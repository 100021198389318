import { IProjectData } from "Components/Common/DataList/DataList";
import Footer from "Components/Common/Footer/Footer";
import Popup from "Components/Common/Popup/Popup";
import useMoveScroll from "Components/Common/Scroll/useMoveScroll";
import { HomeContents, HomeIntroContents } from "Components/Data/Contents/HomeContents";
import { IHomeContents } from "Components/Module/API/Interface/Home.interface";
import IntroSpace from "Components/Pages/Home/IntroSpace/IntroSpace";
import ReactVideoPlayer from "Components/Pages/Home/ReactPlayer/ReactVideoPlayer";
import React, { useEffect, useRef, useState } from "react";
import { Element, Link as LinkS } from "react-scroll";
import { useWindowSize } from "usehooks-ts";
import { useTranslation } from "react-i18next";
import "./Home.scss";

function useComponentVisible(initialIsVisible: any) {
	const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible);
	const ref = useRef<HTMLDivElement>(null);
	const { width, height } = useWindowSize();

	const handleHideDropdown = (event: KeyboardEvent) => {
		if (event.key === "Escape") {
			setIsComponentVisible(false);
		}
	};

	const handleClickOutside = (event: any) => {
		// eslint-disable-next-line no-empty
		if (ref.current && !ref.current.contains(event.target)) {
		}
	};

	useEffect(() => {
		document.addEventListener("keydown", handleHideDropdown, true);
		document.addEventListener("click", handleClickOutside, true);
		return () => {
			document.removeEventListener("keydown", handleHideDropdown, true);
			document.removeEventListener("click", handleClickOutside, true);
		};
	});

	return { ref, isComponentVisible, setIsComponentVisible };
}

function Home() {
	const { t, i18n } = useTranslation();
	// useEffect(() => {
	// 	window.scrollTo(0, 0);
	// }, []);
	const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

	const [dialogRender, setDialogRender] = useState<boolean>(false);

	const [selectData, setSelectData] = useState<IProjectData>({
		id: "",
		workerTag: "",
		name: {
			ko: "",
			en: "",
			jp: "",
			cn: ""
		},
		company: {
			ko: "",
			en: "",
			jp: "",
			cn: ""
		},
		type: "",
		mainImg: "",
		videoNew: "",
		detailImg: "",
	});
	useEffect(() => {
		if (isComponentVisible) {
			document.body.style.overflow = "hidden";
		} else {
			document.body.style.overflow = "unset";
		}
	}, [isComponentVisible]);
	useEffect(() => {
		// console.log(selectData);
	}, [selectData]);

	const handleClickDialogOpen = (data: IProjectData) => {
		if (data.id === "none") {
			return;
		}
		setSelectData(data);
		setIsComponentVisible(true);
	};
	const element = {
		0: useMoveScroll("worker_0"),
		1: useMoveScroll("worker_1"),
		2: useMoveScroll("worker_2"),
		3: useMoveScroll("worker_3"),
		4: useMoveScroll("worker_4"),
		5: useMoveScroll("showreel"),
		length: 6
	};
	return (
		<>
			<div ref={ref} className="home-container">
				<ul className="group">
					<li className="intro-character">
						<img className="img" src={HomeIntroContents.image} alt="" />
						<div className="up-background">
							<div className="left-back" />
							<div className="right-back" />
						</div>
						<div className="down-background">
							<div className="left-back" />
							<div className="right-back" />
						</div>
						<div className="area up-area">
							{/* <LinkS
								to="worker_0"
								spy={true}
								smooth={false}
								duration={500}
								offset={50}
								className="scroll-btn"
							/> */}
							<div className="scroll-btn" onClick={element[0].onMoveToElement} />
							<div className="scroll-btn" onClick={element[1].onMoveToElement} />
							<div className="scroll-btn" onClick={element[2].onMoveToElement} />
						</div>
						<div className="area down-area">
							<div className="scroll-btn" onClick={element[5].onMoveToElement} />
							<div className="scroll-btn" onClick={element[3].onMoveToElement} />
							<div className="scroll-btn" onClick={element[4].onMoveToElement} />
						</div>
					</li>
					<li className={"intro-text " + (i18n.language === "ko" ? "ko" : "en")}>
						{t("intro_text_0")} <span className="text-bold">{t("intro_text_1")} </span>
						<br></br>
						{t("intro_text_2")} <br></br>
						{t("intro_text_3")} <br></br>
						{t("intro_text_4")} <br></br>
						{t("intro_text_5")} <span className="text-bold">{t("intro_text_6")}</span>{t("intro_text_7")}
					</li>
					<div ref={element[5].element} style={{ scrollMargin: "-50px" }} />
					<ReactVideoPlayer url={"https://vimeo.com/834677738"} />
				</ul>
				{/* {HomeContents.map((value: IHomeContents, index: number) => (
					<div key={"worker_" + index}>
						<div ref={element[index].element} />
						<IntroSpace
							key={"home_contents_" + index}
							index={index}
							dir={index % 2 === 0 ? "left" : "right"}
							workerData={value}
							onClickDialogOpen={handleClickDialogOpen}
						/>
					</div>
				))} */}
				<div ref={element[0].element} style={{ scrollMargin: "-50px" }} />
				<div key={"worker_0"}>
					<IntroSpace
						key={"home_contents_0"}
						index={0}
						dir={"left"}
						workerData={HomeContents[0]}
						onClickDialogOpen={handleClickDialogOpen}
					/>
				</div>
				<div ref={element[1].element} style={{ scrollMargin: "-50px" }} />
				<div key={"worker_1"}>
					<IntroSpace
						key={"home_contents_1"}
						index={1}
						dir={"right"}
						workerData={HomeContents[1]}
						onClickDialogOpen={handleClickDialogOpen}
					/>
				</div>
				<div ref={element[2].element} style={{ scrollMargin: "-50px" }} />
				<div key={"worker_2"}>
					<IntroSpace
						key={"home_contents_2"}
						index={2}
						dir={"left"}
						workerData={HomeContents[2]}
						onClickDialogOpen={handleClickDialogOpen}
					/>
				</div>
				<div ref={element[3].element} style={{ scrollMargin: "-50px" }} />
				<div key={"worker_3"}>
					<IntroSpace
						key={"home_contents_3"}
						index={3}
						dir={"right"}
						workerData={HomeContents[3]}
						onClickDialogOpen={handleClickDialogOpen}
					/>
				</div>
				<div ref={element[5].element} style={{ scrollMargin: "-50px" }} />
				<div key={"worker_4"}>
					<IntroSpace
						key={"home_contents_4"}
						index={4}
						dir={"left"}
						workerData={HomeContents[4]}
						onClickDialogOpen={handleClickDialogOpen}
					/>
				</div>
				<div ref={element[4].element} style={{ scrollMargin: "-50px" }} />
				<div key={"worker_5"}>
					<IntroSpace
						key={"home_contents_5"}
						index={5}
						dir={"right"}
						workerData={HomeContents[5]}
						onClickDialogOpen={handleClickDialogOpen}
					/>
				</div>
				<div className="talent-recruitment">
					<span className="title">{t("talent_recruitment")}</span>
					<span className="sub-text st1 under-line">pd@loocreative.com</span>
					<br />
					<span className={"end-text " + (i18n.language === "ko" ? "ko" : "en")}>{t("lead_the_trennd_with_looc")}</span>
					<div className="btn-list">
						<span className="sand">{t("send")}</span>
						<span className="download">{t("download")}</span>
					</div>
				</div>
				<div className="home-distance" style={{ width: "100%", height: "130px" }}></div>
				<Footer />
			</div>

			<div>
				{isComponentVisible && (
					<Popup data={selectData} setOpenPopup={setIsComponentVisible} />
				)}
			</div>
		</>
	);
}

export default Home;
