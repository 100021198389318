import React from "react";

import imgMainBg from "../Source/bg-main.png";
import imgOverview from "../Source/img-overview-01.png";

import imgConcept1 from "../Source/img-concept-01.png";
import imgConcept2 from "../Source/img-concept-02.png";
import imgConcept3 from "../Source/img-concept-03.png";
import imgConcept4 from "../Source/img-concept-04.png";

import imgColor1 from "../Source/img-color-01.png";

import imgFont1 from "../Source/img-font-01.png";

import imgKey1 from "../Source/img-keyvisual-01.png";
import imgKey2 from "../Source/img-keyvisual-02.png";
import imgKey3 from "../Source/img-keyvisual-03.png";
import imgKey4 from "../Source/img-keyvisual-04.png";

import imgAdv1 from "../Source/advertisement-01.png";
import imgAdv2 from "../Source/advertisement-02.png";
import imgAdv3 from "../Source/advertisement-03.png";

import imgEnd from "../Source/bg-epilogue.png";

import gif1 from "../Source/opening_01.gif";
import gif3 from "../Source/opening_03.gif";
import gif4 from "../Source/opening_04.gif";
import gif5 from "../Source/opening_05.gif";

import './styles.scss'

const PageBg = () => {
	return (
		<div className="ces2023opening">
			<div className="main-bg">
				<img src={imgMainBg} alt="" />
			</div>

			<OverView
				title="Samsung Press Conference ‘CES 2023’"
				detail={`삼성전자의 Unbox & Discover 2022의 Neo QLED 8K를 예고하기 위해 제작되었다. TV를 보며 여가활동을 하는 사람을 활용해 스토리텔링 하였다. 메인 키워드인 "Let's Do Neo QLED 8K 에서 'Do'라는 단어를 뒤집어 활용한 'Man'의 의미를 가지는 아이콘으로 변화시켜, 'Do' 가 내포한 의미와 3D 오브젝트들, TV를 통해 사람이 할 수 있는 활동을 하는 'Do'를 보며 어떤 제품을 나타내려고 하는지 간접적으로 드러나게 하였다.`}
			/>

			<div className="main-bg">
				<img src={imgOverview} alt="" />
			</div>

			<div className="main-bg">
				<img src={gif1} alt="" />
			</div>

			<OverView
				title="Concept"
				detail={`아모레퍼시픽이 전개하는 기능성 뷰티브랜드 '라네즈' 에서 새롭게 출시하는 블루 히알루론산을 주 성분으로 활용해 강력한 흡수율과 리페어 보습 효능을 선사하는 올 뉴 '워터뱅크 블루 히알루토닉'을 소개한다. 전체적으로 제품의 분위기와 패키징 디자인에 초점을 두고 네이밍에 적합한 블루 톤의 컬러 위주로 산뜻한 느낌으로 진행해 모델을 적절히 활용하며 제품의 특징을 돋보이게 해주는데에 집중하였습니다.`}
			/>

			<div className="tech-img">
				<img src={imgConcept1} alt="img" />
				<img src={imgConcept2} alt="img" />
				<img src={imgConcept3} alt="img" />
				<img src={imgConcept4} alt="img" />
			</div>

			<div className="main-bg">
				<img src={gif3} alt="" />
			</div>

			<OverView
				title="Color"
				detail={`아모레퍼시픽이 전개하는 기능성 뷰티브랜드 '라네즈' 에서 새롭게 출시하는 블루 히알루론산을 주 성분으로 활용해 강력한 흡수율과 리페어 보습 효능을 선사하는 올 뉴 '워터뱅크 블루 히알루토닉'을 소개한다. 전체적으로 제품의 분위기와 패키징 디자인에 초점을 두고 네이밍에 적합한 블루 톤의 컬러 위주로 산뜻한 느낌으로 진행해 모델을 적절히 활용하며 제품의 특징을 돋보이게 해주는데에 집중하였습니다.`}
			/>

			<div className="main-bg">
				<img src={imgColor1} alt="" />
			</div>

			<OverView
				title="Font"
				detail={`아모레 퍼시픽이 전개하는 기능성 뷰티브랜드 '라네즈' 에서 새롭게 출시하는 블루 히알루론산을 주 성분으로 활용해 강력한 흡수율과 리페어 보습 효능을 선사하는 올 뉴 '워터뱅크 블루 히알루토닉'을 소개한다. 전체적으로 제품의 분위기와 패키징 디자인에 초점을 두고 네이밍에 적합한 블루 톤의 컬러 위주로 산뜻한 느낌으로 진행해 모델을 적절히 활용하며 제품의 특징을 돋보이게 해주는데에 집중하였습니다.`}
			/>

			<div className="main-bg">
				<img src={imgFont1} alt="" />
			</div>

			<div className="main-bg">
				<img src={gif4} alt="" />
			</div>

			<div className="main-bg">
				<img src={imgKey1} alt="" />
			</div>

			<div className="main-bg">
				<img src={imgKey2} alt="" />
			</div>

			<div className="main-bg">
				<img src={imgKey3} alt="" />
			</div>

			<div className="main-bg">
				<img src={imgKey4} alt="" />
			</div>

			<div className="main-bg">
				<img src={gif5} alt="" />
			</div>

			<div className="main-bg">
				<img src={imgAdv1} alt="" />
			</div>
			<div className="main-bg">
				<img src={imgAdv2} alt="" />
			</div>
			<div className="main-bg">
				<img src={imgAdv3} alt="" />
			</div>
			<div className="main-bg">
				<img src={imgEnd} alt="" />
			</div>
		</div>
	);
};

export default PageBg;

const OverView = ({ title, detail }: { title: string; detail: string }) => {
	return (
		<div className="overview">
			<div className="top">{title}</div>
			<div className="bottom">{detail}</div>
		</div>
	);
};
