import React from "react";
import bg from "./Contents/bg-main.png";
import overview_1 from "./Contents/img-overview-01@2x.png";
import overview_2 from "./Contents/img-overview-02.png";
import overview_3 from "./Contents/img-overview-03.png";
import img_keyword_1 from "./Contents/img-keyword-01.png";
import img_keyword_2 from "./Contents/img-keyword-02.png";
import img_keyword_3 from "./Contents/img-keyword-03.png";
import img_keyword_4 from "./Contents/img-keyword-04.png";
import img_perfor_01 from "./Contents/img-perfor-01.png";
import img_perfor_02 from "./Contents/img-perfor-02.png";
import img_perfor_03 from "./Contents/img-perfor-03.png";
import img_perfor_04 from "./Contents/img-perfor-04.png";
import img_slim_01 from "./Contents/img-slim-01.png";
import img_slim_02 from "./Contents/img-slim-02.png";
import img_slim_03 from "./Contents/img-slim-03.png";
import img_slim_04 from "./Contents/img-slim-04.png";
import img_slim_05 from "./Contents/img-slim-05.png";
import img_eco_01 from "./Contents/img-ecosystem-01.png";
import img_eco_02 from "./Contents/img-ecosystem-02.png";
import img_eco_03 from "./Contents/img-ecosystem-03.png";
import img_eco_04 from "./Contents/img-ecosystem-04.png";
import img_360_1 from "./Contents/img-360-01.png";
import img_360_2 from "./Contents/img-360-02.png";
import img_360_3 from "./Contents/img-360-03.png";
import img_conti from "./Contents/img-conti.png";
import img_mockup_01 from "./Contents/img-mockup-01.png";
import img_mockup_02 from "./Contents/img-mockup-02.png";
import img_mockup_03 from "./Contents/img-mockup-03.png";
import img_mockup_04 from "./Contents/img-mockup-04.png";
import img_mockup_05 from "./Contents/img-mockup-05.png";
import img_mockup_06 from "./Contents/img-mockup-06.png";
import img_mockup_07 from "./Contents/img-mockup-07.png";

import video_1 from "./Source/master_03.mp4";
import gif_1 from "./Source/Paper_Test_01.gif";
import gif_2 from "./Source/Venus_hold.gif";
import gif_3 from "./Source/Vesta_02.gif";
import gif_4 from "./Source/mars.gif";
import gif_5 from "./Source/Mars_hold_hand.gif";
import gif_6 from "./Source/Mars_hold_01.gif";

import "./style.scss";
function SamsungGalaxyBoook2() {
	return (
		<div className="samsungGalaxy">
			<div className="samsungGalaxy-bg">
				<img src={bg} alt="" />
			</div>

			<div className="samsungGalaxy-overview">
				<div className="samsungGalaxy-overview-imgs">
					<div className="img_1">
						<img src={overview_1} alt="" />
					</div>
					<div className="img_2">
						<img src={overview_2} alt="" />
					</div>
				</div>

				{/* <div className="samsungGalaxy-overview-design">
					<div className="title">Design Icon</div>
					<div className="img">
						<img src={overview_3} alt="" />
					</div>
					<div className="content">
						<div className="title">
							Galaxy Book2 <br />
							Pro 360
						</div>
						<div className="detail">
							새로운 아이디어가 떠오를 때마다 언제 어디서든 기록하세요. <br />
							360도 회전 힌지와 얇고 세련된 2-in-1 노트북, 갤럭시 북2 Pro 360이
							있으니까요.
							<br />
							응답 속도가 향상된 S펜으로 실제 펜처럼 매끄럽게 쓰고 그릴 수 있습니다.
						</div>
					</div>
				</div> */}
			</div>

			{/* <div className="samsungGalaxy-video">
				<iframe
					id="sulwhasoo"
					title="sulwhasoo"
					name="sulwhasoo"
					frameBorder="0"
					allow="autoplay; fullscreen; picture-in-picture"
					allowFullScreen={true}
					src={video_1}
				/>
			</div> */}

			<div className="mainVideo" style={{ paddingBottom: 0 }}>
				<video autoPlay muted loop>
					<source src={video_1} type="video/mp4" />
				</video>
			</div>

			<div className="samsungGalaxy-keyword">
				{/* <div className="title">Keyword</div>

				<div className="img_1">
					<img src={img_keyword_1} alt="" />
				</div> */}
				<div className="img_2">
					<img src={img_keyword_2} alt="" />
				</div>
				<div className="img_3">
					<img src={img_keyword_3} alt="" />
					<img src={img_keyword_4} alt="" />
				</div>
			</div>

			<div className="samsungGalaxy-conti">
				<div className="title">Conti</div>

				<div className="img">
					<img src={img_conti} alt="" />
				</div>
			</div>

			<div className="samsungGalaxy-performance">
				<div className="content">
					<div className="title">Performance</div>
					<div className="detail">
						갤럭시 북2 Pro 360은 모두가 원하는 퍼포먼스를 보여줄 수 있습니다. <br />
						12세대 Intel® Core™ 프로세서와 Intel® Iris® Xe 그래픽이 뛰어난 성능을
						<br />
						보여줍니다. 또한 초고속 LPDDR5 메모리와 첨단 냉각 시스템까지 탑재되어
						<br />
						업무의 효율을 한층 더 높여줍니다.
					</div>
				</div>

				<div className="img">
					<div className="left">
						<div className="img_1">
							<img src={img_perfor_01} alt="" />
						</div>
						<div className="img_2">
							<img src={img_perfor_02} alt="" />
						</div>
					</div>
					<div className="right">
						<img src={img_perfor_03} alt="" />
					</div>
				</div>

				<div className="img-pro">
					<img src={img_perfor_04} alt="" />
				</div>
				<div className="img-gifs">
					<img src={gif_1} alt="" />
					<img src={gif_2} alt="" />
				</div>
			</div>

			<div className="samsungGalaxy-slim">
				<div className="content">
					<div className="title">Slim</div>
					<div className="detail">
						얇지만 견고한 내구성을 가진 슬림 메탈 디자인을 준비했습니다.
						<br />
						15.4 mm의 얇은 두께에 풀메탈 바디의 견고함까지 갖췄습니다. 또한 초슬림
						<br />
						베젤의 넓은 화면으로 쾌적한 작업 환경을 경험할 수 있습니다. 갤럭시 북2와
						<br />
						함께 여러분의 모든 순간을 즐겨 보세요.
					</div>
				</div>

				<div className="imgs">
					<div className="left">
						<img src={img_slim_01} alt="" />
						<img src={img_slim_02} alt="" />
					</div>
					<div className="right">
						<img src={img_slim_03} alt="" />
					</div>
				</div>

				<div className="gif">
					<img src={gif_3} alt="" />
				</div>

				<div className="list-img">
					<img src={img_slim_04} alt="" />
					<img src={img_slim_05} alt="" />
				</div>
			</div>

			<div className="samsungGalaxy-slim">
				<div className="content">
					<div className="title">Ecosystem</div>
					<div className="detail">
						Quick Share로 폰/태블릿 등의 갤럭시 기기 간 파일 공유가 쉬워집니다.
						<br />
						번거로운 작업을 할 필요없이 무선으로 연결하여 간단하게 공유해 보세요.
						<br />
						첫 세팅도 걱정하지 마세요. Single Sign-On을 사용하면 한 번의 <br />
						로그인만으로 Galaxy Ecosystem에 연결된 앱을 사용할 수 있습니다.
					</div>
				</div>

				<div className="imgs">
					<div className="left">
						<img src={img_eco_01} alt="" />
						<img src={img_eco_02} alt="" />
					</div>
					<div className="right">
						<img src={img_eco_03} alt="" />
					</div>
				</div>

				<div className="gif">
					<img src={img_eco_04} alt="" />
				</div>

				<div className="list-img">
					<img src={gif_4} alt="" />
					<img src={gif_5} alt="" />
				</div>
			</div>

			<div className="samsungGalaxy-slim">
				<div className="content">
					<div className="title">360</div>
					<div className="detail">
						리얼 S펜과 360도 회전 힌지가 적용된 2 in 1 노트북을 만나보세요.
						<br />
						새로운 아이디어가 떠오를 때마다 언제 어디서든 기록하세요. 360도 회전
						<br />
						힌지와 얇고 세련된 2-in-1 노트북, 갤럭시 북2 Pro 360이 있습니다.
						<br />
						응답 속도가 향상된 S펜으로 실제 펜처럼 매끄럽게 쓰고 그려보세요.
					</div>
				</div>

				<div className="imgs">
					<div className="left">
						<img src={img_360_1} alt="" />
						<img src={img_360_2} alt="" />
					</div>
					<div className="right">
						<img src={img_360_3} alt="" />
					</div>
				</div>

				<div className="gif">
					<img src={gif_6} alt="" />
				</div>
			</div>

			<div className="samsungGalaxy-content">
				더욱 밝아진 Super AMOLED 디스플레이는 보는 즐거움을 더합니다. 컬러 볼륨 120 %의
				생생한 색감에 깊은 명암비, 빠른 응답 속도, 낮은 블루라이트 수치까지,
				<br />
				섬세한 그래픽 작업은 물론 게임, 영화 등의 콘텐츠를 더욱 몰입감 있게 즐길 수
				있습니다. 12세대 Intel® Core™ 프로세서와 Intel® Arc™ 그래픽 탑재로 차원이 다른
				<br />
				강력한 퍼포먼스를 확인할 수 있습니다. 게이밍, 영상 편집, 업무 까지 한 차원 높아진
				수준을 직접 느껴보세요 .
			</div>

			<div className="samsungGalaxy-mockup">
				{/* <img src={img_mockup_01} alt="" /> */}
				<img src={img_mockup_02} alt="" />
				<img src={img_mockup_03} alt="" />
				<img src={img_mockup_04} alt="" />
				<img src={img_mockup_05} alt="" />
				<img src={img_mockup_06} alt="" />
				<img src={img_mockup_07} alt="" />
			</div>
		</div>
	);
}

export default SamsungGalaxyBoook2;
