import DataList, { IProjectData } from "Components/Common/DataList/DataList";
import Footer from "Components/Common/Footer/Footer";
import LineMenu, { ILineMenuData } from "Components/Common/LineMenu/LineMenu";
import Popup from "Components/Common/Popup/Popup";
import { WorkContetntData } from "Components/Data/Contents/WorkContents";
import i18n from "Components/Module/i18n/locales";

import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useWindowSize } from "usehooks-ts";
import "./Work.scss";

export const ConsultingMenuData: ILineMenuData[] = [
	{
		id: "all",
		title: "ALL",
		value: "ALL"
	},
	{
		id: "tz",
		title: "TZ",
		value: "TZ"
	},
	{
		id: "Chase",
		title: "Chase",
		value: "Chase"
	},
	{
		id: "Haenee",
		title: "Haenee",
		value: "Haenee"
	},
	{
		id: "YSH",
		title: "YSH",
		value: "YSH"
	},
	{
		id: "Kelly",
		title: "Kelly",
		value: "Kelly"
	}
];

function useComponentVisible(initialIsVisible: any) {
	const { t, i18n } = useTranslation();
	const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible);
	const ref = useRef<HTMLDivElement>(null);
	const { width, height } = useWindowSize();

	const handleHideDropdown = (event: KeyboardEvent) => {
		if (event.key === "Escape") {
			setIsComponentVisible(false);
		}
	};

	const handleClickOutside = (event: any) => {
		// eslint-disable-next-line no-empty
		if (ref.current && !ref.current.contains(event.target)) {
		}
	};
	// useEffect(() => {
	// 	window.onbeforeunload = function pushRefresh() {
	// 		window.scrollTo(0, 0);
	// 	};
	// }, []);

	useEffect(() => {
		document.addEventListener("keydown", handleHideDropdown, true);
		document.addEventListener("click", handleClickOutside, true);
		return () => {
			document.removeEventListener("keydown", handleHideDropdown, true);
			document.removeEventListener("click", handleClickOutside, true);
		};
	});

	return { ref, isComponentVisible, setIsComponentVisible };
}

function Work() {
	const [selectTag, setSelectTag] = useState("ALL");

	const inputChange = (value: string) => {
		setSelectTag(value);
	};
	const [dialogRender, setDialogRender] = useState<boolean>(false);
	const [selectData, setSelectData] = useState<IProjectData>({
		id: "",
		workerTag: "",
		name: {
			ko: "",
			en: "",
			jp: "",
			cn: ""
		},
		company: {
			ko: "",
			en: "",
			jp: "",
			cn: ""
		},
		type: "",
		mainImg: "",
		videoNew: ""
	});

	useEffect(() => {
		// console.log(selectData);
	}, [selectData]);

	const handleClickDialogOpen = (data: IProjectData) => {
		setSelectData(data);
		setIsComponentVisible(true);
	};

	const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

	useEffect(() => {
		if (isComponentVisible) {
			document.body.style.overflow = "hidden";
		} else {
			document.body.style.overflow = "unset";
		}
	}, [isComponentVisible]);

	return (
		<>
			<div ref={ref} className="work-container">
				<div className="work-wrapper">
					<div className="work-menu-wrapper">
						{/* <div className="menu-box">
							<LineMenu
								list={ConsultingMenuData}
								selectTag={selectTag}
								onChangeInput={inputChange}
							/>
						</div> */}
						<div className="project-data-list">
							<DataList
								list={WorkContetntData}
								selectTag={selectTag}
								onClickDialogOpen={handleClickDialogOpen}
							/>
						</div>
					</div>
				</div>
				<Footer />
			</div>
			<div>
				{isComponentVisible && (
					<Popup data={selectData} setOpenPopup={setIsComponentVisible} />
				)}
			</div>
		</>
	);
}
export default Work;
