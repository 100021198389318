import { BrowserRouter, Routes, Route, useNavigate, useLocation } from "react-router-dom";
import Header from "Components/Common/Header/Header";
import Home from "Components/Pages/Home/Home";
import About from "Components/Pages/About/About";
import PageNotFound from "Components/Pages/Error/PageNotFound";
import React, { useEffect } from "react";
import Work from "Components/Pages/Works/Work";
import Contact from "Components/Pages/Contact/Contact";
import i18n from "Components/Module/i18n/locales";
import ScrollToTop from "Components/Common/Scroll/ScrollToTap";

function App() {
	const location = useLocation();

	useEffect(() => {
		const language = sessionStorage.getItem("language");
		if (language) {
			i18n.changeLanguage(language);
		}
	}, []);
	return (
		<div className="app-root">
			<Header location={location} i18n={i18n}></Header>
			{/* <ScrollToTop /> */}
			<Routes>
				<Route path="/" element={<Home></Home>} />
				<Route path="/about" element={<About></About>} />
				<Route path="/work" element={<Work></Work>} />
				<Route path="/contact" element={<Contact></Contact>} />
				<Route path="*" element={<PageNotFound></PageNotFound>} />
			</Routes>
		</div>
	);
}

export default App;
