import React from "react";

import img1 from "../../Source/bg-main-01.png";

import imgBgPlan from "../../Source/bg-planning.png";
import imgBgKeyword from "../../Source/bg-keywords.png";

import imgPl1 from "../../Source/img-planning-01.png";
import imgPl2 from "../../Source/img-planning-02.png";
import imgPl3 from "../../Source/img-planning-03.png";

import imgK1 from "../../Source/img-keywords-01.png";

import imgDr1 from "../../Source/img-direction-01.png";

import imgRf from "../../Source/img-refobject-01.png";
import imgRf2 from "../../Source/img-refobject-02.png";
import imgRf3 from "../../Source/img-refobject-03.png";

import imgBgRf from "../../Source/bg-referencebg.png";
import imgBgRf1 from "../../Source/img-referencebg-01.png";
import imgBgRf2 from "../../Source/img-referencebg-02.png";
import imgBgRf3 from "../../Source/img-referencebg-03.png";

import imgConti from "../../Source/bg-conti.png";
import imgConti1 from "../../Source/img-conti-01.png";
import imgConti2 from "../../Source/img-conti-02.png";
import imgConti3 from "../../Source/img-conti-03.png";

import imgKeyVisual from "../../Source/bg-keyvisual.png";
import imgKeyVisual1 from "../../Source/img-keyvisual-01.png";
import imgKeyVisual2 from "../../Source/img-keyvisual-02.png";
import imgKeyVisual3 from "../../Source/img-keyvisual-03.png";
import imgKeyVisual4 from "../../Source/img-keyvisual-04.png";

import imgOpen from "../../Source/bg-openingtitle.png";
import imgEpi from "../../Source/bg-epilogue.png";

import "./style.scss";

const HomeBg = () => {
	return (
		<div className="artificialPage-bg">
			<div className="background">
				<img src={img1} alt="" />
			</div>

			<div className="section">
				<div className="section-container">
					<div className="section-content">
						<div className="title">Planning <br></br> Intention</div>
						<div className="detail">
							최고의 자리에 오르고자 하는 인간의 극한의 욕망을상직적 의미들을 지정해
							시각적으로 인상깊게 표현하는 것에 초점을 맞추어 제작하였습니다.
						</div>
					</div>

					<div className="section-img">
						<div className="item">
							<img src={imgPl1} alt="" />
						</div>
						<div className="item">
							<img src={imgPl2} alt="" />
						</div>
						<div className="item">
							<img src={imgPl3} alt="" />
						</div>
					</div>
				</div>
			</div>

			<div className="keyword">
				<div className="keyword-container">
					<div className="section-content right">
						<div className="title">Keywords</div>
						<div className="detail">
							욕망을 추구하는 인간의 감정과 소품에서상징적인 오브젝트의 키워드를
							설정한 후 연계성을 고려하여스토리텔링을 해나갔습니다.
						</div>
					</div>

					<div className="section-img">
						<div className="item">
							<img src={imgK1} alt="" />
						</div>
					</div>
				</div>
			</div>

			<div className="gif totop">
				<img
					style={{ width: "100%" }}
					alt="Loading..."
					src={require("../../Source/tree_cup.gif")}
				/>
			</div>

			<div className="direction">
				<div className="section-container">
					<div className="section-content white">
						<div className="title">Design Direction</div>
						<div className="detail">
							욕망의 상징을 레드로 컬러를 설정하여 기획 의도를 인지하기 쉽도록 어두운
							배경에서 계속해서 진행해 나가욕망의 강렬함이 컬러로 느껴질 수 있도록
							하였습니다.
						</div>
					</div>

					<div className="section-img">
						<img className="img-keyword" src={imgDr1} alt="" />
					</div>

					<div className="section-content white margintop-50">
						<div className="title smaller">Design Direction</div>
						<div className="detail">
							욕망의 상징을 레드로 컬러를 설정하여 기획 의도를 인지하기 쉽도록 어두운
							배경에서 계속해서 진행해 나가욕망의 강렬함이 컬러로 느껴질 수 있도록
							하였습니다.
						</div>
					</div>
				</div>
			</div>

			<div className="refrence">
				<div className="title">Reference Object</div>
				<div className="main-img">
					<img src={imgRf} alt="reference object" />
				</div>
				<div className="bird">
					<div className="item">
						<img src={imgRf2} alt="bird 2" />
					</div>
					<div className="item">
						<img src={imgRf3} alt="bird 3" />
					</div>
				</div>
			</div>

			<div className="refrence-bottom">
				<div className="title">
					<div className="top">
						Reference
						<br />
						Background
					</div>
					<div className="bottom">
						욕망의 상징성을 레드라는 컬러를 사용하여 기획 의도에 맞게 욕망과 욕망으로
						인해 벌어지는 일들을 컬러로 느낄 수 있게 하였습니다.
					</div>
				</div>

				<div className="item-container">
					<div className="item">
						<div className="item-img">
							<img src={imgBgRf1} alt="img 1" />
						</div>
						<div className="content">
							<div className="top">Museum</div>
							<div className="bottom">
								붉은색 배경의 미술관에걸려있는 도시 형태의 작품은 공작도시를
								상징합니다.
							</div>
						</div>
					</div>
					<div className="item from-right">
						<div className="item-img img-2">
							<img src={imgBgRf2} alt="img 1" />
						</div>
						<div className="content right">
							<div className="top">Bathtub</div>
							<div className="bottom">
								욕조는 욕망의 상징이 잠들어있던 공간을 의미합니다.
							</div>
						</div>
					</div>
					<div className="item">
						<div className="item-img img-3">
							<img src={imgBgRf3} alt="img 1" />
						</div>
						<div className="content">
							<div className="top">Door</div>
							<div className="bottom">
								거대한 돌로 된 문이 열리는 배경은 욕망의 분출, 새로운 시작을
								의미합니다. 이 배경을 기점으로 기존 상징들의 파괴가 이루어집니다.
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="conti">
				<div className="content">
					<div className="top">Conti</div>
					<div className="bottom">
						상징성을 부여하며 전개하는스토리에 중점을 두고 의미를 부여한 오브젝트들의
						구성과 연결방식을 생각하며 스토리텔링을 하였고, 그에 맞게 콘티를
						구성하였습니다.
					</div>
				</div>
				<img src={imgConti1} alt="contiebg1" className="img-main" />
			</div>
			<div className="conti-bottom">
				<img src={imgConti2} alt="contiebg2" />
				<img src={imgConti3} alt="contiebg3" />
			</div>
			<div className="gif totop  totop2">
				<img alt="Loading..." src={require("../../Source/chair_03.gif")} />
			</div>

			<div className="key-visual">
				<div className="content">
					<div className="top">Key visual</div>
					<div className="bottom">
						전달하려고 하는 내용의 스토리에 중점을 두고 의미를 해치지 않는 선에서 상직적
						오브젝트들을 자연스럽게 연결할 수 있는 방식을 생각하며 구성하였습니다.
					</div>
				</div>
				<div className="img-container">
					<div className="item">
						<img src={imgKeyVisual1} alt="img" />
					</div>
					<div className="item right">
						<img src={imgKeyVisual2} alt="img" />
					</div>
					<div className="item">
						<img src={imgKeyVisual3} alt="img" />
					</div>
					<div className="item">
						<img src={imgKeyVisual4} alt="img" />
					</div>
				</div>
			</div>
			<div className="mainVideo" style={{ paddingBottom: 0 }}>
				<video autoPlay muted loop>
					<source src={require("../../Source/master.mp4")} type="video/mp4" />
				</video>
			</div>
			<div className="opening-img">
				<img src={imgOpen} alt="" />
			</div>
			<div className="epi-img">
				<img src={imgEpi} alt="" />
			</div>
		</div>
	);
};

export default HomeBg;
