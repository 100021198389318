import React from "react";
import bg from "../../Contents/bg-main.png";
import bg_overview from "../../Contents/img-overview.png";
import bg_concept from "../../Contents/img-concept.png";
import bg_color from "../../Contents/img-color.png";
import bg_font from "../../Contents/img-font.png";
import bg_key_visual from "../../Contents/img-keyvisual.png";
import bg_mockup_01 from "../../Contents/bg-mockup-01.png";
import bg_mockup_02 from "../../Contents/bg-mockup-02.png";
import bg_mockup_03 from "../../Contents/bg-mockup-03.png";
import bg_mockup_04 from "../../Contents/bg-mockup-04.png";
import bg_mockup_05 from "../../Contents/bg-mockup-05.png";
import bg_mockup_06 from "../../Contents/bg-mockup-06.png";

import "./style.scss";
import { Intro } from "../Intro/Intro";
function HomeBg() {
	return (
		<div className="home-samsung-qled">
			<div className="imgBg">
				{/* <img src={bg} alt="" /> */}
			</div>
			<Intro
				title="Let’s Do Neo QLED 8K"
				detail="삼성전자가 온라인으로 개최한 Unbox & Discover 2022 행사를 예고하는 영상으로 Neo QLED 8K 가 제공하는 새로운 시청 경험을 나타내기 위해 단순하게 시청을 하는 것 뿐만이 아니라 사람이 동시에 
할 수 있는 경험들을 메인 키워드인 “Let’s Do Neo QLED 8K” 에서 ‘Do’라는 단어를 활용한 ‘Man’의 의미를 가지는 아이콘으로 변화시켜, ‘Do’ 가 내포한 의미와 3D 오브젝트들을 통해 Neo QLED 8K를 
통해 다양한 경험을 하는 ‘Do’ 를 보며 보는 사람들이 기대감을 느낄 수있게 디자인 하였습니다."
				isCenter={false}
			/>
			<div className="imgBg">
				<img src={bg_overview} alt="" />
			</div>

			<Intro title="" detail="" isCenter />
			<div className="imgBg">
				<img alt="Loading..." src={require("../../Source/popocorn_02.gif")} />
			</div>
			<Intro
				title="Concept"
				detail="일상에서 사람들이 TV를 통해 하고있는 익숙한 경험을 공유해 사람들이 보다 더 쉽게 공감대를 형성하도록 하여 어떤 제품을 나타내려고 하는지 직관적으로 느낄 수 있게 하기 위해‘Do’가 현 시대의 사람들이 TV를 통해 주로 하는 음악감상, 영화감상, 트레이닝, 게임 4종류의 경험을 하는 스토리로 풀어나갔습니다. Do가 활동을 하며 다른 경험을 하며 트랜지션
되고, 경험마다 컬러를 설정하여 어떤 변화가 나타나는지 직관적으로 느낄 수 있도록 디자인 하였습니다."
				isCenter={false}
			/>

			<div className="imgBg">
				<img src={bg_concept} alt="" />
			</div>
			<Intro
				title="Color"
				detail="배경의 컬러로 주제를 변화를 느낄 수 있도록 영상이 시작되는 오프닝 부분과 끝나는 부분의 컬러, 경험을 보여주는 배경 컬러들을 각각 모두 다르게 설정하여 시작과 끝, 나타내고자 
                하는 주제의 내용들을 명확하게 보이도록 하였으며, 눈에 피로감을 주지 않도록 파스텔 톤 위주의 컬러로 사용하였습니다. 폰트를 통해 아이콘화된 ‘Do’의 컬러가 변하지 않게 끝까지 
                유지하며 가독성을 해치지 않도록 하였습니다."
				isCenter={false}
			/>
			<div className="imgBg">
				<img src={bg_color} alt="" />
			</div>
			<Intro
				title="Font"
				detail="브랜드의 BI에 맞게 고객사가 영문을 사용 할때 주로 사용하는 삼성의 영문 폰트를 사용하여 통일감을 주었고, 볼드체를 사용해 가독성을 올리며 ‘Do’ 가 아이콘화되어 3D오브젝트로 변화될 때, 자연스럽게 리깅되어 움직일 때 입체감있고 생동감있는 형태로 움직이게 할 수 있게 진행하였고, 화면 내에서 작은 오브젝트로만 스토리가 진행될 때 구현감을 올릴 수 있
                도록 하였습니다."
				isCenter={false}
			/>
			<div className="imgBg">
				<img src={bg_font} alt="" />
			</div>
			<Intro
				title="Keyvisual"
				detail="등장을 알리는 경쾌한 음악에 맞춰 Neo QLED 8K에서 Do가 떨어지며 아이콘이 등장해 막이 올라가는 오프닝을 열고 의인화된 ‘Do’ 아이콘이 다양한 경험을 하는 움직임을 통해 
각 각 장면의 컨셉이 바뀌며 영상의 스토리가 전개됩니다. 각 주제의 컨셉에 맞는 오브젝트들과 ‘Do’ 아이콘이 각 각 컨셉의 오브젝트들을 활용해 움직이고 활동을 하는 움직임
에서 자연스럽게 다른 컨셉으로 트랜지션 될 수 있도록 하였습니다."
				isCenter
			/>
			<div className="imgBg">
				<img src={bg_key_visual} alt="" />
			</div>

			<div className="imgBg gif-padding">
				<img alt="Loading..." src={require("../../Source/popcorn_01.gif")} />
			</div>

			<div className="mainVideo" style={{ paddingBottom: 0 }}>
				<video autoPlay muted loop>
					<source
						src={require("../../Source/2022_03_Samsung_Neo_QLED_main.mp4")}
						type="video/mp4"
					/>
				</video>
			</div>
			<Intro title="" detail="" isCenter />
			<div className="imgBg">
				<img src={bg_mockup_01} alt="" />
			</div>
			<div className="img-double">
				<img src={bg_mockup_02} alt="" />
				<img src={bg_mockup_03} alt="" />
			</div>
			<div className="imgBg img-mobile">
				<img src={bg_mockup_04} alt="" />
			</div>
			<div className="imgBg">
				<img src={bg_mockup_05} alt="" />
			</div>
			<div className="imgBg">
				<img src={bg_mockup_06} alt="" />
			</div>
		</div>
	);
}

export default HomeBg;
