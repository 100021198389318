import React from "react";
import "./style.scss";
import bg from "./Contents/bg-main.png";
import secret from "./Contents/bg-overview.png";
// import video_1 from "./Source/master.mp4";
import concept from "./Contents/test.png";
import gif_1 from "./Source/key_01.gif";
import gif_2 from "./Source/key_01.gif";
import gif_3 from "./Source/ball_02.gif";
import gif_4 from "./Source/rotation.gif";
import img_concept_9 from "./Contents/img-concept-09.png";
import img_concept_10 from "./Contents/img-concept-10.png";
import img_logo from "./Contents/img-typography.png";
import img_keyvisual from "./Contents/img-keyvisual.png";
import img_mockup from "./Contents/bg-mockup-01.png";
import img_mockup_2 from "./Contents/bg-mockup-02.png";
import img_mockup_3 from "./Contents/bg-mockup-03.png";
import img_epi from "./Contents/bg-epilogue.png";
function SulwhasooPage() {
	return (
		<div className="sulwhasoo">
			<div className="sulwhasoo-bg">
				<img src={bg} alt="" />
			</div>

			<div className="sulwhasoo-overview">
				<div className="sulwhasoo-overview-container">
					<div className="title">OVERVIEW</div>
					<div className="detail">
						여성의 아름다움은 계속 진화합니다. 과거의 한 순간에 머무르지 않습니다.
						설화수는 이 믿음으로 본연의 아름다움을 찾아 나섰습니다. <br />
						시간이 흘러도 건강하게 빛나는 아름다움을 탐구했습니다. 설화수와 함께 삶의
						모든 순간에서 본연의 아름다움을 누릴 수 있습니다. <br />
						설화수는 50 여년에 걸친 도전과 혁신의 산물이며, 한국 화장품의 한 역사가 되는
						브랜드입니다.
						<br />
					</div>
				</div>
			</div>

			<div className="sulwhasoo-secret">
				<div className="sulwhasoo-secret-container">
					<div className="img">
						<img src={secret} alt="" />
					</div>
					<div className="content">
						<div className="title">
							The Secret of Ageless Beauty,
							<br />
							Timeless Wisdom Ageless Beauty,
							<br />
							To The Ageless Beauty Beyond Time.
						</div>
						<div className="detail">
							시간을 초월한 영원한 아름다움으로, <br />
							설화수와 함께 삶의 모든 순간에서 본연의 아름다움을 누릴 수 있습니다.
						</div>
					</div>
				</div>
			</div>



			{/* <div className="mainVideo" style={{ paddingBottom: 0 }}>
				<video autoPlay muted loop>
					<source src={video_1} type="video/mp4" />
				</video>
			</div> */}

			<div className="sulwhasoo-concept">
				<div className="sulwhasoo-concept-img">
					<img src={concept} alt="" />
				</div>

				<div className="content">
					<div className="title">CONCEPT</div>
					<div className="detail">
						이번 프로젝트는 설화수 만의 오랜 연구 역사, 시간이 흘러도 건강하게 빛나는
						아름다움이라는 주제에 초점을 두고 그것을 시각적으로 표현해내는 것을 중점에
						두었습니다.
					</div>
				</div>
			</div>

			<div className="sulwhasoo-key">
				<img src={img_concept_9} alt="" />
				<img src={img_concept_10} alt="" />
			</div>

			<div className="sulwhasoo-gif">
				<img src={gif_1} alt="" />
				<div className="sulwhasoo-gif-container">
					<div className="title">Typography</div>
					<div className="detail">
						이번 프로젝트에 사용된 설화수의 지정 서체는 설화수가 추구하는 브랜드 가치를
						효과적으로 표현할 수 있도록 2가지 종류의 서체를 <br />
						사용하였습니다. 영문과 한글 버전을 달리하여 브랜드 이미지가 고객에게 잘
						전달될 수 있도록 적용하였습니다.
					</div>
				</div>
			</div>

			<div className="sulwhasoo-logo">
				<div className="sulwhasoo-logo-container">
					<img src={img_logo} alt="" />
				</div>
			</div>

			<div className="sulwhasoo-keyvisual">
				<div className="sulwhasoo-keyvisual-container">
					<div className="content">
						<div className="title">Keyvisual</div>
						<div className="detail">
							설화수 베스트셀러 제품이 다채로운 컬러감의 배경과 키포인트가 되는 아트
							오브제와 함께 만나 조화롭게 어우러집니다. 오브제들은 과거에 <br />
							머무르지 않고 본연의 아름다움을 탐구한다는 설화수의 믿음처럼 여러 장소를
							거쳐 마침내 목표 장소에 도달하는 흐름을 표현하고 있습니다. <br />
							모두가 추구하는 본연의 아름다움이 결국 설화수에 있다는 것을 보여줍니다.
						</div>
					</div>

					<div className="img">
						<img src={img_keyvisual} alt="" />
					</div>
				</div>
			</div>

			<div className="sulwhasoo-list-gif">
				<img src={gif_2} alt="" />
				<img src={gif_3} alt="" />
			</div>

			<div className="sulwhasoo-gif-key">
				<img src={gif_4} alt="" />
				<img src={img_mockup} alt="" />
				<img src={img_mockup_2} alt="" />
				<img src={img_mockup_3} alt="" />
				<img src={img_epi} alt="" />
			</div>
		</div>
	);
}
export default SulwhasooPage;
