import React, { useEffect } from "react";

import "./Contact.scss";
import Footer from "Components/Common/Footer/Footer";

import ContactMain from "Assets/Images/Contact/contact-main@3x.png";
import ContactMap from "Assets/Images/Contact/contact-map@3x.png";
import ContactMapMobile from "Assets/Images/Contact/map-mobile.jpg";
import { useTranslation } from "react-i18next";

function Contact() {
	const { t, i18n } = useTranslation();
	const handleMapImage = () => {
		if (window.innerWidth < 767) {
			return <img className="contact-map" src={ContactMapMobile} alt="" />;
		} else {
			return <img className="contact-map" src={ContactMap} alt="" />;
		}
	};
	return (
		<>
			<div className="contact-container">
				<ul className="group">
					<img className="contact-main" src={ContactMain} alt="" />
					{handleMapImage()}
					<div className="loocreative">
						<span className="title">{t("contact_text_0")}</span>
						<span className={"sub-text st1 " + (i18n.language === "ko" ? "ko" : "en")}>
							{t("address")}
						</span>
						<br />
						<span className={"sub-text " + (i18n.language === "ko" ? "ko" : "en")}>TEL. 02.543.4983</span>

					</div>

					<div className="company-profile">
						<span className="title">{t("contact_text_1")}</span>
						<span className={"sub-text st1 " + (i18n.language === "ko" ? "ko" : "en")}>{t("contact_text_2")}</span>
						<br />
						<span className={"end-text under-line " + (i18n.language === "ko" ? "ko" : "en")}>
							{t("contact_text_3")}
						</span>
						<div className="btn-list">
							<span className="download">{t("download")}</span>
						</div>
					</div>
				</ul>
				<Footer />
			</div>
		</>
	);
}
export default Contact;
