import React from "react";

import imgMain from "../Source/img-main.png";

import imgConcept1 from "../Source/img-concept-01.png";
import imgConcept2 from "../Source/img-concept-02.png";
import imgConcept3 from "../Source/img-concept-03.png";
import imgConcept4 from "../Source/img-concept-04.png";

import imgSlogan from "../Source/img-slogan.png";

import imgTypo1 from "../Source/typo-01.png";
import imgTypo2 from "../Source/typo-02.png";

import imgVisual1 from "../Source/img-keyvisual-01.png";
import imgVisual2 from "../Source/img-keyvisual-02.png";
import imgVisual3 from "../Source/img-keyvisual-03.png";
import imgVisual4 from "../Source/img-keyvisual-04.png";
import imgVisual5 from "../Source/img-keyvisual-05.png";
import imgVisual6 from "../Source/img-keyvisual-06.png";
import imgVisual7 from "../Source/img-keyvisual-07.png";

import imgMockup1 from "../Source/bg-mockup-01.png";
import imgMockup2 from "../Source/bg-mockup-02.png";
import imgMockup3 from "../Source/bg-mockup-03.png";
import imgMockup4 from "../Source/bg-mockup-04.png";

import MasterVideo from "../Source/master.mp4";

import "./styles.scss";

const PageBg = () => {
	return (
		<div className="lottegroup">
			<div className="main-bg">
				<img src={imgMain} alt="img" />
			</div>

			<div className="over-view">
				<div className="content">
					<div className="top">Project Overview</div>
					<div className="bottom">
						롯데는 2021년 7월 「오늘을 새롭게, 내일을 이롭게. 롯데(New Today, Better
						Tomorrow. LOTTE)」라는 새로운 슬로건을 발표했습니다. 급변하는 시대에 발맞춰
						인류의 지속가능한 미래를 위해 모두에게 이로운 혁신을 하겠다는 의지를 담으며,
						고객이 요구하는 미래가치에 발맞춰 일상과 사회를 긍정적으로 변화시키겠다는
						롯데의 역할과 의지를 공고히 하고 있습니다. 이번에는 다양한 사람들의 꿈과
						도전을 응원하는 롯데의 또다른 모습을 소비자의 기대감과 공감을 여러가지
						시리즈의 영상을 통해 효과적으로 불러일으킬 수 있도록 하였습니다.
					</div>
				</div>
			</div>

			<div className="main-bg">
				<img src={require("../Source/01.gif")} alt="loading..." />
			</div>

			<div className="concept">
				<div className="title">Concept</div>

				<div className="core-img">
					<div className="item">
						<img src={imgConcept1} alt="img" />
						<div className="text">
							<div className="top">환상같은 모습의 다양한 풍경들</div>
							<div className="bottom">Fantastic a variety of landscapes</div>
						</div>
					</div>
					<div className="item right">
						<img src={imgConcept2} alt="img" />
						<div className="text">
							<div className="top">콜라주 느낌의 슬로건 텍스트와 패턴</div>
							<div className="bottom">Collage-like slogan text and patterns</div>
						</div>
					</div>
					<div className="item right">
						<img src={imgConcept3} alt="img" />
						<div className="text">
							<div className="top">다양한 사람들의 꿈과 도전을 보여주는</div>
							<div className="bottom">Dreams and Challenges of Various People</div>
						</div>
					</div>
				</div>

				<div className="bottom-img">
					<img src={imgConcept4} alt="img" />
				</div>
			</div>

			<div className="main-bg">
				<img src={imgSlogan} alt="img" />
			</div>

			<div className="typography-section">
				<div className="title">Typography</div>
				<div className="typo-wraper">
					<div className="item">
						<img src={imgTypo1} alt="img" />
					</div>
					<div className="item">
						<img src={imgTypo2} alt="img" />
					</div>
				</div>
			</div>

			<div className="main-bg">
				<img src={require("../Source/03.gif")} alt="loading..." />
			</div>

			<div className="key-visual-1">
				<div className="title">Key Visual</div>
				<div className="key-wraper">
					<div className="item">
						<img src={imgVisual1} alt="img" />
					</div>
					<div className="item">
						<img src={imgVisual2} alt="img" />
					</div>
				</div>
			</div>

			<div className="main-bg">
				<img src={imgVisual3} alt="img" />
			</div>

			<div className="key-visual-2">
				<div className="item">
					<img src={imgVisual4} alt="img" />
				</div>
				<div className="item">
					<img src={imgVisual5} alt="img" />
				</div>
			</div>

			<div className="main-bg">
				<img src={require("../Source/04.gif")} alt="loading..." />
			</div>

			<div className="main-bg">
				<img src={imgVisual6} alt="img" />
			</div>

			<div className="key-visual-3">
				<div className="item">
					<img src={imgVisual7} alt="img" />
				</div>
			</div>

			<div className="mainVideo" style={{ paddingBottom: 0 }}>
				<video autoPlay muted loop>
					<source src={MasterVideo} type="video/mp4" />
				</video>
			</div>

			<div className="main-bg">
				<img src={imgMockup1} alt="img" />
			</div>
			<div className="main-bg">
				<img src={imgMockup2} alt="img" />
			</div>
			<div className="main-bg">
				<img src={imgMockup3} alt="img" />
			</div>
			<div className="main-bg">
				<img src={imgMockup4} alt="img" />
			</div>
		</div>
	);
};

export default PageBg;
