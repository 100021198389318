import React from "react";
import HomeBg from "./components/HomeBg";
import "./SamsungNeoQLEDPage.scss";

function SamsungNeoQLEDPage() {
	return (
		<div className="samsungNeoQLEDPage">
			<HomeBg />
		</div>
	);
}
export default SamsungNeoQLEDPage;
