import { Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { IThumbnail } from "../DataList";
import { addThumbnail } from "./thumbnailSlice";
import "./GetThumbnail.scss";
/////
export function GetThumbnail({ id, url, height }: any) {
	/////
	const [thumb, setThumb] = useState(url);

	const [result, setResult] = useState("") as any;
	const dispatch = useAppDispatch();
	const thumbnailList = useAppSelector(state => state.thumbnail.thumbnailList);

	function getVimeoThumbnail(url: string) {
		if (url) {
			let video_id: string;
			let thumbnail: string;
			// console.log("url",url);

			if (url.match(/https?:\/\/(www\.)?vimeo.com\/(\d+)($|\/)/)) {
				video_id = url.split("/")[3];
			} else if (url.match(/^vimeo.com\/channels\/[\d\w]+#[0-9]+/)) {
				video_id = url.split("#")[1];
			} else if (url.match(/vimeo.com\/groups\/[\d\w]+\/videos\/[0-9]+/)) {
				video_id = url.split("/")[4];
			} else if (url.match(/player.vimeo.com\/video\/[0-9]+/)) {
				video_id = url.split("/")[4];
			}
			return new Promise((resolve, reject) => {
				fetch("https://vimeo.com/api/oembed.json?url=https%3A//vimeo.com/" + video_id).then(
					response => {
						response
							.json()
							.then(jsonData => {
								const res = jsonData.thumbnail_url.split("_")[1];
								thumbnail = jsonData.thumbnail_url.replace(res, "1280x720");
								resolve(thumbnail);
							})
							.catch(error => {
								console.error(error);
								reject(false);
							});
					}
				);
			});
		}
	}

	const generalThumb = async (thumb: string) => {
		const thumbnail = await getVimeoThumbnail(thumb);
		setResult(thumbnail);
		const action = addThumbnail({
			id: id,
			value: thumbnail
		});
		dispatch(action);
	};

	useEffect(() => {
		const item = thumbnailList.find((item: IThumbnail) => item.id === id);

		if (item) {
			setResult(item.value);
		} else {
			generalThumb(thumb);
		}
	}, []);

	if (!result) {
		return <div className="skeleton" />;
	}

	return (
		<>
			<img
				id={id}
				key={"image_" + result}
				style={{ width: "100%", height: "100%" }}
				src={result}
				alt=""
				className="fade-in"
			/>
			{/* <div
				className="contents_img"
				style={{ height: "100%", width: "100%", backgroundImage: `url(${result})` }}
			/> */}
		</>
	);
}
