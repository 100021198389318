import React from "react";
import bg from "../../Contents/bg-main.png";
import bg_mockup_01 from "../../Contents/img-mockup-01.png";
import bg_mockup_02 from "../../Contents/img-mockup-02.png";
import bg_mockup_03 from "../../Contents/img-mockup-03.png";
import bg_mockup_04 from "../../Contents/img-mockup-04.png";
import bg_mockup_05 from "../../Contents/img-mockup-05.png";
import bg_mockup_06 from "../../Contents/img-mockup-06.png";
import bg_concept_01 from "../../Contents/img-concept-01.png";
import bg_concept_02 from "../../Contents/img-concept-02.png";
import bg_concept_03 from "../../Contents/img-concept-03.png";
import bg_concept_04 from "../../Contents/img-concept-04.png";
import bg_color_01 from "../../Contents/img-color-01.png";
import bg_color_02 from "../../Contents/img-color-02.png";
import bg_color_03 from "../../Contents/img-color-03.png";
import bg_ref_01 from "../../Contents/img-ref-01.png";
import bg_ref_02 from "../../Contents/img-ref-02.png";
import bg_ref from "../../Contents/bg-ref.png";
import bg_3d_01 from "../../Contents/img-3-d-01.png";
import bg_3d_02 from "../../Contents/img-3-d-02.png";

import "./style.scss";
import { IntroSmall } from "../IntroSmall/IntroSmall";
function HomeStarWar() {
	return (
		<div className="home-starWar">
			<div className="imgBg" style={{ position: "relative" }}>
				<img src={bg} alt="" />
			</div>
			<div className="container">
				<IntroSmall
					title1="Project<br>Overview"
					title2="Star Wars™ Special Edition LG OLED"
					detail="LG와 Lucasfilm이 힘을 합쳐 세상에 없던 LG OLED TV를 만들었습니다. 모든 터치에 스타워즈 기능과 다크 사이드로 빠져들게 하는 디자인이 적용되었습니다.
					이를 통해 스타워즈 시리즈를 경험하는 새롭고 장대한 방법이 탄생했습니다. 오리지널 영화의 스토리 보드와 일러스트레이션은 물론, 다스베이더의 어두운 내면을 향한 여정을 아릅답게 렌더링한 아트웍을 통해 OLED를 나만의 갤러리로 변신시켜 보세요."
					styleContainer={{}}
				/>
				<div className="imgBg">
					<img alt="Loading..." src={require("../../Source/face01.gif")} />
				</div>
				<div className="slogan">
					“You’ll Never Watch Star Wars™
					<br /> the Same Way Again”
				</div>
			</div>
			<div className="container" style={{ background: "#1f1f1f", paddingBottom: 200 }}>
				<IntroSmall
					title1="Concept"
					detail="메인 컨셉은 원작 영화를 연상케 하는 스타워즈의 요소를 적극적으로 활용하였습니다. 배경은 우주를 중심으로 디자인하였으며, 단순히 다스베이더의 모습을 보여주는 것이 아닌 심플하면서도 이목을 집중시키는 라인 오브젝트, 포인트 컬러를 사용한 라이팅으로
				보는 사람의 이목을 끌어들이는 압도적인 연출을 가능케 했습니다."
					styleContainer={{}}
				/>
				<div className="star-concept">
					<div>
						<img src={bg_concept_01} alt="" />
					</div>
					<div>
						<img src={bg_concept_02} alt="" />
					</div>
					<div>
						<img src={bg_concept_03} alt="" />
					</div>
					<div className=""></div>
					<div>
						<img src={bg_concept_04} alt="" />
					</div>
					<div className=""></div>
				</div>
			</div>
			<div className="container" style={{ background: "#161616", paddingBottom: 200 }}>
				<IntroSmall
					title1="Color"
					detail="사용된 색상은 짙은 검정, 흰색 및 빨간색입니다. 특히 대부분의 배경 색상을 검은색으로 지정하고 포인트 컬러를 빨간색을 사용하여 영상에 나오는 콘텐츠에 시선이 잘 집중될 수 있게 하였습니다."
					styleContainer={{}}
				/>
				<div className="star-color">
					<img src={bg_color_01} alt="" />
					<img src={bg_color_02} alt="" />
					<img src={bg_color_03} alt="" />
				</div>
			</div>
			<div className="container star-ref" style={{ backgroundImage: `url(${bg_ref})` }}>
				<IntroSmall
					title1="Reference"
					detail="주로 스타워즈 및 우주와 관련 요소를 선택했습니다. 이러한 요소들을 모아 구성을 심플하면서도 보는 사람의 시선을 집중시켜 더욱 인상적으로 변화시키고자 하였습니다. 우주를 표현하면서도 스타워즈에 대해서도 자연스럽게 녹여내어 몰입감을 높였습니다."
					styleContainer={{}}
				/>
				<div className="star-ref-img">
					{/* <div className="imgBg">
						<img src={bg_ref_01} alt="" />
					</div> */}
					<div className="imgBg">
						<img src={bg_ref_02} alt="" />
					</div>
				</div>
			</div>
			<div className="container" style={{ background: "#000000", paddingBottom: 200 }}>
				<IntroSmall
					title1="3D Design"
					detail="3D 프로그램으로 렌더링된 다스베이더는 단순히 리얼하게 구현되는 것에 초점을 두지 않았습니다. 원작 영화에서만 볼 수 있었던 다스베이더만의 압도적인 분위기를 표현하기 위해 정교한 움직임과 요소, 라이팅에 특히 신경썼습니다."
					styleContainer={{}}
				/>
				<div className="img-double">
					<img src={bg_3d_01} alt="" />
					<img src={bg_3d_02} alt="" />
				</div>
				<div className="imgBg">
					<img alt="Loading..." src={require("../../Source/face01.gif")} />
				</div>
			</div>

			<div className="imgBg">
				<img src={bg_mockup_01} alt="" />
			</div>
			<div className="imgBg">
				<img src={bg_mockup_02} alt="" />
			</div>
			<div className="imgBg">
				<img src={bg_mockup_03} alt="" />
			</div>
			<div className="imgBg">
				<img src={bg_mockup_04} alt="" />
			</div>
			<div className="imgBg">
				<img src={bg_mockup_05} alt="" />
			</div>
			<div className="imgBg">
				<img src={bg_mockup_06} alt="" />
			</div>
		</div>
	);
}

export default HomeStarWar;
