import React from "react";

import mainBg from "../Source/main-bg.png";
import Opening from "../Source/opening_02.gif";

import imgOverview01 from "../Source/img-overview-01.png";

import imgConcept01 from "../Source/img-concept-01.png";
import imgConcept02 from "../Source/img-concept-02.png";
import imgConcept03 from "../Source/img-concept-03.png";

import handGif from "../Source/hand_01.gif";

import logo01 from "../Source/img-logo-01.png";
import logo02 from "../Source/img-logo-02.png";

import imgVisual01 from "../Source/img-keyvisual-01.png";
import imgVisual02 from "../Source/img-keyvisual-02.png";
import imgVisual03 from "../Source/img-keyvisual-03.png";

import catGif from "../Source/cat_04.gif";
import cat3Gif from "../Source/cat_03.gif";

import imgVisual05 from "../Source/img-keyvisual-05.png";

import imgStory01 from "../Source/img-story-01.png";
import S05Gif from "../Source/S05.gif";

import imgAdv01 from "../Source/img-advertisement-01.png";
import imgAdv02 from "../Source/img-advertisement-02.png";
import imgAdv03 from "../Source/img-advertisement-03.png";
import imgAdv04 from "../Source/img-advertisement-04.png";
import imgAdv05 from "../Source/img-concept-01@2x.png";
import MasterVideo from "../Source/master.mp4";

import imgend from "../Source/bg-epilogue.png";

import "./styles.scss";

const PageBg = () => {
	return (
		<div className="drbrain">
			<div className="main-bg">
				<img src={mainBg} alt="img" />
			</div>

			<div className="overview">
				<div className="item">
					<div className="top">DR.BRAIN</div>
					<div className="bottom">
						Dr. Brain is a 2021 South Korean web series created by Kim Jee-woon. The
						sci-fi thriller is based on the Korean webtoon of the same name by
						Hongjacga. Lee Sun-kyun stars in the lead role as a brain scientist trying
						to solve the mysterious death of his family by hacking into the brains of
						the deceased.
					</div>
				</div>
			</div>

			<div className="main-bg">
				<img src={imgOverview01} alt="img" />
			</div>

			<div className="overview">
				<div className="item">
					<div className="top">Concept</div>
					<div className="bottom">
						Visuals with extremely low seriousness and weight using realistic 3D.
						Representation of repeated brain scans “Connection - Assimilation - Tracking
						- Ego Division”. Creating a bizarre screen through POLY MESH technique.
					</div>
				</div>
			</div>
			<div className="main-bg">
				<img src={imgAdv05} alt="img" />
			</div>
			<div className="realistic">
				<img src={imgConcept01} alt="" />
				<img src={imgConcept02} alt="" />
				<img src={imgConcept03} alt="" />
			</div>
			<div className="block"></div>
			<div className="main-bg">
				<img src={handGif} alt="img" />
			</div>

			<div className="overview">
				<div className="item">
					<div className="top">logo</div>
					<div className="bottom">
						The logo used for Dr. Brain is originally the font Bebas Neue Regular.
						However, it has been edited to suit the concept.
					</div>
				</div>
			</div>

			<div className="logo">
				<img src={logo01} alt="" />
				<img src={logo02} alt="" />
			</div>

			<div className="main-bg">
				<img src={Opening} alt="img" />
			</div>

			<div className="overview">
				<div className="item">
					<div className="top">Key Visual</div>
					<div className="bottom">
						Harmony between dark background and clear white tone (first half) Expression
						of animal eyes and pupils expressed with hyper-realistic CG
					</div>
				</div>
			</div>

			<div className="logo">
				<img src={imgVisual01} alt="" />
				<img src={imgVisual02} alt="" />
			</div>

			<div className="main-bg">
				<img src={imgVisual03} alt="img" />
			</div>

			<div className="main-bg">
				<img src={catGif} alt="img" />
			</div>
			<div className="main-bg">
				<img src={cat3Gif} alt="img" />
			</div>

			<div className="overview">
				<div className="item">
					<div className="top">Story</div>
					<div className="bottom">
						{`A quiet and dark space. I'm heading towards somewhere, and the place I've arrived
A world that is not the reality that the corpse shows. Busily moving in search 
The world seen through my own eyes, not mine.
In my head, which has already been broken and complicated by these things...`}
					</div>
				</div>
			</div>

			<div className="main-bg">
				<img src={imgStory01} alt="img" />
			</div>
			<div className="main-bg">
				<img src={S05Gif} alt="img" />
			</div>

			<div className="overview">
				<div className="item">
					<div className="top">advertisement</div>
					<div className="bottom">
						We have made billboards placed in high population areas. Billboards both
						show the content of the movie, as well as convey specific information about
						the movie to viewers.
					</div>
				</div>
			</div>

			<div className="main-bg">
				<img src={imgAdv01} alt="img" />
			</div>

			<div className="mainVideo" style={{ paddingBottom: 0 }}>
				<video autoPlay muted loop>
					<source src={MasterVideo} type="video/mp4" />
				</video>
			</div>

			<div className="main-bg">
				<img src={imgAdv02} alt="img" />
			</div>
			<div className="main-bg">
				<img src={imgAdv03} alt="img" />
			</div>
			<div className="main-bg">
				<img src={imgAdv04} alt="img" />
			</div>

			<div className="main-bg">
				<img src={imgend} alt="img" />
			</div>
		</div>
	);
};

export default PageBg;
