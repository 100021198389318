import { useRef } from "react";

//hook
function useMoveScroll(id: string) {
	const element = useRef<HTMLDivElement>(null);
	const onMoveToElement = () => {
		element.current?.scrollIntoView({ behavior: "smooth", block: "start" });
	};
	return { id, element, onMoveToElement };
}

export default useMoveScroll;
