import React from "react";

import "./Intro.scss";

export const Intro = ({
	title,
	detail,
	isCenter,
	isHorizontal,
	styleContainer
}: {
	title: string;
	detail: string;
	isCenter: boolean;
	isHorizontal?: boolean;
	styleContainer?: object;
}) => {
	return (
		<div className="intro-must">
			{title && detail ? (
				<div
					className={`intro-must-container ${isHorizontal ? "horizontal" : ""}`}
					style={styleContainer}
				>
					<div className={`title ${isCenter ? "center" : ""}`}>{title}</div>
					<div
						className={`detail ${isCenter ? "center" : ""} ${
							isHorizontal ? "maxWidth" : ""
						}`}
					>
						{detail}
					</div>
				</div>
			) : (
				<div
					className={`intro-must-container ${isHorizontal ? "horizontal" : ""}`}
					style={{ padding: "70px 0" }}
				>
					<div className={`title ${isCenter ? "center" : ""}`}>{title}</div>
					<div className={`detail ${isCenter ? "center" : ""}`}>{detail}</div>
				</div>
			)}
		</div>
	);
};
