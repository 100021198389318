import React from "react";
import "./style.scss";

const OverView = ({
	title,
	detail,
	img,
	imgSecond,
	background,
	centerContent
}: {
	title?: string;
	detail?: string;
	img: any;
	background: string;
	centerContent?: boolean;
	imgSecond?: any;
}) => {
	return (
		<div className="overview" style={{ background: background }}>
			<div className="overview-container">
				{title && detail && (
					<div
						style={{ color: background === "#fff" ? "#0e0b0b" : "#fff" }}
						className={`overview-content ${centerContent ? "centerContent" : ""}`}
					>
						<div className="title">{title}</div>
						<div className={`detail ${centerContent ? "detailContent" : ""}`}>
							{detail}
						</div>
					</div>
				)}
				<div className="overview-img">
					<img src={img} alt="" />
				</div>
				{imgSecond && (
					<div className="overview-img-second">
						{imgSecond && <img src={imgSecond} alt="" />}
					</div>
				)}
			</div>
		</div>
	);
};


export default OverView;