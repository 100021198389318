import React from "react";
import HomeMust from "./components/HomeMust";
import "./MustItPage.scss";
function MustItPage() {
	return (
		<div>
			<HomeMust />
		</div>
	);
}
export default MustItPage;
