import React from "react";
import bg from "./Contents/bg-main.png";
import bg_overview from "./Contents/img-overview.png";
import bg_keyword from "./Contents/img-keyword.png";
import bg_design from "./Contents/img-direction.png";
import bg_object from "./Contents/img-object.png";
import bg_concept from "./Contents/img-concept.png";
import bg_object_concept from "./Contents/bg-object-concept.png";
import bg_conti from "./Contents/img-conti.png";
import bg_key from "./Contents/img-keyvisual-01.png";
import bg_key_2 from "./Contents/img-keyvisual-02.png";
import bg_key_3 from "./Contents/img-keyvisual-03.png";
import bg_key_4 from "./Contents/img-keyvisual-04.png";
import bg_key_visual from "./Contents/bg-keyvisual.png";
import bg_last from "./Contents/bg-epilogue.png";
import gif_img_1 from "./Source/man_turn_01.gif";
import gif_img_1_m from "./Source/man_turn_01.mp4";
import gif_end_02 from "./Source/end_02.gif";
import gif_end_02_m from "./Source/end_02.mp4";
import gif_man_02 from "./Source/man_02.gif";
import gif_man_02_m from "./Source/man_02.mp4";
import gif_man_turn_02 from "./Source/man_turn_02.gif";
import gif_man_turn_02_m from "./Source/man_turn_02.mp4";
import gif_water_01 from "./Source/water_01.gif";
import gif_bg_2 from "./Contents/gif-bg@2x.png";

import "./TheSilentSeaPage.scss";
import OverView from "./components/Overview";
import { data } from "./data";

function TheSilentSea() {
	return (
		<div className="silentSea">
			<div className="background">
				<img src={bg} alt="" />
				<div className="content">
					<div className="title">고요의 바다</div>
					<div className="detail">Project Guide</div>
				</div>
			</div>

			<OverView
				title="Overview"
				img={bg_overview}
				background="#0e0b0b"
				detail={data.data_1}
			/>

			<OverView title="Keyword" img={bg_keyword} background="#000000" detail={data.data_2} />



			<div className="gif_end_02" >
				<div className="front">
					<video
						autoPlay
						loop
						muted
						playsInline
						width="100%"
						height="100%"
					>
						<source src={gif_end_02_m} type="video/mp4" />
					</video>
				</div>
				<div className="back"><img alt="Loading..." src={gif_bg_2} /></div>
			</div>


			<OverView
				title="Design Direction"
				img={bg_design}
				background="#fff"
				detail={data.data_3}
			/>

			<div className="layout-bg">
				<div className="layout-bg-img">
					<img src={bg_object_concept} alt="" />
				</div>
				<OverView
					title="Object"
					img={bg_object}
					background="transparent"
					detail={data.data_4}
				/>
				<OverView
					title="Concept"
					img={bg_concept}
					background="transparent"
					detail={data.data_5}
				/>
			</div>

			<OverView
				title="Conti"
				img={bg_conti}
				background="#fff"
				centerContent={true}
				detail={data.data_6}
			/>

			<div className="img-gif-flex">
				{/* <img src={gif_img_1} alt="" /> */}
				<video
					autoPlay
					loop
					muted
					playsInline
					width="100%"
					height="100%"
				>
					<source src={gif_img_1_m} type="video/mp4" />
				</video>
				{/* <img src={gif_man_02} alt="" /> */}
				<video
					autoPlay
					loop
					muted
					playsInline
					width="100%"
					height="100%"
				>
					<source src={gif_man_02_m} type="video/mp4" />
				</video>
				{/* <img src={gif_man_turn_02} alt="" /> */}
				<video
					autoPlay
					loop
					muted
					playsInline
					width="100%"
					height="100%"
				>
					<source src={gif_man_turn_02_m} type="video/mp4" />
				</video>
			</div>

			{/* <div className="img-gif-bg">
			</div> */}

			<OverView
				title="Keyvisual"
				img={bg_key}
				imgSecond={bg_key_2}
				background="#0F0B0B"
				centerContent={true}
				detail={data.data_7}
			/>

			<div className="gif_end_02">
				<img alt="Loading..." src={require("./Source/water_01.gif")} />
			</div>

			<div className="layout-bg">
				<div className="layout-bg-img">
					<img src={bg_key_visual} alt="" />
				</div>
				<OverView img={bg_key_3} background="transparent" />
				<OverView img={bg_key_4} background="transparent" />
			</div>

			<div className="background">
				<img src={bg_last} alt="" />
			</div>
		</div >
	);
}
export default TheSilentSea;
