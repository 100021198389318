import { IProjectData } from "Components/Common/DataList/DataList";
//ARTWORK
import ailien from "./workId/childs/motion/Alien.jpg";
import alienDetail from "./workId/childs/motion/alienDetail.jpeg";
import bespoke2 from "./workId/childs/motion/bespoke2.jpg";
import bespokeDetail from "./workId/childs/motion/bespoke.jpg";
import samsung from "./workId/childs/motion/samsung.jpg";
import samsungDetail from "./workId/childs/motion/betterSamsung.jpeg";
import whatHappend from "./workId/childs/motion/whatHappened.jpg";
import whatHappendDetail from "./workId/childs/motion/whatHappenedDetail.jpg";
import milkshake from "./workId/childs/motion/milkshake.jpg";
import milkshakeDetail from "./workId/childs/motion/milkShakeDetail.jpg";
// import priest from "./workId/childs/motion/Test01_thum.jpeg";
import priestDetail from "./workId/childs/motion/priest.jpg";
// import ktSkylife from "./workId/childs/motion/ktSkylife.jpg";
import ktSkylifeDetail from "./workId/childs/motion/skylifeArt.jpg";
import timingDark from "./workId/childs/motion/timingdark.jpg";
import timingDarkDetail from "./workId/childs/motion/timingdark.jpg";
import dark from "./workId/childs/motion/dark.jpg";
import darkDetail from "./workId/childs/motion/darkCircle.jpg";
import timing from "./workId/childs/motion/timing.jpg";
import timingDetail from "./workId/childs/motion/timing.jpg";
// import lyn from "./workId/childs/motion/lyn.jpg";
import lynDetail from "./workId/childs/motion/lyn.jpg";
import victor from "./workId/childs/motion/victor.jpg";
import victorDetail from "./workId/childs/motion/victor.jpg";

import hyundai_art from "./workId/childs/motion/hyundai_art.jpg";
import hyundai_artDetail from "./workId/childs/motion/hyundaiArt.jpg";

import ToastCanvas from "./workId/childs/ToastCanvas/ToastCanvas.jpg";
import Exon from "./workId/childs/Exon/Exon_v2.png";
import ThuNulli from "./workId/childs/TheNulli/TheNullI.jpeg";

import Icarus from "./workId/childs/Icarus/Icarus.jpeg";
import IcarusDetail from "./workId/childs/Icarus/210427_1차완성_Ash.jpg";

import Prong from "./workId/childs/Prong/PRONG.jpeg";
import Yame from "./workId/childs/Yame/Yame.jpeg";
import FarmPharm from "./workId/childs/FarmPharm/thumb_Farm_840x528.jpg";
import Sareuri from "./workId/childs/ㅅㅇㄹ/thumb_ㅅㅇㄹ_840x528.jpg";
import GuardSignature from "./workId/childs/GuardSignature/thumb_GuardSignature.jpg";
import WhatHappen from "./workId/childs/WHT30/thumb_WHT30_840x528.jpg";

import Artificialcity from "./workId/childs/Artificialcity/thumb_artificialcity_840x528.jpg";

import NOMAD from "./workId/childs/NOMAD/thumb_nomad_840x528.jpg";
import UIX from "./workId/childs/UIX/thumb_uix_840x528.jpg";
import KKAL from "./workId/childs/KKAL/thumb_KKAL_840x528.jpg";

import Sejong from "./workId/childs/Sejong/thumb_sejong_840x528.jpg";
import SejongDetail from "./workId/childs/Sejong/Contents/220216_sejong_projectguide.jpg";

import Methin from "./workId/childs/Methin/thumb_methin_840x528.jpg";
import ServerKit from "./workId/childs/Servekit/thumb_severkit_840x528.jpg";
import ConceptCube from "./workId/childs/ConceptCube/thumb_conceptcube_840x528.jpg";
import LocaMoney from "./workId/childs/LocaMoney/thumb_loca_840x528.jpg";
import Maxius from "./workId/childs/Maxius/thumb_maxius_840x528.jpg";


//detail image
import Ocean from "./workId/childs/고요의바다/Contents/image.jpg";
import DrBrain from "./workId/childs/DrBrain/Contents/image.png";
import DIGILOCA from "./workId/childs/DIGILOCA/Contents/image.jpg";
import ArtificialcityDetail from "./workId/childs/Artificialcity/Contents/PG_ArtificialCity.jpg";
import SQLED from "./workId/childs/SamsungQLED/Contents/image.jpg";
import Laneige from "./workId/childs/Laneige/Contents/image.jpg";
import CES2021Title from "./workId/childs/CES2021Title/Contents/image.jpeg";
import MustIt from "./workId/childs/MustIt/Contents/image.jpg";
import LocaMoneyDetail from "./workId/childs/LocaMoney/Contents/220214_locacard.jpg";
import Oera_Detail from "./workId/childs/Oera/Contents/image.jpg";
import CES2023 from "./workId/childs/CES2023Opening/Contents/image.jpg";
import SamsungGlaxyBookDetail from "./workId/childs/SamsungGalaxyBook2/Contents/image.jpg";
import SulwhasooContents from "./workId/childs/Sulwhasoo/Contents/image.jpg";
import LG_OLED_STARWARS from "./workId/childs/LG_OLED_STARWARS/Contents/image.jpg";
export const WorkContetntData: IProjectData[] = [
	/// 모든 정렬 순서는 가장 최근것이 상위에 표시되어야함 , Shoreel 영상 밑에로 정렬 필요.
	{
		id: "Showreel2023",
		name: {
			ko: "2023 Looc Showreel",
			en: "2023 Looc Showreel",
			jp: "",
			cn: ""
		},
		company: {
			ko: "2023 Looc Showreel",
			en: "2023 Looc Showreel",
			jp: "",
			cn: ""
		},
		type: "SHOWREEL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/834677738"
	},
	//--------------------------------------------------------------------------------------------------------------------
	{
		id: "823578943",
		name: {
			ko: "Samsung Card l The iD",
			en: "Samsung Card l The iD",
			jp: "",
			cn: ""
		},
		company: {
			ko: "Samsung Card l The iD",
			en: "Samsung Card l The iD",
			jp: "",
			cn: ""
		},
		type: "",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/823578943"
	},
	{
		id: "823578916",
		name: {
			ko: "Amazing Oat",
			en: "Amazing Oat",
			jp: "",
			cn: ""
		},
		company: {
			ko: "Amazing Oat",
			en: "Amazing Oat",
			jp: "",
			cn: ""
		},
		type: "",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/823578916"
	},
	{
		id: "823573458",
		name: {
			ko: "KIA Brand Film",
			en: "KIA Brand Film",
			jp: "",
			cn: ""
		},
		company: {
			ko: "KIA Brand Film",
			en: "KIA Brand Film",
			jp: "",
			cn: ""
		},
		type: "",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/823573458"
	},
	{
		id: "823573368",
		name: {
			ko: "LG OLED T l 투명한미래 전",
			en: "LG OLED T l 투명한미래 전",
			jp: "",
			cn: ""
		},
		company: {
			ko: "LG OLED T l 투명한미래 전",
			en: "LG OLED T l 투명한미래 전",
			jp: "",
			cn: ""
		},
		type: "",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/823573368"
	},
	
	{
		id: "823573238",
		name: {
			ko: "Samsung l Eco Heating System™",
			en: "Samsung l Eco Heating System™",
			jp: "",
			cn: ""
		},
		company: {
			ko: "Samsung l Eco Heating System™",
			en: "Samsung l Eco Heating System™",
			jp: "",
			cn: ""
		},
		type: "",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/823573238"
	},
	{
		id: "823573183",
		name: {
			ko: "Samsung l One UI 5",
			en: "Samsung l One UI 5",
			jp: "",
			cn: ""
		},
		company: {
			ko: "Samsung l One UI 5",
			en: "Samsung l One UI 5",
			jp: "",
			cn: ""
		},
		type: "",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/823573183"
	},

	{
		id: "823573076",
		name: {
			ko: "SK Broadband",
			en: "SK Broadband",
			jp: "",
			cn: ""
		},
		company: {
			ko: "SK Broadband",
			en: "SK Broadband",
			jp: "",
			cn: ""
		},
		type: "",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/823573076"
	},

	
	{
		id: "823572825",
		name: {
			ko: "MEGA MGC COFFEE l 손흥민편",
			en: "MEGA MGC COFFEE l 손흥민편",
			jp: "",
			cn: ""
		},
		company: {
			ko: "MEGA MGC COFFEE l 손흥민편",
			en: "MEGA MGC COFFEE l 손흥민편",
			jp: "",
			cn: ""
		},
		type: "",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/823572825"
	},
	
	{
		id: "Samsung_722443463",
		name: {
			ko: "롯데 기업PR l 오늘을 새롭게 내일을 이롭게",
			en: "LOTTE Corporate PR l Make today new for tomorrow",
			jp: "",
			cn: ""
		},
		company: {
			ko: "롯데 기업PR l 오늘을 새롭게 내일을 이롭게",
			en: "LOTTE Corporate PR l Make today new for tomorrow",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/722443463"
	},
	{
		id: "Samsung_722450037",
		name: {
			ko: "펩시 l 제로슈가",
			en: "Pepsi l Zero Sugar",
			jp: "",
			cn: ""
		},
		company: {
			ko: "펩시 l 제로슈가",
			en: "Pepsi l Zero Sugar",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/722450037"
	},
	{
		id: "Samsung_722438803",
		name: {
			ko: "셀러비 l 지수 편",
			en: "Celebrity l Index",
			jp: "",
			cn: ""
		},
		company: {
			ko: "셀러비 l 지수 편",
			en: "Celebrity l Index",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/722438803"
	},
	{
		id: "Samsung_722443451",
		name: {
			ko: "파리바게트 Lab",
			en: "Paris Baguette Lab",
			jp: "",
			cn: ""
		},
		company: {
			ko: "파리바게트 Lab",
			en: "Paris Baguette Lab",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/722443451"
	},
	{
		id: "Samsung_722438696",
		name: {
			ko: "삼성 l 트라이갤럭시",
			en: "Samsung l Tri-Galaxy",
			jp: "",
			cn: ""
		},
		company: {
			ko: "삼성 l 트라이갤럭시",
			en: "Samsung l Tri-Galaxy",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/722438696"
	},
	
	{
		id: "Monstrous_722452776",
		name: {
			ko: "괴이 l 오프닝타이틀",
			en: "Monstrous l Opening Title",
			jp: "",
			cn: ""
		},
		company: {
			ko: "괴이 l 오프닝타이틀",
			en: "Monstrous l Opening Title",
			jp: "",
			cn: ""
		},
		type: "OPENING TITLE",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/722452776"
	},
	{
		id: "Life_722434904",
		name: {
			ko: "생활공작소",
			en: "Life Workshop",
			jp: "",
			cn: ""
		},
		company: {
			ko: "생활공작소",
			en: "Life Workshop",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/722438105"
	},
	{
		id: "RidiBooks_722493539",
		name: {
			ko: "리디북스 l 상수리 나무 아래 편",
			en: "Reddy Books l Under the oak tree",
			jp: "",
			cn: ""
		},
		company: {
			ko: "리디북스 l 상수리 나무 아래 편",
			en: "Reddy Books l Under the oak tree",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/722493539"
	},
	{
		id: "HanwhaLife_722438629",
		name: {
			ko: "한화생명 시그니처 암보험 l 본편",
			en: "Hanwha Life Signature Cancer Insurance l Main Part",
			jp: "",
			cn: ""
		},
		company: {
			ko: "한화생명 시그니처 암보험 l 본편",
			en: "Hanwha Life Signature Cancer Insurance l Main Part",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/722438629"
	},
	{
		id: "HanwhaLife_722438552",
		name: {
			ko: "한화생명 시그니처 암보험 l 보장편",
			en: "Hanwha Life Signature Cancer Insurance l Coverage",
			jp: "",
			cn: ""
		},
		company: {
			ko: "한화생명 시그니처 암보험 l 보장편",
			en: "Hanwha Life Signature Cancer Insurance l Coverage",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/722438552"
	},
	{
		id: "HanwhaLife_722438589",
		name: {
			ko: "한화생명 시그니처 암보험 l 설계편",
			en: "Hanwha Life Signature Cancer Insurance l Design",
			jp: "",
			cn: ""
		},
		company: {
			ko: "한화생명 시그니처 암보험 l 설계편",
			en: "Hanwha Life Signature Cancer Insurance l Design",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/722438589"
	},
	{
		id: "DONGWON_700367513",
		name: {
			ko: "동원 l 큐브참치",
			en: "DONGWON l Cube tuna",
			jp: "",
			cn: ""
		},
		company: {
			ko: "동원 l 큐브참치",
			en: "DONGWON l Cube tuna",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/700367513"
	},
	{
		id: "SAMSUNG_700367771",
		name: {
			ko: "삼성 갤럭시 북2 l ScreenSaver",
			en: "SAMSUNG GALAXY Book l ScreenSaver",
			jp: "",
			cn: ""
		},
		company: {
			ko: "삼성 갤럭시 북2 l ScreenSaver",
			en: "SAMSUNG GALAXY Book l ScreenSaver",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/700367771"
	},
	{
		id: "SAMSUNG_700367853",
		name: {
			ko: "삼성 갤럭시 북2 Pro l ScreenSaver",
			en: "SAMSUNG GALAXY Book Pro l ScreenSaver",
			jp: "",
			cn: ""
		},
		company: {
			ko: "삼성 갤럭시 북2 Pro l ScreenSaver",
			en: "SAMSUNG GALAXY Book Pro l ScreenSaver",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/700367853"
	},
	{
		id: "SAMSUNG_700367952",
		name: {
			ko: "삼성 갤럭시 북2 l ScreenSaver",
			en: "SAMSUNG GALAXY Book l ScreenSaver",
			jp: "",
			cn: ""
		},
		company: {
			ko: "삼성 갤럭시 북2 l ScreenSaver",
			en: "SAMSUNG GALAXY Book l ScreenSaver",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/700367952"
	},
	{
		id: "SAMSUNG_700368045",
		name: {
			ko: "삼성 갤럭시 북2 Pro 360 l ScreenSaver",
			en: "SAMSUNG GALAXY Book Pro 360 l ScreenSaver",
			jp: "",
			cn: ""
		},
		company: {
			ko: "삼성 갤럭시 북2 Pro 360 l ScreenSaver",
			en: "SAMSUNG GALAXY Book Pro 360 l ScreenSaver",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/700368045"
	},
	{
		id: "SAMSUNG_700369422",
		name: {
			ko: "삼성 갤럭시A l ScreenSaver",
			en: "SAMSUNG GALAXY A l ScreenSaver",
			jp: "",
			cn: ""
		},
		company: {
			ko: "삼성 갤럭시A l ScreenSaver",
			en: "SAMSUNG GALAXY A l ScreenSaver",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/700369422"
	},
	
	{
		id: "DAOL_692971985",
		name: {
			ko: "다올 l Black ver.",
			en: "DAOL l Black ver.",
			jp: "",
			cn: ""
		},
		company: {
			ko: "다올 l Black ver.",
			en: "DAOL l Black ver.",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/692971985"
	},
	{
		id: "DAOL_692972395",
		name: {
			ko: "다올 l White ver.",
			en: "DAOL l White ver.",
			jp: "",
			cn: ""
		},
		company: {
			ko: "다올 l White ver.",
			en: "DAOL l White ver.",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/692972395"
	},
	{
		id: "SAMSUNG_692972798",
		name: {
			ko: "삼성 Neo QLED l Do Man",
			en: "SAMSUNG Neo QLED l Do Man",
			jp: "",
			cn: ""
		},
		company: {
			ko: "삼성 Neo QLED l Do Man",
			en: "SAMSUNG Neo QLED l Do Man",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/692972798"
	},
	{
		id: "Laneige_685348146",
		name: {
			ko: "라네즈 워터뱅크 l 패키지 편",
			en: "Laneige Water Bank l Package",
			jp: "",
			cn: ""
		},
		company: {
			ko: "라네즈 워터뱅크 l 패키지 편",
			en: "Laneige Water Bank l Package",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/685348146"
	},
	{
		id: "Laneige_685348277",
		name: {
			ko: "라네즈 워터뱅크 l How to 편",
			en: "Laneige Water Bank l How to",
			jp: "",
			cn: ""
		},
		company: {
			ko: "라네즈 워터뱅크 l How to 편",
			en: "Laneige Water Bank l How to",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/685348277"
	},
	{
		id: "Laneige_685348368",
		name: {
			ko: "라네즈 워터뱅크 l 히어로 편",
			en: "Laneige Water Bank l Hero",
			jp: "",
			cn: ""
		},
		company: {
			ko: "라네즈 워터뱅크 l 히어로 편",
			en: "Laneige Water Bank l Hero",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/685348368"
	},
	{
		id: "SAMSUNG_685348435",
		name: {
			ko: "삼성 갤럭시 탭 l 멀티태스킹 편",
			en: "SAMSUNG Galaxy Tab l Multitasking",
			jp: "",
			cn: ""
		},
		company: {
			ko: "삼성 갤럭시 탭 l 멀티태스킹 편",
			en: "SAMSUNG Galaxy Tab l Multitasking",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/685348435"
	},
	{
		id: "SAMSUNG_685348476",
		name: {
			ko: "삼성 갤럭시 탭 l 클립스튜디오 편",
			en: "SAMSUNG Galaxy Tab l Clip Studio",
			jp: "",
			cn: ""
		},
		company: {
			ko: "삼성 갤럭시 탭 l 클립스튜디오 편",
			en: "SAMSUNG Galaxy Tab l Clip Studio",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/685348476"
	},
	{
		id: "SAMSUNG_677513409",
		name: {
			ko: "삼성 l DS",
			en: "SAMSUNG l DS",
			jp: "",
			cn: ""
		},
		company: {
			ko: "삼성 l DS",
			en: "SAMSUNG l DS",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/677513409"
	},
	{
		id: "KAKAOBANK_677515140",
		name: {
			ko: "카카오뱅크 l 주택담보대출",
			en: "KAKAOBANK l 주택담보대출",
			jp: "",
			cn: ""
		},
		company: {
			ko: "카카오뱅크 l 주택담보대출",
			en: "KAKAOBANK l 주택담보대출",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/677515140"
	},
	{
		id: "SKECHERS_676160762",
		name: {
			ko: "스케쳐스 l 박서준편",
			en: "SKECHERS l Park Seo Joon episode",
			jp: "",
			cn: ""
		},
		company: {
			ko: "스케쳐스 l 박서준편",
			en: "SKECHERS l Park Seo Joon episode",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/676160762"
	},
	{
		id: "DIGILOCA_676115105",
		name: {
			ko: "DIGALOCA l 출근편",
			en: "DIGALOCA l Go to work episode",
			jp: "",
			cn: ""
		},
		company: {
			ko: "DIGALOCA l 출근편",
			en: "DIGALOCA l Go to work episode",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/676115105"
	},

	{
		id: "DIGILOCA_676115604",
		name: {
			ko: "DIGALOCA l 자동차보험편",
			en: "DIGALOCA l Motor insurance episode",
			jp: "",
			cn: ""
		},
		company: {
			ko: "DIGALOCA l 자동차보험편",
			en: "DIGALOCA l Motor insurance episode",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/676115604"
	},
	{
		id: "Samsung_676119316",
		name: {
			ko: "삼성 l CES2022 Message Film",
			en: "SAMSUNG l CES2022 Message Film",
			jp: "",
			cn: ""
		},
		company: {
			ko: "삼성 l CES2022 Message Film",
			en: "SAMSUNG l CES2022 Message Film",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/676119316"
	},
	{
		id: "Samsung_676128174",
		name: {
			ko: "삼성 l CES2022 Opening Film",
			en: "SAMSUNG l CES2022 Opening Film",
			jp: "",
			cn: ""
		},
		company: {
			ko: "삼성 l CES2022 Opening Film",
			en: "SAMSUNG l CES2022 Opening Film",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/676128174"
	},
	{
		id: "Samsung_676130075",
		name: {
			ko: "삼성 l OneUI4 Unpack",
			en: "SAMSUNG l OneUI4 Unpack",
			jp: "",
			cn: ""
		},
		company: {
			ko: "삼성 l OneUI4 Unpack",
			en: "SAMSUNG l OneUI4 Unpack",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/676130075"
	},
	{
		id: "Opening Title",
		name: {
			ko: "Opening Title l 지금 우리 학교는",
			en: "Opening Title l 지금 우리 학교는",
			jp: "",
			cn: ""
		},
		company: {
			ko: "Opening Title l 지금 우리 학교는",
			en: "Opening Title l 지금 우리 학교는",
			jp: "",
			cn: ""
		},
		type: "OPENING TITLE",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/674310746"
	},
	{
		id: "고요의 바다",
		name: {
			ko: "Opening Title | 고요의 바다",
			en: "Opening Title | The Silent sea",
			jp: "",
			cn: ""
		},
		company: {
			ko: "Opening Title | 고요의 바다",
			en: "Opening Title | The Silent sea",
			jp: "",
			cn: ""
		},
		type: "OPENING TITLE",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/661081833",
		detailImg: Ocean
	},
	{
		id: "공작도시",
		name: {
			ko: "Opening Title | 공작도시",
			en: "Opening Title | Sabotage City",
			jp: "",
			cn: ""
		},
		company: {
			ko: "Opening Title | 공작도시",
			en: "Opening Title | Sabotage City",
			jp: "",
			cn: ""
		},
		type: "OPENING TITLE",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/655256972"
	},
	{
		id: "DIGILOCA",
		name: {
			ko: "DIGILOCA | 티저",
			en: "DIGILOCA | Teaser",
			jp: "",
			cn: ""
		},
		company: {
			ko: "DIGILOCA | 티저",
			en: "DIGILOCA | Teaser",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/655258427"
	},
	{
		id: "코나카드 ",
		name: {
			ko: "코나 럭키카드 | 코나캐시떡상카드편",
			en: "Kona Lucky Card",
			jp: "",
			cn: ""
		},
		company: {
			ko: "코나 럭키카드 | 코나캐시떡상카드편",
			en: "Kona Lucky Card",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/653595553"
	},
	
	{
		id: "로카머니",
		name: {
			ko: "로카머니 | LOCAMONEY",
			en: "로카머니 | LOCAMONEY",
			jp: "",
			cn: ""
		},
		company: {
			ko: "로카머니 | LOCAMONEY",
			en: "로카머니 | LOCAMONEY",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/653600207"
	},
	{
		id: "삼성 로고플레이 환경의달 편",
		name: {
			ko: "삼성 로고플레이 | 환경의달 편",
			en: "Samsung Logo Play | The month of the environment Compilation",
			jp: "",
			cn: ""
		},
		company: {
			ko: "삼성 로고플레이 | 환경의달 편",
			en: "Samsung Logo Play | The month of the environment Compilation",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/653606982"
	},
	{
		id: "삼성 로고플레이 썸머(보트) 편",
		name: {
			ko: "삼성 로고플레이 | 썸머(보트) 편",
			en: "Samsung Logo Play | Summer(Boat) Compilation",
			jp: "",
			cn: ""
		},
		company: {
			ko: "삼성 로고플레이 | 썸머(보트) 편",
			en: "Samsung Logo Play | Summer(Boat) Compilation",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/653608292"
	},
	{
		id: "삼성 로고플레이 썸머(파라솔) 편",
		name: {
			ko: "삼성 로고플레이 | 썸머(파라솔) 편",
			en: "Samsung Logo Play | Summer(Parasol) Compilation",
			jp: "",
			cn: ""
		},
		company: {
			ko: "삼성 로고플레이 | 썸머(파라솔) 편",
			en: "Samsung Logo Play | Summer(Parasol) Compilation",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/653608706"
	},
	{
		id: "삼성 로고플레이 썸머(플라밍고) 편",
		name: {
			ko: "삼성 로고플레이 | 썸머(플라밍고) 편",
			en: "Samsung Logo Play | Summer(Flamingo) Compilation",
			jp: "",
			cn: ""
		},
		company: {
			ko: "삼성 로고플레이 | 썸머(플라밍고) 편",
			en: "Samsung Logo Play | Summer(Flamingo) Compilation",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/653609058"
	},
	{
		id: "삼성 로고플레이 국제 손씻기의 날편",
		name: {
			ko: "삼성 로고플레이 | 국제 손씻기의 날편",
			en: "Samsung Logo Play ",
			jp: "",
			cn: ""
		},
		company: {
			ko: "삼성 로고플레이 | 국제 손씻기의 날편",
			en: "Samsung Logo Play ",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/653609346"
	},

	{
		id: "삼성 로고플레이 기념편",
		name: {
			ko: "삼성 로고플레이 | 52주년 기념편",
			en: "Samsung Logo Play | 52nd anniversary. Commemoration Compilation",
			jp: "",
			cn: ""
		},
		company: {
			ko: "삼성 로고플레이 | 52주년 기념편",
			en: "Samsung Logo Play | 52nd anniversary. Commemoration Compilation",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/653609581"
	},
	{
		id: "현대카드IRON",
		name: {
			ko: "현대카드 | IRON & WOOD",
			en: "Hyundai Card | IRON & WOOD",
			jp: "",
			cn: ""
		},
		company: {
			ko: "현대카드 | IRON & WOOD",
			en: "Hyundai Card | IRON & WOOD",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/575702754"
	},
	{
		id: "신한라이프",
		name: {
			ko: "신한라이프 | Brand ID",
			en: "Shinhan Life. | Brand ID",
			jp: "",
			cn: ""
		},
		company: {
			ko: "신한라이프 | Brand ID",
			en: "Shinhan Life. | Brand ID",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/575703806"
	},
	{
		id: "제주맥주",
		name: {
			ko: "제주맥주 | DIVE INTO DIVERSITY",
			en: "Jeju Beer | DIVE INTO DIVERSITY",
			jp: "",
			cn: ""
		},
		company: {
			ko: "제주맥주 | DIVE INTO DIVERSITY",
			en: "Jeju Beer | DIVE INTO DIVERSITY",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/585705906"
	},
	{
		id: "바디프렌드02",
		name: {
			ko: "바디프렌드 | 페이커",
			en: "Samsung | Galaxy Watch BIA",
			jp: "",
			cn: ""
		},
		company: {
			ko: "바디프렌드 | 페이커",
			en: "Samsung | Galaxy Watch BIA",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/625408651"
	},
	{
		id: "엘지",
		name: {
			ko: "엘지 Oled | ECO MINDED",
			en: "LG Oled | ECO MINDED",
			jp: "",
			cn: ""
		},
		company: {
			ko: "엘지 Oled | ECO MINDED",
			en: "LG Oled | ECO MINDED",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/625375486"
	},
	{
		id: "엘지01",
		name: {
			ko: "엘지 Oled | EYE COMFORT",
			en: "LG Oled | EYE COMFORT",
			jp: "",
			cn: ""
		},
		company: {
			ko: "엘지 Oled | EYE COMFORT",
			en: "LG Oled | EYE COMFORT",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/625375544"
	},
	
	{
		id: "정관장",
		name: {
			ko: "정관장 | Everytime Global",
			en: "Cheong-Kwan-Jang | Everytime Global",
			jp: "",
			cn: ""
		},
		company: {
			ko: "정관장 | Everytime Global",
			en: "Cheong-Kwan-Jang | Everytime Global",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/624416801"
	},
	{
		id: "CJ_ON",
		name: {
			ko: "CJ온스타일 | 상품전문가 편",
			en: "CJ OnStyle  | Product expert episode.",
			jp: "",
			cn: ""
		},
		company: {
			ko: "CJ온스타일 | 상품전문가 편",
			en: "CJ OnStyle  | Product expert episode.",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/624411230"
	},
	{
		id: "CJ_ON-LIVE",
		name: {
			ko: "CJ온스타일 | 라이브 편",
			en: "CJ OnStyle  | Live episode.",
			jp: "",
			cn: ""
		},
		company: {
			ko: "CJ온스타일 | 라이브 편",
			en: "CJ OnStyle  | Live episode.",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/624411182"
	},
	{
		id: "oera",
		name: {
			ko: "오에라 | 브랜드 스토리",
			en: "Oera | Brand Story",
			jp: "",
			cn: ""
		},
		company: {
			ko: "오에라 | 브랜드 스토리",
			en: "Oera | Brand Story",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/624408838"
	},
	{
		id: "CM",
		name: {
			ko: "commercial",
			en: "commercial",
			jp: "",
			cn: ""
		},
		company: {
			ko: "에블린 마스크 | 스타일링 컬러 마스크",
			en: "Evelyn Mask | Styling color mask",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/591373850"
	},
	{
		id: "머스트잇",
		name: {
			ko: "머스트잇",
			en: "Must it",
			jp: "",
			cn: ""
		},
		company: {
			ko: "머스트잇",
			en: "Must it",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/591366301"
	},

	// 10.28 Update 부분
	{
		id: "alien",
		name: {
			ko: "ALIEN",
			en: "ALIEN",
			jp: "",
			cn: ""
		},
		company: {
			ko: "LEE SUHYUN - ‘ALIEN’",
			en: "LEE SUHYUN - ‘ALIEN’",
			jp: "",
			cn: ""
		},
		type: "MV",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/472901907"
	},
	{
		id: "vimeo03",
		name: {
			ko: "동행세일 | 2021 대한민국 동행세일",
			en: "Accompanying Sale | 2021 Korea accompanying sale",
			jp: "",
			cn: ""
		},
		company: {
			ko: "동행세일 | 2021 대한민국 동행세일",
			en: "Accompanying Sale | 2021 Korea accompanying sale",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/575703072"
	},
	{
		id: "vimeo02",
		name: {
			ko: "Naver | 네이버 쇼핑라이브 1주년",
			en: "Naver | Shopping Live 1st Anniversary",
			jp: "",
			cn: ""
		},
		company: {
			ko: "Naver | 네이버 쇼핑라이브 1주년",
			en: "Naver | Shopping Live 1st Anniversary",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/575706724"
	},
	{
		id: "현대카드",
		name: {
			ko: "현대카드 | the Pink",
			en: "Hyundai Card | the Pink",
			jp: "",
			cn: ""
		},
		company: {
			ko: "현대카드 | the Pink",
			en: "Hyundai Card | the Pink",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/575703734"
	},
	{
		id: "vimeo01",
		name: {
			ko: "현대카드 | the Pink",
			en: "Hyundai Card | the Pink",
			jp: "",
			cn: ""
		},
		company: {
			ko: "현대카드 | the Pink",
			en: "Hyundai Card | the Pink",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/575703760"
	},
	{
		id: "Dragon_Roi",
		name: {
			ko: "Dragon_Roi",
			en: "Dragon_Roi",
			jp: "",
			cn: ""
		},
		company: {
			ko: "Dragon_Roi",
			en: "Dragon_Roi",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/538567904"
	},

	{
		id: "ROCA_Money",
		name: {
			ko: "ROCA_Money",
			en: "ROCA_Money",
			jp: "",
			cn: ""
		},
		company: {
			ko: "ROCA_Money",
			en: "ROCA_Money",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/538507405"
	},

	{
		id: "BESPOKE_directer",
		name: {
			ko: "BESPOKE_directer",
			en: "BESPOKE_directer",
			jp: "",
			cn: ""
		},
		company: {
			ko: "BESPOKE_directer",
			en: "BESPOKE_directer",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/527082917"
	},

	{
		id: "Icarus_Teaser",
		name: {
			ko: "ICARUS | Teaser",
			en: "ICARUS | Teaser",
			jp: "",
			cn: ""
		},
		company: {
			ko: "ICARUS | Teaser",
			en: "ICARUS | Teaser",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/515568312"
	},

	{
		id: "Hyundaicard_Socar_Looc_ver",
		name: {
			ko: "현대카드 | 쏘카 _ LOOC.ver",
			en: "Hyundaicard_Socar_Looc_ver",
			jp: "",
			cn: ""
		},
		company: {
			ko: "현대카드 | 쏘카 _ LOOC.ver",
			en: "Hyundaicard_Socar_Looc_ver",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/510526186"
	},

	{
		id: "kakao_emoticon",
		name: {
			ko: "카카오 | 이모티콘 플러스",
			en: "Kakao | Emoticon Plus",
			jp: "",
			cn: ""
		},
		company: {
			ko: "카카오 | 이모티콘 플러스",
			en: "Kakao | Emoticon Plus",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/506923070"
	},

	{
		id: "kia_mohave",
		name: {
			ko: "Kia Mohave | The matser | Dreams are young",
			en: "Kia Mohave | The matser | Dreams are young",
			jp: "",
			cn: ""
		},
		company: {
			ko: "Kia Mohave | The matser | Dreams are young",
			en: "Kia Mohave | The matser | Dreams are young",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/506921408"
	},

	{
		id: "samsung_CES",
		name: {
			ko: "Samsung | CES 2021 Title",
			en: "Samsung | CES 2021 Title",
			jp: "",
			cn: ""
		},
		company: {
			ko: "Samsung | CES 2021 Title",
			en: "Samsung | CES 2021 Title",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/499461535"
	},

	{
		id: "lg_logo",
		name: {
			ko: "LG Logo Animation 15s",
			en: "LG Logo Animation 15s",
			jp: "",
			cn: ""
		},
		company: {
			ko: "LG Logo Animation 15s",
			en: "LG Logo Animation 15s",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/499464405"
	},
	{
		id: "mx_boost",
		name: {
			ko: "현대카드 | MX Boost 30s",
			en: "Hyundai Card | MX Boost 30s",
			jp: "",
			cn: ""
		},
		company: {
			ko: "현대카드 | MX Boost 30s",
			en: "Hyundai Card | MX Boost 30s",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/499461558"
	},
	{
		id: "hyundai_rima",
		name: {
			ko: "현대카드 | 현대카드 RIMA",
			en: "Hyundai Card | Hyundai Card RIMA",
			jp: "",
			cn: ""
		},
		company: {
			ko: "현대카드 | 현대카드 RIMA",
			en: "Hyundai Card | Hyundai Card RIMA",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/485340394"
	},
	{
		id: "melon_mma",
		name: {
			ko: "Melon | MMA",
			en: "Melon | MMA",
			jp: "",
			cn: ""
		},
		company: {
			ko: "Melon | MMA",
			en: "Melon | MMA",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/485299555"
	},
	{
		id: "mc365",
		name: {
			ko: "365mc | Brand Film Dir ver.",
			en: "365mc | Brand Film Dir ver.",
			jp: "",
			cn: ""
		},
		company: {
			ko: "365mc | Brand Film Dir ver.",
			en: "365mc | Brand Film Dir ver.",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/485298725"
	},
	{
		id: "sulwhasoo",
		name: {
			ko: "설화수 | Sulwhasoo",
			en: "Sulwhasoo",
			jp: "",
			cn: ""
		},
		company: {
			ko: "설화수 | Sulwhasoo",
			en: "Sulwhasoo",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/484421372"
	},
	{
		id: "yongfilm",
		name: {
			ko: "Yongfilm | 리더필름 ‘Yongfilm’",
			en: "Yongfilm | 리더필름 ‘Yongfilm’",
			jp: "",
			cn: ""
		},
		company: {
			ko: "Yongfilm | 리더필름 ‘Yongfilm’",
			en: "Yongfilm | 리더필름 ‘Yongfilm’",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/484420918"
	},
	
	{
		id: "primera",
		name: {
			ko: "프리메라 | 알파인 베리 워터리 크림",
			en: "Primera | Alpine Berry Watery Cream",
			jp: "",
			cn: ""
		},
		company: {
			ko: "프리메라 | 알파인 베리 워터리 크림",
			en: "Primera | Alpine Berry Watery Cream",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/472902036"
	},
	{
		id: "neulteq",
		name: {
			ko: "신한카드 늘테크",
			en: "Shinnan Card Neulteq",
			jp: "",
			cn: ""
		},
		company: {
			ko: "신한카드 늘테크",
			en: "Shinnan Card Neulteq",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/472902017"
	},
	{
		id: "drumjunghwa",
		name: {
			ko: "닥터디퍼런트 lm Differen_엄정화",
			en: "Dr.Differen lm Differen_umjunghwa",
			jp: "",
			cn: ""
		},
		company: {
			ko: "닥터디퍼런트 lm Differen_엄정화",
			en: "Dr.Differen lm Differen_umjunghwa",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/472902001"
	},
	{
		id: "drteaser",
		name: {
			ko: "닥터디퍼런트 lm Different_Teaser",
			en: "Dr.Different lm Different_Teaser",
			jp: "",
			cn: ""
		},
		company: {
			ko: "닥터디퍼런트 lm Different_Teaser",
			en: "Dr.Different lm Different_Teaser",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/472901982"
	},
	{
		id: "naverRetail",
		name: {
			ko: "네이버 시리즈 Ratil_TVC_30s",
			en: "Naver series Ratil_TVC_30s",
			jp: "",
			cn: ""
		},
		company: {
			ko: "네이버 시리즈 Ratil_TVC_30s",
			en: "Naver series Ratil_TVC_30s",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/472901975"
	},
	{
		id: "naverRanamoonTvc",
		name: {
			ko: "네이버 시리즈 Ranamoon_TVC_30s",
			en: "Naver series Ranamoon_TVC_30s",
			jp: "",
			cn: ""
		},
		company: {
			ko: "네이버 시리즈 Ranamoon_TVC_30s",
			en: "Naver series Ranamoon_TVC_30s",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/472901937"
	},
	{
		id: "naverRanamoonDigital",
		name: {
			ko: "네이버 시리즈 Ranamoon_Digital",
			en: "Naver series Ranamoon_Digital",
			jp: "",
			cn: ""
		},
		company: {
			ko: "네이버 시리즈 Ranamoon_Digital",
			en: "Naver series Ranamoon_Digital",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/472901917"
	},
	{
		id: "JMsolutionGlow",
		name: {
			ko: "JMsolution | 글로우 필 스파크림",
			en: "JMsolution | Glow fill SPA Cream",
			jp: "",
			cn: ""
		},
		company: {
			ko: "JMsolution | 글로우 필 스파크림",
			en: "JMsolution | Glow fill SPA Cream",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/472901878"
	},
	{
		id: "colormatching",
		name: {
			ko: "갤럭시 노트20 컬러 매칭 콜라보레이션",
			en: "GLAXY NOTE20 Color matching collaboration",
			jp: "",
			cn: ""
		},
		company: {
			ko: "갤럭시 노트20 컬러 매칭 콜라보레이션",
			en: "GLAXY NOTE20 Color matching collaboration",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/472901856"
	},
	{
		id: "SamsungInduction",
		name: {
			ko: "Samsung All induction_Digital",
			en: "Samsung All induction_Digital",
			jp: "",
			cn: ""
		},
		company: {
			ko: "Samsung All induction_Digital",
			en: "Samsung All induction_Digital",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/472901855"
	},
	{
		id: "maumhangul",
		name: {
			ko: "갤럭시 노트20 마음한줄",
			en: "GLAXY NOTE20 A line of heart",
			jp: "",
			cn: ""
		},
		company: {
			ko: "갤럭시 노트20 마음한줄",
			en: "GLAXY NOTE20 A line of heart",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/472901825"
	},
	{
		id: "skmagic",
		name: {
			ko: "SK매직 스스로 직수정수기",
			en: "SK Magic water purifier",
			jp: "",
			cn: ""
		},
		company: {
			ko: "SK매직 스스로 직수정수기",
			en: "SK Magic water purifier",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/472901821"
	},
	{
		id: "iopeexpert",
		name: {
			ko: "IOPE 레티놀 엑스퍼트 01%",
			en: "IOPE Retinol Expert 01%",
			jp: "",
			cn: ""
		},
		company: {
			ko: "IOPE 레티놀 엑스퍼트 01%",
			en: "IOPE Retinol Expert 01%",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/472901797"
	},
	{
		id: "cliokill",
		name: {
			ko: "클리오 킬커버 픽서 쿠션",
			en: "CLIO | Kill Cover Fixer Cushion",
			jp: "",
			cn: ""
		},
		company: {
			ko: "클리오 킬커버 픽서 쿠션",
			en: "CLIO | Kill Cover Fixer Cushion",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/472901780"
	},
	{
		id: "ktskylife",
		name: {
			ko: "KT 스카이라이프",
			en: "KT Skylife",
			jp: "",
			cn: ""
		},
		company: {
			ko: "KT 스카이라이프",
			en: "KT Skylife",
			jp: "",
			cn: ""
		},
		type: "MOTION",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/472901762"
	},
	{
		id: "bibigoPremier",
		name: {
			ko: "비비고 프리미엄죽 | 죽이 맞다",
			en: "BIBIGO Premium",
			jp: "",
			cn: ""
		},
		company: {
			ko: "비비고 프리미엄죽 | 죽이 맞다",
			en: "BIBIGO Premium",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/472901749"
	},
	{
		id: "bibigoBulnak",
		name: {
			ko: "비비고 프리미엄죽 | 불낙죽편",
			en: "BIBIGO | 불낙죽 episode’",
			jp: "",
			cn: ""
		},
		company: {
			ko: "비비고 프리미엄죽 | 불낙죽편",
			en: "BIBIGO | 불낙죽 episode’",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/472901748"
	},
	// 08.26 Update 부분
	{
		id: "double_decker",
		name: {
			ko: "쌍갑포차 오프닝 타이틀",
			en: "A Double Decker Opening title",
			jp: "",
			cn: ""
		},
		company: {
			ko: "쌍갑포차 오프닝 타이틀",
			en: "A Double Decker Opening title",
			jp: "",
			cn: ""
		},
		type: "OPENING TITLE",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/451750444"
	},
	{
		id: "My Country",
		name: {
			ko: "나의 나라",
			en: "My Country",
			jp: "",
			cn: ""
		},
		company: {
			ko: "나의 나라",
			en: "My Country",
			jp: "",
			cn: ""
		},
		type: "OPENING TITLE",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/451757003"
	},
	// 20.08.03 Update 부분
	{
		id: "식빵 언니",
		name: {
			ko: "식빵 언니 #김연경 선수 아이돌 데뷔 링티",
			en: "Bread sister #Kim Yeon-kyung's idol debut ringtee",
			jp: "",
			cn: ""
		},
		company: {
			ko: "식빵 언니 #김연경 선수 아이돌 데뷔 링티",
			en: "Bread sister #Kim Yeon-kyung's idol debut ringtee",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/444152205"
	},
	{
		id: "클라우드 생 드래프트",
		name: {
			ko: "클라우드 생 드래프트(feat.박서준)",
			en: "Cloud draft (feat.park seo joon)",
			jp: "",
			cn: ""
		},
		company: {
			ko: "클라우드 생 드래프트(feat.박서준)",
			en: "Cloud draft (feat.park seo joon)",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/444152220"
	},
	{
		id: "bibigo",
		name: {
			ko: "비비고 | 프리미엄 국물요리",
			en: "Bibigo | Premium Soup",
			jp: "",
			cn: ""
		},
		company: {
			ko: "비비고 | 프리미엄 국물요리",
			en: "Bibigo | Premium Soup",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/444151926"
	},
	{
		id: "dokgo",
		name: {
			ko: "배달의 민족 | 독고배달이",
			en: "BAEMIN | Dokgo",
			jp: "",
			cn: ""
		},
		company: {
			ko: "배달의 민족 | 독고배달이",
			en: "BAEMIN | Dokgo",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/444152045"
	},
	{
		id: "Nyang",
		name: {
			ko: "배달의민족 | 냥이배달이",
			en: "BAEMIN | Nyang",
			jp: "",
			cn: ""
		},
		company: {
			ko: "배달의민족 | 냥이배달이",
			en: "BAEMIN | Nyang",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/444152019"
	},
	{
		id: "my long",
		name: {
			ko: "배달의민족 | 마이배달이",
			en: "BAEMIN | My Baedal",
			jp: "",
			cn: ""
		},
		company: {
			ko: "배달의민족 | 마이배달이",
			en: "BAEMIN | My Baedal",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/444151969"
	},
	{
		id: "Do you know Samsumg membership02",
		name: {
			ko: "삼성전자 멤버쉽 | Do you know 2",
			en: "Do you khow Samsung Electronics Membership #2",
			jp: "",
			cn: ""
		},
		company: {
			ko: "삼성전자 멤버쉽 | Do you know 2",
			en: "Do you khow Samsung Electronics Membership #2",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/444127648"
	},
	{
		id: "Do you know Samsumg membership01",
		name: {
			ko: "삼성전자 멤버쉽 | Do you know 1",
			en: "Do you khow Samsung Electronics Membership #1",
			jp: "",
			cn: ""
		},
		company: {
			ko: "삼성전자 멤버쉽 | Do you know 1",
			en: "Do you khow Samsung Electronics Membership #1",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/444125083"
	},

	{
		id: "kb teaser",
		name: {
			ko: "2020 KB | 티저",
			en: "2020 KB | teaser",
			jp: "",
			cn: ""
		},
		company: {
			ko: "2020 KB | 티저",
			en: "2020 KB | teaser",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/444152058"
	},
	{
		id: "30 second",
		name: {
			ko: "HyundaiCard | People",
			en: "HyundaiCard | People",
			jp: "",
			cn: ""
		},
		company: {
			ko: "현대카드 | People",
			en: "HyundaiCard | People",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/444151945"
	},
	{
		id: "together",
		name: {
			ko: "Together",
			en: "Together",
			jp: "",
			cn: ""
		},
		company: {
			ko: "대한민국 동행세일",
			en: "Together",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/444152190"
	},
	{
		id: "sleep",
		name: {
			ko: "Samsung Health | Sleep",
			en: "Samsung Health | Sleep",
			jp: "",
			cn: ""
		},
		company: {
			ko: "삼성 Health | Sleep",
			en: "Samsung Health | Sleep",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/444152161"
	},
	{
		id: "meditation",
		name: {
			ko: "Samsung Health | Meditation",
			en: "Samsung Health | Meditation",
			jp: "",
			cn: ""
		},
		company: {
			ko: "삼성 Health | Meditation",
			en: "Samsung Health | Meditation",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/444152150"
	},
	{
		id: "hometraining",
		name: {
			ko: "Samsung Health | Hometraining",
			en: "Samsung Health | Hometraining",
			jp: "",
			cn: ""
		},
		company: {
			ko: "삼성 Health | Hometraining",
			en: "Samsung Health | Hometraining",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/444152136"
	},
	{
		id: "bixby",
		name: {
			ko: "Bixby",
			en: "Bixby",
			jp: "",
			cn: ""
		},
		company: {
			ko: "빅스비 | bixby",
			en: "Bixby",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/444152116"
	},
	{
		id: "Take off 30",
		name: {
			ko: "Hyundai Card + Korean Air | Take off",
			en: "Hyundai Card + Korean Air | Take off",
			jp: "",
			cn: ""
		},
		company: {
			ko: "현대카드 + 대한항공 | Take off",
			en: "Hyundai Card + Korean Air | Take off",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/444152174"
	},


	//////// 기존

	{
		id: "15초 단편버전_A",
		name: {
			ko: "15-second short versionA",
			en: "15-second short versionA",
			jp: "",
			cn: ""
		},
		company: {
			ko: "15초 단편버전A",
			en: "15-second short versionA",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/427261694"
	},
	{
		id: "2018_Kingdom_Title",
		name: {
			ko: "2018 Kingdom Title",
			en: "2018 Kingdom Title",
			jp: "",
			cn: ""
		},
		company: {
			ko: "2018 Kingdom Title",
			en: "2018 Kingdom Title",
			jp: "",
			cn: ""
		},
		type: "OPENING TITLE",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/427261878"
	},
	{
		id: "2019_LYN_TVC_direct_Full",
		name: {
			ko: "2019 LYN TVC direct Full",
			en: "2019 LYN TVC direct Full",
			jp: "",
			cn: ""
		},
		company: {
			ko: "2019 LYN TVC direct Full",
			en: "2019 LYN TVC direct Full",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/427271870"
	},
	{
		id: "2019_shinsegae_45A",
		name: {
			ko: "2019 shinsegae 45A",
			en: "2019 shinsegae 45A",
			jp: "",
			cn: ""
		},
		company: {
			ko: "2019 shinsegae 45A",
			en: "2019 shinsegae 45A",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/427273260"
	},
	{
		id: "2019_라이나_요리매연",
		name: {
			ko: "2019 lina cooking Smoke",
			en: "2019 lina cooking Smoke",
			jp: "",
			cn: ""
		},
		company: {
			ko: "2019 라이나 요리매연",
			en: "2019 lina cooking Smoke",
			jp: "",
			cn: ""
		},
		type: "MOTION",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/427273729"
	},
	{
		id: "anssil_Teaser_IFdesign",
		name: {
			ko: "anssil Teaser IFdesign",
			en: "anssil Teaser IFdesign",
			jp: "",
			cn: ""
		},
		company: {
			ko: "anssil Teaser IFdesign",
			en: "anssil Teaser IFdesign",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/427274640"
	},
	{
		id: "oilbank_2460_936",
		name: {
			ko: "oilbank 2460 936",
			en: "oilbank 2460 936",
			jp: "",
			cn: ""
		},
		company: {
			ko: "oilbank 2460 936",
			en: "oilbank 2460 936",
			jp: "",
			cn: ""
		},
		type: "MOTION",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/427275163"
	},
	{
		id: "CLIO 프리즘 에어 아이 팔레트",
		name: {
			ko: "CLIO Prism Air Eye Palette",
			en: "CLIO Prism Air Eye Palette",
			jp: "",
			cn: ""
		},
		company: {
			ko: "CLIO 프리즘 에어 아이 팔레트",
			en: "CLIO Prism Air Eye Palette",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/427275283"
	},
	{
		id: "2020_JM_Solution_30A",
		name: {
			ko: "2020 JM Solution 30A",
			en: "2020 JM Solution 30A",
			jp: "",
			cn: ""
		},
		company: {
			ko: "2020 JM Solution 30A",
			en: "2020 JM Solution 30A",
			jp: "",
			cn: ""
		},
		type: "MOTION",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/427275457"
	},
	{
		id: "2020_Samsung_Grande",
		name: {
			ko: "2020 Samsung Grande",
			en: "2020 Samsung Grande",
			jp: "",
			cn: ""
		},
		company: {
			ko: "2020 Samsung Grande",
			en: "2020 Samsung Grande",
			jp: "",
			cn: ""
		},
		type: "MOTION",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/427275829"
	},
	{
		id: "2020_JTBC_TV 방송용 A_가치편",
		name: {
			ko: "2020 JTBC TV Broadcasting A Value Edition",
			en: "2020 JTBC TV Broadcasting A Value Edition",
			jp: "",
			cn: ""
		},
		company: {
			ko: "2020 JTBC TV 방송용 A 가치편",
			en: "2020 JTBC TV Broadcasting A Value Edition",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/427277692"
	},
	{
		id: "2020_JTBC_TV 방송용 B_정신편",
		name: {
			ko: "2020 JTBC TV Broadcast B Mental",
			en: "2020 JTBC TV Broadcast B Mental",
			jp: "",
			cn: ""
		},
		company: {
			ko: "2020 JTBC TV 방송용 B 정신편",
			en: "2020 JTBC TV Broadcast B Mental",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/427278421"
	},
	{
		id: "2020_JTBC_TV 방송용 C_자세편",
		name: {
			ko: "2020 JBC TV Broadcasting C Posture",
			en: "2020 JBC TV Broadcasting C Posture",
			jp: "",
			cn: ""
		},
		company: {
			ko: "2020 JTBC TV 방송용 C 자세편",
			en: "2020 JBC TV Broadcasting C Posture",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/427279361"
	},
	{
		id: "Samsungcard_All",
		name: {
			ko: "Samsungcard All",
			en: "Samsungcard All",
			jp: "",
			cn: ""
		},
		company: {
			ko: "Samsungcard All",
			en: "Samsungcard All",
			jp: "",
			cn: ""
		},
		type: "MOTION",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/427280045"
	},
	{
		id: "Domino_Pizza",
		name: {
			ko: "Domino Pizza",
			en: "Domino Pizza",
			jp: "",
			cn: ""
		},
		company: {
			ko: "Domino Pizza",
			en: "Domino Pizza",
			jp: "",
			cn: ""
		},
		type: "MOTION",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/427281792"
	},
	{
		id: "2020_Galaxy_A_Quantum",
		name: {
			ko: "2020 Galaxy A Quantum",
			en: "2020 Galaxy A Quantum",
			jp: "",
			cn: ""
		},
		company: {
			ko: "2020 Galaxy A Quantum",
			en: "2020 Galaxy A Quantum",
			jp: "",
			cn: ""
		},
		type: "MOTION",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/427282235"
	},
	{
		id: "2020_Galaxy_A_Quantum_2",
		name: {
			ko: "2020 Galaxy A Quantum 2",
			en: "2020 Galaxy A Quantum 2",
			jp: "",
			cn: ""
		},
		company: {
			ko: "2020 Galaxy A Quantum 2",
			en: "2020 Galaxy A Quantum 2",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/427282160"
	},
	{
		id: "GD_untitle",
		name: {
			ko: "GD Untitle",
			en: "GD Untitle",
			jp: "",
			cn: ""
		},
		company: {
			ko: "GD 무제",
			en: "GD Untitle",
			jp: "",
			cn: ""
		},
		type: "MV",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/428444098"
	},
	{
		id: "LEE_HI_NO-ONE",
		name: {
			ko: "LEE HI-NO ONE",
			en: "LEE HI-NO ONE",
			jp: "",
			cn: ""
		},
		company: {
			ko: "LEE HI-NO ONE",
			en: "LEE HI-NO ONE",
			jp: "",
			cn: ""
		},
		type: "MV",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/428444202"
	},
	{
		id: "你好吗我很好谢谢你呢",
		name: {
			ko: "你好吗我很好谢谢你呢-Chris Lee",
			en: "你好吗我很好谢谢你呢-Chris Lee",
			jp: "",
			cn: ""
		},
		company: {
			ko: "你好吗我很好谢谢你呢-Chris Lee",
			en: "你好吗我很好谢谢你呢-Chris Lee",
			jp: "",
			cn: ""
		},
		type: "MV",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/428444739"
	},
	{
		id: "流行_TREND",
		name: {
			ko: "流行 TREND",
			en: "流行 TREND",
			jp: "",
			cn: ""
		},
		company: {
			ko: "流行 TREND",
			en: "流行 TREND",
			jp: "",
			cn: ""
		},
		type: "MV",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/428445382"
	},
	{
		id: "Park_Ji_Yoon_Beep",
		name: {
			ko: "Park Ji Yoon-Beep",
			en: "Park Ji Yoon-Beep",
			jp: "",
			cn: ""
		},
		company: {
			ko: "Park Ji Yoon-Beep",
			en: "Park Ji Yoon-Beep",
			jp: "",
			cn: ""
		},
		type: "MV",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/428445830"
	},
	{
		id: "PERFECT",
		name: {
			ko: "PERFECT",
			en: "PERFECT",
			jp: "",
			cn: ""
		},
		company: {
			ko: "PERFECT",
			en: "PERFECT",
			jp: "",
			cn: ""
		},
		type: "MV",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/428446077"
	},
	{
		id: "yoon_jong_sin",
		name: {
			ko: "Yoon Jong Shin - You are still cool",
			en: "Yoon Jong Shin - You are still cool",
			jp: "",
			cn: ""
		},
		company: {
			ko: "윤종신 - 그대 여전히 멋있는 사람",
			en: "Yoon Jong Shin - You are still cool",
			jp: "",
			cn: ""
		},
		type: "MV",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/428446569"
	},
	{
		id: "lexus_taeyang",
		name: {
			ko: "lexus X taeyang - So Good",
			en: "lexus X taeyang - So Good",
			jp: "",
			cn: ""
		},
		company: {
			ko: "렉서스 X 태양 - SO Good",
			en: "lexus X taeyang - So Good",
			jp: "",
			cn: ""
		},
		type: "MV",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/428446667"
	},
	{
		id: "park_ji_yoon_Yoo_hoo",
		name: {
			ko: "Park Ji Yoon - Yoo Hoo",
			en: "Park Ji Yoon - Yoo Hoo",
			jp: "",
			cn: ""
		},
		company: {
			ko: "박지윤 - Yoo Hoo",
			en: "Park Ji Yoon - Yoo Hoo",
			jp: "",
			cn: ""
		},
		type: "MV",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/428446883"
	},
	{
		id: "Priest title",
		name: {
			ko: "Priest Title",
			en: "Priest Title",
			jp: "",
			cn: ""
		},
		company: {
			ko: "Priest Title",
			en: "Priest Title",
			jp: "",
			cn: ""
		},
		type: "OPENING TITLE",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/395442161"
	},
	{
		id: "Onmyoji - New SP Shikigami Demoniac Shuten Doji",
		name: {
			ko: "Onmyoji - New SP Shikigami Demoniac Shuten Doji",
			en: "Onmyoji - New SP Shikigami Demoniac Shuten Doji",
			jp: "",
			cn: ""
		},
		company: {
			ko: "Onmyoji - New SP Shikigami Demoniac Shuten Doji",
			en: "Onmyoji - New SP Shikigami Demoniac Shuten Doji",
			jp: "",
			cn: ""
		},
		type: "MOTION",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/395442101"
	},
	{
		id: "Samsung intro no frame",
		name: {
			ko: "Samsung Intro No Frame",
			en: "Samsung Intro No Frame",
			jp: "",
			cn: ""
		},
		company: {
			ko: "Samsung Intro No Frame",
			en: "Samsung Intro No Frame",
			jp: "",
			cn: ""
		},
		type: "MOTION",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/395442074"
	},
	{
		id: "LiivM Launching 30",
		name: {
			ko: "LiivM Launching 30",
			en: "LiivM Launching 30",
			jp: "",
			cn: ""
		},
		company: {
			ko: "LiivM Launching 30",
			en: "LiivM Launching 30",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/395442007"
	},
	{
		id: "[비맥스 메타정] 정신피로 편",
		name: {
			ko: "[비맥스 메타정] 정신피로 편",
			en: "[비맥스 메타정] 정신피로 편",
			jp: "",
			cn: ""
		},
		company: {
			ko: "[비맥스 메타정] 정신피로 편",
			en: "[비맥스 메타정] 정신피로 편",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/395441950"
	},
	{
		id: "Fomula-E with방탄소년단",
		name: {
			ko: "Fomula-E",
			en: "Fomula-E",
			jp: "",
			cn: ""
		},
		company: {
			ko: "Fomula-E",
			en: "Fomula-E",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/395441914"
	},
	{
		id: "All New Galaxy A",
		name: {
			ko: "All New Galaxy A",
			en: "All New Galaxy A",
			jp: "",
			cn: ""
		},
		company: {
			ko: "All New Galaxy A",
			en: "All New Galaxy A",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/395441891"
	},
	{
		id: "New K5 Teaser",
		name: {
			ko: "New K5 - Teaser",
			en: "New K5 - Teaser",
			jp: "",
			cn: ""
		},
		company: {
			ko: "New K5 - 티져",
			en: "New K5 - Teaser",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/389487146"
	},
	{
		id: "Samsung Galaxy Wallpaper Film",
		name: {
			ko: "Samsung Galaxy Wallpaper film",
			en: "Samsung Galaxy Wallpaper film",
			jp: "",
			cn: ""
		},
		company: {
			ko: "삼성 갤럭시 - Wallpaper film",
			en: "Samsung Galaxy Wallpaper film",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/389487105"
	},
	{
		id: "Www Title",
		name: {
			ko: "WWW - Title",
			en: "WWW - Title",
			jp: "",
			cn: ""
		},
		company: {
			ko: "WWW - 타이틀 ",
			en: "WWW - Title ",
			jp: "",
			cn: ""
		},
		type: "OPENING TITLE",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/389487074"
	},
	{
		id: "Samsung Block Chain",
		name: {
			ko: "Samsung Blockchain",
			en: "Samsung Blockchain",
			jp: "",
			cn: ""
		},
		company: {
			ko: "삼성 블록체인",
			en: "Samsung Blockchain",
			jp: "",
			cn: ""
		},
		type: "MOTION",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/389486994"
	},
	{
		id: "Sk Holdings Jjack",
		name: {
			ko: "SK Holdings - JJAK",
			en: "SK Holdings - JJAK",
			jp: "",
			cn: ""
		},
		company: {
			ko: "SK Holdings - JJAK",
			en: "SK Holdings - JJAK",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/389486969"
	},
	{
		id: "Sk Holdings Up",
		name: {
			ko: "SK Holdings - UP",
			en: "SK Holdings - UP",
			jp: "",
			cn: ""
		},
		company: {
			ko: "SK Holdings - UP",
			en: "SK Holdings - UP",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/389486947"
	},
	{
		id: "Naver Series Prenup",
		name: {
			ko: "Naver Series - Prenup",
			en: "Naver Series - Prenup",
			jp: "",
			cn: ""
		},
		company: {
			ko: "Naver Series - Prenup",
			en: "Naver Series - Prenup",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/389486917"
	},
	{
		id: "Naver Series Goldenhour",
		name: {
			ko: "Naver Series - Goldenhour",
			en: "Naver Series - Goldenhour",
			jp: "",
			cn: ""
		},
		company: {
			ko: "Naver Series - Goldenhour",
			en: "Naver Series - Goldenhour",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/389486890"
	},

	{
		id: "Lotte Chemical",
		name: {
			ko: "Lotte Chemical",
			en: "Lotte Chemical",
			jp: "",
			cn: ""
		},
		company: {
			ko: "롯데 케미칼",
			en: "Lotte Chemical",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/389486822"
	},
	{
		id: "Hyundai Card Dive",
		name: {
			ko: "Hyundaicard DIVE",
			en: "Hyundaicard DIVE",
			jp: "",
			cn: ""
		},
		company: {
			ko: "Hyundaicard DIVE",
			en: "Hyundaicard DIVE",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/389486799"
	},
	{
		id: "Chapi",
		name: {
			ko: "Chapi",
			en: "Chapi",
			jp: "",
			cn: ""
		},
		company: {
			ko: "Chapi",
			en: "Chapi",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/389486757"
	},
	{
		id: "Soofee",
		name: {
			ko: "Soofe'e",
			en: "Soofe'e",
			jp: "",
			cn: ""
		},
		company: {
			ko: "Soofe'e",
			en: "Soofe'e",
			jp: "",
			cn: ""
		},
		type: "MOTION",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/389486731"
	},
	{
		id: "Lineage 2m Bi",
		name: {
			ko: "Lineage2 M BI",
			en: "Lineage2 M BI",
			jp: "",
			cn: ""
		},
		company: {
			ko: "Lineage2 M BI",
			en: "Lineage2 M BI",
			jp: "",
			cn: ""
		},
		type: "MOTION",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/389486706"
	},
	{
		id: "Blade And Soul",
		name: {
			ko: "Blade & Soul",
			en: "Blade & Soul",
			jp: "",
			cn: ""
		},
		company: {
			ko: "Blade & Soul",
			en: "Blade & Soul",
			jp: "",
			cn: ""
		},
		type: "COMMERCIAL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/389486676"
	},
	{
		id: "Beauty Inside Ending Title",
		name: {
			ko: "Beauty Inside Ending Title",
			en: "Beauty Inside Ending Title",
			jp: "",
			cn: ""
		},
		company: {
			ko: "뷰티인사이드 - 엔딩 타이틀",
			en: "Beauty Inside Ending Title",
			jp: "",
			cn: ""
		},
		type: "OPENING TITLE",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/389486653"
	},
	{
		id: "Snowpiercer Title",
		name: {
			ko: "Snowpiercer-Title",
			en: "Snowpiercer-Title",
			jp: "",
			cn: ""
		},
		company: {
			ko: "설국열차 - 타이틀",
			en: "Snowpiercer-Title",
			jp: "",
			cn: ""
		},
		type: "OPENING TITLE",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/389486623"
	},
	{
		id: "Lost Kingdom",
		name: {
			ko: "Lost Kingdom",
			en: "Lost Kingdom",
			jp: "",
			cn: ""
		},
		company: {
			ko: "Lost Kingdom",
			en: "Lost Kingdom",
			jp: "",
			cn: ""
		},
		type: "Motion",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/389486596"
	},
	{
		id: "Lg Optimus G",
		name: {
			ko: "LG Optimus G",
			en: "LG Optimus G",
			jp: "",
			cn: ""
		},
		company: {
			ko: "LG Optimus G",
			en: "LG Optimus G",
			jp: "",
			cn: ""
		},
		type: "MOTION",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/389486550"
	},
	{
		id: "Cgv Elevator",
		name: {
			ko: "CGV-ELEVATOR",
			en: "CGV-ELEVATOR",
			jp: "",
			cn: ""
		},
		company: {
			ko: "CGV-ELEVATOR",
			en: "CGV-ELEVATOR",
			jp: "",
			cn: ""
		},
		type: "MOTION",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/389486493"
	},
	{
		id: "All New Cgv",
		name: {
			ko: "All NEW CGV",
			en: "All NEW CGV",
			jp: "",
			cn: ""
		},
		company: {
			ko: "All NEW CGV",
			en: "All NEW CGV",
			jp: "",
			cn: ""
		},
		type: "MOTION",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/389486429"
	},
	//ArtWork
	{
		id: "alienArt",
		name: {
			ko: "Alien - LEE SUHYUN | Project Guide",
			en: "Alien - LEE SUHYUN | Project Guide",
			jp: "",
			cn: ""
		},
		company: {
			ko: "Alien - LEE SUHYUN | Project Guide",
			en: "Alien - LEE SUHYUN | Project Guide",
			jp: "",
			cn: ""
		},
		type: "ARTWORK",
		mainImg: ailien,
		detailImg: [alienDetail],
		videoNew: "https://player.vimeo.com/video/472901907"
	},

	{
		id: "bespokeArt",
		name: {
			ko: "Bespoke | Project Guide",
			en: "Bespoke | Project Guide",
			jp: "",
			cn: ""
		},
		company: {
			ko: "Bespoke | Project Guide",
			en: "Bespoke | Project Guide",
			jp: "",
			cn: ""
		},
		type: "ARTWORK",
		mainImg: bespoke2,
		detailImg: [bespokeDetail],
		videoNew: "https://player.vimeo.com/video/527082917"
	},
	{
		id: "Maxius",
		name: {
			ko: "Maxius",
			en: "Maxius",
			jp: "",
			cn: ""
		},
		company: {
			ko: "Maxius",
			en: "Maxius",
			jp: "",
			cn: ""
		},
		type: "DESIGN",
		mainImg: Maxius,
		detailImg: [
			...Array(38)
				.fill("")
				.map((item, index) => [
					require(`./workId/childs/Maxius/Contents/image (${index + 1}).jpg`)
				])
		]
	},
	{
		id: "LocaMoney",
		name: {
			ko: "LocaMoney",
			en: "LocaMoney",
			jp: "",
			cn: ""
		},
		company: {
			ko: "LocaMoney",
			en: "LocaMoney",
			jp: "",
			cn: ""
		},
		type: "ARTWORK",
		mainImg: LocaMoney,
		detailImg: [LocaMoneyDetail]
	},
	{
		id: "ConceptCube",
		name: {
			ko: "ConceptCube",
			en: "ConceptCube",
			jp: "",
			cn: ""
		},
		company: {
			ko: "ConceptCube",
			en: "ConceptCube",
			jp: "",
			cn: ""
		},
		type: "DESIGN",
		mainImg: ConceptCube,
		detailImg: [
			...Array(36)
				.fill("")
				.map((item, index) => [
					require(`./workId/childs/ConceptCube/Contents/image (${index + 1}).jpg`)
				])
		]
	},
	{
		id: "ServerKit",
		name: {
			ko: "ServerKit",
			en: "ServerKit",
			jp: "",
			cn: ""
		},
		company: {
			ko: "ServerKit",
			en: "ServerKit",
			jp: "",
			cn: ""
		},
		type: "DESIGN",
		mainImg: ServerKit,
		detailImg: [
			...Array(40)
				.fill("")
				.map((item, index) => [
					require(`./workId/childs/Servekit/Contents/image (${index + 1}).jpg`)
				])
		]
	},
	{
		id: "Methin",
		name: {
			ko: "Methin",
			en: "Methin",
			jp: "",
			cn: ""
		},
		company: {
			ko: "Methin",
			en: "Methin",
			jp: "",
			cn: ""
		},
		type: "DESIGN",
		mainImg: Methin,
		detailImg: [
			...Array(58)
				.fill("")
				.map((item, index) => [
					require(`./workId/childs/Methin/Contents/image (${index + 1}).jpg`)
				])
		]
	},
	{
		id: "Sejong",
		name: {
			ko: "Sejong",
			en: "Sejong",
			jp: "",
			cn: ""
		},
		company: {
			ko: "Sejong",
			en: "Sejong",
			jp: "",
			cn: ""
		},
		type: "ARTWORK",
		mainImg: Sejong,
		detailImg: [SejongDetail]
	},
	{
		id: "KKAL",
		name: {
			ko: "KKAL",
			en: "KKAL",
			jp: "",
			cn: ""
		},
		company: {
			ko: "KKAL",
			en: "KKAL",
			jp: "",
			cn: ""
		},
		type: "DESIGN",
		mainImg: KKAL,
		detailImg: [
			...Array(36)
				.fill("")
				.map((item, index) => [
					require(`./workId/childs/KKAL/Contents/image (${index + 1}).jpg`)
				])
		]
	},
	{
		id: "UIX",
		name: {
			ko: "UIX",
			en: "UIX",
			jp: "",
			cn: ""
		},
		company: {
			ko: "UIX",
			en: "UIX",
			jp: "",
			cn: ""
		},
		type: "DESIGN",
		mainImg: UIX,
		detailImg: [
			...Array(20)
				.fill("")
				.map((item, index) => [
					require(`./workId/childs/UIX/Contents/image (${index + 1}).jpg`)
				])
		]
	},
	{
		id: "NOMAD",
		name: {
			ko: "NOMAD",
			en: "NOMAD",
			jp: "",
			cn: ""
		},
		company: {
			ko: "NOMAD",
			en: "NOMAD",
			jp: "",
			cn: ""
		},
		type: "DESIGN",
		mainImg: NOMAD,
		detailImg: [
			...Array(34)
				.fill("")
				.map((item, index) => [
					require(`./workId/childs/NOMAD/Contents/image (${index + 1}).jpg`)
				])
		]
	},
	{
		id: "Artificialcity",
		name: {
			ko: "Artificialcity",
			en: "Artificialcity",
			jp: "",
			cn: ""
		},
		company: {
			ko: "Artificialcity",
			en: "Artificialcity",
			jp: "",
			cn: ""
		},
		type: "ARTWORK",
		mainImg: Artificialcity,
		detailImg: [ArtificialcityDetail]
	},
	{
		id: "whatHappend",
		name: {
			ko: "What Happened to 30's | Porject Guide",
			en: "What Happened to 30's | Porject Guide",
			jp: "",
			cn: ""
		},
		company: {
			ko: "What Happened to 30's | Porject Guide",
			en: "What Happened to 30's | Porject Guide",
			jp: "",
			cn: ""
		},
		type: "ARTWORK",
		mainImg: WhatHappen,
		detailImg: [
			...Array(72)
				.fill("")
				.map((item, index) => [
					require(`./workId/childs/WHT30/Contents/image (${index + 1}).jpg`)
				])
		]
	},
	{
		id: "GuardSignature",
		name: {
			ko: "GuardSignature",
			en: "GuardSignature",
			jp: "",
			cn: ""
		},
		company: {
			ko: "GuardSignature",
			en: "GuardSignature",
			jp: "",
			cn: ""
		},
		type: "DESIGN",
		mainImg: GuardSignature,
		detailImg: [
			...Array(36)
				.fill("")
				.map((item, index) => [
					require(`./workId/childs/GuardSignature/Contents/image (${index + 1}).jpg`)
				])
		]
	},
	{
		id: "ㅅㅇㄹ",
		name: {
			ko: "살으리",
			en: "살으리",
			jp: "",
			cn: ""
		},
		company: {
			ko: "살으리",
			en: "살으리",
			jp: "",
			cn: ""
		},
		type: "DESIGN",
		mainImg: Sareuri,
		detailImg: [
			...Array(23)
				.fill("")
				.map((item, index) => [
					require(`./workId/childs/ㅅㅇㄹ/Contents/image (${index + 1}).jpg`)
				])
		]
	},
	{
		id: "FarmPharm",
		name: {
			ko: "FarmPharm",
			en: "FarmPharm",
			jp: "",
			cn: ""
		},
		company: {
			ko: "FarmPharm",
			en: "FarmPharm",
			jp: "",
			cn: ""
		},
		type: "DESIGN",
		mainImg: FarmPharm,
		detailImg: null
	},
	{
		id: "Yame",
		name: {
			ko: "Yame",
			en: "Yame",
			jp: "",
			cn: ""
		},
		company: {
			ko: "Yame",
			en: "Yame",
			jp: "",
			cn: ""
		},
		type: "DESIGN",
		mainImg: Yame,
		detailImg: null
	},
	{
		id: "Prong",
		name: {
			ko: "Prong",
			en: "Prong",
			jp: "",
			cn: ""
		},
		company: {
			ko: "Prong",
			en: "Prong",
			jp: "",
			cn: ""
		},
		type: "DESIGN",
		mainImg: Prong,
		detailImg: [
			...Array(38)
				.fill("")
				.map((_item, index) => [
					require(`./workId/childs/Prong/Contents/image (${index + 1}).jpg`)
				])
		]
	},
	{
		id: "Icarus",
		name: {
			ko: "Icarus",
			en: "Icarus",
			jp: "",
			cn: ""
		},
		company: {
			ko: "Icarus",
			en: "Icarus",
			jp: "",
			cn: ""
		},
		type: "ARTWORK",
		mainImg: Icarus,
		detailImg: [IcarusDetail]
	},
	{
		id: "TheNulli",
		name: {
			ko: "TheNulli",
			en: "TheNulli",
			jp: "",
			cn: ""
		},
		company: {
			ko: "TheNulli",
			en: "TheNulli",
			jp: "",
			cn: ""
		},
		type: "DESIGN",
		mainImg: ThuNulli,
		detailImg: [
			...Array(38)
				.fill("")
				.map((item, index) => [
					require(`./workId/childs/TheNulli/Contents/image (${index + 1}).jpg`)
				])
		]
	},
	{
		id: "Exon",
		name: {
			ko: "Exon",
			en: "Exon",
			jp: "",
			cn: ""
		},
		company: {
			ko: "Exon",
			en: "Exon",
			jp: "",
			cn: ""
		},
		type: "DESIGN",
		mainImg: Exon,
		detailImg: [
			...Array(39)
				.fill("")
				.map((item, index) => [
					require(`./workId/childs/Exon/Contents/image (${index + 1}).jpg`)
				])
		]
	},
	{
		id: "work_11",
		name: {
			ko: "ToastCanvas",
			en: "ToastCanvas",
			jp: "",
			cn: ""
		},
		company: {
			ko: "ToastCanvas",
			en: "ToastCanvas",
			jp: "",
			cn: ""
		},
		type: "DESIGN",
		mainImg: ToastCanvas,
		detailImg: [
			...Array(41)
				.fill("")
				.map((item, index) => [
					require(`./workId/childs/ToastCanvas/Contents/메인 (${index + 1}).jpg`)
				])
		]
	},
	/// 여기 위로

	{
		id: "work_10",
		name: {
			ko: "Better Normal for All | Project Guide",
			en: "Better Normal for All | Project Guide",
			jp: "",
			cn: ""
		},
		company: {
			ko: "Better Normal for All | Project Guide",
			en: "Better Normal for All | Project Guide",
			jp: "",
			cn: ""
		},
		type: "ARTWORK",
		mainImg: samsung,
		detailImg: [samsungDetail],
		videoNew: "https://player.vimeo.com/video/499461535"
	},

	{
		id: "work_9",
		name: {
			ko: "Hyundaicard Rima | Porject Guide",
			en: "Hyundaicard Rima | Porject Guide",
			jp: "",
			cn: ""
		},
		company: {
			ko: "Hyundaicard Rima | Porject Guide",
			en: "Hyundaicard Rima | Porject Guide",
			jp: "",
			cn: ""
		},
		type: "ARTWORK",
		mainImg: hyundai_art,
		detailImg: [hyundai_artDetail],
		videoNew: "https://player.vimeo.com/video/485340394"
	},
	{
		id: "work_8",
		name: {
			ko: "Milke Shake | Project Guide",
			en: "Milke Shake | Project Guide",
			jp: "",
			cn: ""
		},
		company: {
			ko: "Milke Shake | Project Guide",
			en: "Milke Shake | Project Guide",
			jp: "",
			cn: ""
		},
		type: "ARTWORK",
		mainImg: milkshake,
		detailImg: [milkshakeDetail]
	},
	{
		id: "work_7",
		name: {
			ko: "Priest | Project Guide",
			en: "Priest | Project Guide",
			jp: "",
			cn: ""
		},
		company: {
			ko: "Priest | Project Guide",
			en: "Priest | Project Guide",
			jp: "",
			cn: ""
		},
		type: "ARTWORK",
		mainImg: "",
		detailImg: [priestDetail],
		videoNew: "https://player.vimeo.com/video/395442161"
	},
	{
		id: "work_6",
		name: {
			ko: "KT Skylife | Project Guide",
			en: "KT Skylife | Project Guide",
			jp: "",
			cn: ""
		},
		company: {
			ko: "KT Skylife | Project Guide",
			en: "KT Skylife | Project Guide",
			jp: "",
			cn: ""
		},
		type: "ARTWORK",
		mainImg: "",
		detailImg: [ktSkylifeDetail],
		videoNew: "https://player.vimeo.com/video/472901762"
	},
	{
		id: "work_5",
		name: {
			ko: "Dark Circle Pictures | Project Guide",
			en: "Dark Circle Pictures | Project Guide",
			jp: "",
			cn: ""
		},
		company: {
			ko: "Dark Circle Pictures | Project Guide",
			en: "Dark Circle Pictures | Project Guide",
			jp: "",
			cn: ""
		},
		type: "ARTWORK",
		mainImg: dark,
		detailImg: [darkDetail]
	},
	{
		id: "work_4",
		name: {
			ko: "Timing Alice | Project Guide",
			en: "Timing Alice | Project Guide",
			jp: "",
			cn: ""
		},
		company: {
			ko: "Timing Alice | Project Guide",
			en: "Timing Alice | Project Guide",
			jp: "",
			cn: ""
		},
		type: "ARTWORK",
		mainImg: timing,
		detailImg: [timingDetail]
	},
	{
		id: "work_3",
		name: {
			ko: "Timing Alice | Project Guide",
			en: "Timing Alice | Project Guide",
			jp: "",
			cn: ""
		},
		company: {
			ko: "Timing Alice | Project Guide",
			en: "Timing Alice | Project Guide",
			jp: "",
			cn: ""
		},
		type: "ARTWORK",
		mainImg: timingDark,
		detailImg: [timingDarkDetail]
	},
	{
		id: "work_2",
		name: {
			ko: "Lyn - The Lightbringer | Project Guide",
			en: "Lyn - The Lightbringer | Project Guide",
			jp: "",
			cn: ""
		},
		company: {
			ko: "Lyn - The Lightbringer | Project Guide",
			en: "Lyn - The Lightbringer | Project Guide",
			jp: "",
			cn: ""
		},
		type: "ARTWORK",
		mainImg: "",
		detailImg: lynDetail,
		videoNew: "https://player.vimeo.com/video/330937494"
	},
	{
		id: "work_1",
		name: {
			ko: "Victor - 비극의 탄생 | Project Guide",
			en: "Victor | Project Guide",
			jp: "",
			cn: ""
		},
		company: {
			ko: "Victor - 비극의 탄생 | Project Guide",
			en: "Victor | Project Guide",
			jp: "",
			cn: ""
		},
		type: "ARTWORK",
		mainImg: victor,
		detailImg: victorDetail
	},
	{
		id: "work_0",
		name: {
			ko: "2020 Looc Showreel",
			en: "2020 Looc Showreel",
			jp: "",
			cn: ""
		},
		company: {
			ko: "2020 Looc Showreel",
			en: "2020 Looc Showreel",
			jp: "",
			cn: ""
		},
		type: "SHOWREEL",
		mainImg: "",
		videoNew: "https://player.vimeo.com/video/425415801"
	}
];
