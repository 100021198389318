import React, { useState, useEffect } from "react";
import Slider from "react-slick";

import "./About.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Footer from "Components/Common/Footer/Footer";

import AboutMain from "Assets/Images/About/about-main-2@3x.png";
import MoAboutMain from "Assets/Images/About/about-main-2.png";
import BkLogo from "Assets/Images/About/380-bk-logo.png";

import Character_01 from "Assets/Images/About/all@3x.png";
import Character_02 from "Assets/Images/About/01@3x.png";
import Character_03 from "Assets/Images/About/02@3x.png";
import Character_04 from "Assets/Images/About/03@3x.png";
import Character_05 from "Assets/Images/About/04@3x.png";

import { AboutContents } from "Components/Data/About/AboutContents";
import { useTranslation } from "react-i18next";
const settings = {
	// className: "center",
	infinite: true,
	centerPadding: "60px",
	slidesToShow: 3,
	swipeToSlide: true,

	arrows: false
};

const data = [
	Character_01
	// Character_02,
	// Character_03,
	// Character_04,
	// Character_05
];

function About() {
	const { t, i18n } = useTranslation();
	const [menu, setMenu] = useState("Unique");
	const [isMobile, setIsMoblie] = useState(true);
	function onChange(value: string) {
		setMenu(value);
	}

	const handleResize = () => {
		// console.log(`브라우저 화면 사이트 x : ${window.innerWidth}, y : ${window.innerHeight}`);
		if (window.innerWidth > 767) {
			setIsMoblie(true);
		} else {
			setIsMoblie(false);
		}
	};

	function AboutMainImage(props: any) {
		if (isMobile) {
			return <img className="about-main" src={AboutMain} alt="" />; //PC
		} else {
			return <img className="about-main" src={MoAboutMain} alt="" />; //Mobile
		}
	}
	useEffect(() => {
		handleResize();
	}, [menu]);

	useEffect(() => {
		window.scrollTo(0, 0);
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return (
		<>
			<div className="about-container">
				<ul className="group">
					<AboutMainImage />
					<div className="list-character">
						{data.map((item, index) => (
							<div key={index} className={`list-character-image character${index}`}>
								<img src={item} alt="" />
							</div>
						))}
						{/* <CustomCarousel contents={AboutContents} /> */}
					</div>
					<div className="discription">
						<div className="menu-box">
							<a>
								<input
									hidden
									id="Unique"
									value="Unique"
									defaultChecked={true}
									type="radio"
									name="menu-box"
									onChange={e => onChange(e.target.value)}
								/>
								<label className="tap" htmlFor="Unique">
									{t("about_text_5")}
								</label>
							</a>
							<a>
								<input
									hidden
									id="Professional"
									value="Professional"
									type="radio"
									name="menu-box"
									onChange={e => onChange(e.target.value)}
								/>
								<label className="tap" htmlFor="Professional">
									{t("about_text_6")}
								</label>
							</a>
							<a>
								<input
									hidden
									id="Creative"
									value="Creative"
									type="radio"
									name="menu-box"
									onChange={e => onChange(e.target.value)}
								/>
								<label className="tap" htmlFor="Creative">
									{t("about_text_7")}
								</label>
							</a>
							<a>
								<input
									hidden
									id="Ingenious"
									value="Ingenious"
									type="radio"
									name="menu-box"
									onChange={e => onChange(e.target.value)}
								/>
								<label className="tap" htmlFor="Ingenious">
									{t("about_text_8")}
								</label>
							</a>
						</div>
						<div className={"text-box " + (i18n.language === "ko" ? "ko" : "en")}>
							<div>
								{menu === "Unique" && (
									<p>
										{t("about_text_0")}<span> </span>
										{t("about_text_1")}
										<br />
										<br />
										{t("about_text_2")}
									</p>
								)}
								{menu === "Professional" && (
									<p>
										{t("about_text_0")}<span> </span>
										{t("about_text_1")}
										<br />
										<br />
										{t("about_text_2")}
									</p>
								)}
								{menu === "Creative" && (
									<p>
										{t("about_text_0")}<span> </span>
										{t("about_text_1")}
										<br />
										<br />
										{t("about_text_2")}
									</p>
								)}
								{menu === "Ingenious" && (
									<p>
										{t("about_text_0")}<span> </span>
										{t("about_text_1")}
										<br />
										<br />
										{t("about_text_2")}
									</p>
								)}
							</div>
						</div>
					</div>

					<div className="we-are-looc-reative">
						<img className="bk-logo" src={BkLogo} alt="" />
						<span className={"sub-text " + (i18n.language === "ko" ? "ko" : "en")}>{t("about_text_3")}</span>
						<span className={"end-text " + (i18n.language === "ko" ? "ko" : "en")}>{t("about_text_4")}</span>
					</div>
				</ul>
				<Footer />
			</div >
		</>
	);
}
export default About;
