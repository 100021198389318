import React from "react";

import "./IntroSmall.scss";

export const IntroSmall = ({
	title1,
	title2,
	detail,
	styleContainer
}: {
	title1?: any;
	title2?: string;
	detail?: string;

	styleContainer?: object;
}) => {
	return (
		<div className="introSmall-star-war">
			<div>
				<p className="introSmall-title1" dangerouslySetInnerHTML={{ __html: title1 }}></p>
				<p className="introSmall-title2">{title2}</p>
			</div>
			<div className="introSmall-detail">
				<p>{detail}</p>
			</div>
		</div>
	);
};
