import React from "react";
import bg from "../../Contents/bg-main.png";
import bg_mockup_01 from "../../Contents/bg-mockup-01.png";
import bg_mockup_02 from "../../Contents/bg-mockup-02.png";
import bg_mockup_03 from "../../Contents/bg-mockup-03.png";
import bg_mockup_04 from "../../Contents/bg-mockup-04.png";
import bg_mockup_05 from "../../Contents/bg-mockup-05.png";
import bg_mockup_06 from "../../Contents/bg-mockup-06.png";
import bg_mockup_07 from "../../Contents/bg-mockup-07.png";
import bg_mockup_08 from "../../Contents/bg-mockup-08.png";
import bg_key_visual_01 from "../../Contents/img-keyvisual-01.png";
import bg_key_visual_02 from "../../Contents/img-keyvisual-02.png";
import bg_keyword_01 from "../../Contents/img-keyword-01.png";
import bg_keyword_02 from "../../Contents/img-keyword-02.png";
import bg_overview from "../../Contents/img-overview.png";
import bg_target_01 from "../../Contents/img-target-01.png";
import bg_target_02 from "../../Contents/img-target-02.png";
import bg_typo_01 from "../../Contents/img-typo-01.png";
import bg_typo_02 from "../../Contents/img-typo-02.png";

import { Intro } from "../Intro/Intro";
import "./style.scss";
function HomeMust() {
	return (
		<div className="home-must">
			<div className="imgBg">
				<img src={bg} alt="" />
			</div>
			<Intro
				title="Project Overview"
				detail="머스트잇은 국내에서 가장 많은 명품 브랜드를 판매하는 온라인 명품 플랫폼으로 럭셔리 브랜드를 판매하는 셀러와 패션 피플을 연결하고 다양하고 트렌디한 니즈를 충족시킬 수 있도록 하는 서비스이다. 명품 브랜드를 판매하는 플랫폼의 이미지를 해치지 않도록, 고급스러우면서 미니멀한 이미지를 유지하는 것에 초점을 맞추어 프로젝트를 진행하였다."
				isCenter={false}
				styleContainer={{ maxWidth: 1245 }}
			/>
			<div className="imgBg" style={{ marginBottom: 150 }}>
				<img src={bg_overview} alt="" />
			</div>
			<div className="mainVideo" style={{ paddingBottom: 0 }}>
				<video autoPlay muted loop>
					<source
						src={require("../../Source/2021_08_머스트잇_런칭.mp4")}
						type="video/mp4"
					/>
				</video>
			</div>
			<Intro
				title="Keyword"
				detail="무채색의 배경에 모델이 입은 블랙의 착장과 움직임에 ‘MUST IT’ -> ‘반드시 있다’ 라는 단순한 키워드로 심플한 타이포들을 미니멀하면서 볼드하게 애니메이션을 주어 모델이 전달하는 비주얼적인 이미지를 해치지 않게 고급스러운 이미지를 전달하도록 했다."
				isCenter={false}
				styleContainer={{ maxWidth: 1245 }}
			/>

			<div className="imgBg" style={{ padding: "3.3% 17.44% 10.45% 17.44%" }}>
				<img src={bg_keyword_01} alt="" />
			</div>
			<div className="imgBg">
				<img src={bg_keyword_02} alt="" />
			</div>
			<Intro
				title="Target"
				detail="이 영상을 보고 영향을 받고 움직이는 사람들은 사람들은 패션에 관심이 있으며, 명품 브랜드를 소비할 생각이 있고 앱으로 소비를 할 여지가 있는 사람들이므로  간결하게 ‘명품 플랫폼’ 중 1등, 해당 앱의 키워드 ‘이유가 반드시’ 등 같은 키워드 들을 풀어서 설명하지 않고 직관적이고 미니멀하게 표출하고, 궁금증을 유발할 수 있게 디자인하였다."
				isCenter={false}
				styleContainer={{ paddingBottom: 0, maxWidth: 1245 }}
			/>

			<div className="imgBg" style={{ padding: "2.2% 17.44% 8% 17.44%" }}>
				<img src={bg_target_01} alt="" />
			</div>
			<div className="imgBg">
				<img src={bg_target_02} alt="" />
			</div>
			<Intro
				title="Typo"
				detail="처음부터 끝까지 볼드하게 들어가는 타이포 디자인은 머스트 잇의 로고와 같은 디테일로 폰트를 수정하여 타이포 디자인 자체만으로 머스트 잇의 BI를 
				드러낼 수 있도록 하였고, 모델의 움직임에 맞춰 어우러 질 수 있도록 디자인 하였다."
				isCenter={false}
				styleContainer={{ maxWidth: 1145 }}
			/>

			<div className="img-double">
				<img src={bg_typo_01} alt="" />
				<img src={bg_typo_02} alt="" />
			</div>

			<Intro
				title="Keyvisual"
				detail="모델의 움직임과 타이포디자인이 어우러 질 수 있도록 구성하였고,  머스트잇 로고의 모서리가 
				대각선으로 커팅되는 디테일이 추가된 볼드한 텍스트와 적색 따움표를 사용하여 머스트잇의 BI를 강조하였다."
				isCenter
				styleContainer={{ maxWidth: 840 }}
			/>
			<div className="imgBg">
				<img src={bg_key_visual_01} alt="" />
			</div>
			<div className="imgBg">
				<img src={bg_key_visual_02} alt="" />
			</div>

			<div className="img-double">
				<img src={bg_mockup_01} alt="" />
				<img src={bg_mockup_02} alt="" />
			</div>
			<div className="imgBg">
				<img src={bg_mockup_03} alt="" />
			</div>

			<div className="img-triple">
				<div className="img-triple-1">
					<img src={bg_mockup_04} alt="" />
				</div>

				<div className="img-triple-2">
					<img src={bg_mockup_05} alt="" />
				</div>
				<div className="img-triple-3">
					<img src={bg_mockup_06} alt="" />
				</div>
			</div>

			<div className="imgBg">
				<img src={bg_mockup_07} alt="" />
			</div>
			<div className="imgBg">
				<img src={bg_mockup_08} alt="" />
			</div>
		</div>
	);
}

export default HomeMust;
