import React from "react";

import imgMainBg from "../Source/bg-main.png";

import imgOverview from "../Source/img-overview.png";
import imgTarget from "../Source/img-target.png";
import imgConcept01 from "../Source/img-concept-01.png";
import imgConcept02 from "../Source/img-concept-02.png";

import imgColor01 from "../Source/img-color-01.png";
import imgColor02 from "../Source/img-color-02.png";
import imgColor03 from "../Source/img-color-03.png";
import imgColor04 from "../Source/img-color-04.png";
import imgVisual from "../Source/img-keyvisual.png";

import imgAdv01 from "../Source/img-advertisement-01.png";
import imgAdv02 from "../Source/img-advertisement-02.png";
import imgAdv03 from "../Source/img-advertisement-03.png";
import imgEli from "../Source/bg-epilogue.png";

import gif01 from "../Source/graphic_01.gif";
import gif02 from "../Source/graphic_02.gif";
import gif03 from "../Source/graphic_03.gif";
import MasterVideo from "../Source/master_01.mp4";

import './styles.scss'

const PageBg = () => {
	return (
		<div className="digilloca">
			{/* <div className="main-bg">
				<img src={imgMainBg} alt="img" />
			</div> */}
			<OverView
				img={imgOverview}
				background="#fff"
				isGif={true}
				maxWidth="1250px"
				title="Overview"
				detail="‘롯데카드의 ‘디지로카’는 고객들이 앱을 통해 일상이 보다 편리해지고 풍요로워지는 경험을 누릴 수 있게 하는 목표를 가지고 진행되었다. 디지로카는 고객의 취향과 라이프 스타일을 분석해 금융뿐만 아니라 개인에게 최적화된 서비스와 헤택을 제공하는 개인 맞춤 밀착형 콘텐츠 플랫폼이다. 이를 바탕으로 잘 나타낼 수 있는 일상에서의 상황과 배경에서 내용이 전개됩니다."
			/>

			<OverView
				img={imgTarget}
				background="#f8fafe"
				maxWidth="1250px"
				title="Target"
				detail="롯데카드를 이미 사용하는 사람들 이외에도 화면을 보고 저 앱을 사용한다면 일상생활에서 좀 더 다양한 경험과 서비스를 경험할 수 있겠구나 라는 생각을 갖을 수 있도록 진행하였다. 때문에 특정한 사람들이 아닌 공통적으로 사람들이 자주 경험할 수 있는 상황에서 디지로카가 어떤 경험을 제공할 수 있는제를 보여주기 위해 쇼핑과 자동차 보험, 출근할 때의 화면을 보여주고자 하였습니다."
			/>
			<OverView
				img={imgConcept01}
				maxWidth="1250px"
				background="#fff"
				title="Concept"
				detail="출근할 때 사소하지만 알고있다면 시간을 단축하거나 다음을 결정하는데 도움이 되는 것들의 정보를 소개해가며 일상의 큐레이팅을 통한  프레젠테이션 이라는  느낌으로 진행하였다. 정보를 소개해주는 설명과 아이콘을 제외하고 컬러를 중간 중간 제거하여 더욱 돋보일 수 있도록 하였습니다."
			/>
			<div className="main-bg">
				<img src={imgConcept02} alt="img" />
			</div>
			<div className="main-bg">
				<img src={gif01} alt="img" />
			</div>
			<OverView
				img={imgColor01}
				background="#f8fafe"
				maxWidth="1109px"
				title="Color"
				detail="화면에서 장면마다 연출되는 자연스러운 컬러를 무채색으로 변환 -> 강조 및 전달하고자 하는 것들을  비비드한 컬러로 표현하여 표현하고자 하는 것들을 돋보일 수 있도록 연출하였습니다."
			/>
			<div className="main-bg">
				<img src={imgColor02} alt="img" />
			</div>
			<div className="main-bg">
				<img src={imgColor03} alt="img" />
			</div>
			<div className="main-bg">
				<img src={gif02} alt="img" />
			</div>
			<div className="main-bg">
				<img src={imgColor04} alt="img" />
			</div>
			<div className="key-visual">
				<div className="content">
					<div className="top">Keyvisual</div>
					<div className="bottom">
						{`출근길 일상에서 누구나 자주 일어날 수 있는 장면들을 이어서 보여주며 그
						안에서 나타낼 수 있는 정보들을 아이콘과 그래프 등을 섞어 텍스트와 함께
						표출시키며 진행하였습니다.`}
					</div>
				</div>
				<div className="item">
					<img src={imgVisual} alt="img" />
				</div>
			</div>
			<div className="mainVideo" style={{ paddingBottom: 0 }}>
				<video autoPlay muted loop>
					<source src={MasterVideo} type="video/mp4" />
				</video>
			</div>
			<div className="main-bg">
				<img src={imgAdv01} alt="img" />
			</div>
			<div className="main-bg">
				<img src={imgAdv02} alt="img" />
			</div>
			<div className="main-bg">
				<img src={imgAdv03} alt="img" />
			</div>
			<div className="main-bg">
				<img src={imgEli} alt="img" />
			</div>
		</div>
	);
};

export default PageBg;

const OverView = ({
	title,
	detail,
	img,
	background,
	isGif = false,
	maxWidth
}: {
	title: string;
	detail: string;
	img: any;
	background: string;
	maxWidth: string;
	isGif?: boolean;
}) => {
	return (
		<div className="overview" style={{ backgroundColor: background }}>
			<div className="item">
				<div className="top">{title}</div>
				<div className="bottom">{detail}</div>
			</div>

			<img src={img} alt="img" style={{ maxWidth: maxWidth }} />

			{isGif ? <img src={gif03} alt="" /> : <></>}
		</div>
	);
};
