import { IHomeContents, IHomeIntro } from "Components/Module/API/Interface/Home.interface";

import introCharacter from "Assets/Images/main-character.png";
import introVideo from "Assets/Images/2021-img.png";

import main_01 from "Assets/Images/tz-img-1@3x.png";
import worker_01 from "Assets/Images/character-info-1@3x.png";
import worker_01_work_01 from "Assets/Images/tz-img-2.png";
import worker_01_work_02 from "./workId/childs/DrBrain/thumbnail/thumbnail.jpg";
import worker_01_work_03 from "Assets/Images/tz-img-4.jpg";
import Ocean from "./workId/childs/고요의바다/Contents/image.jpg";
import DrBrain from "./workId/childs/DrBrain/Contents/image.png";

import DIGILOCA from "./workId/childs/DIGILOCA/Contents/image.jpg";
import Artificialcity from "./workId/childs/Artificialcity/thumb_artificialcity_840x528.jpg";
import ArtificialcityDetail from "./workId/childs/Artificialcity/Contents/PG_ArtificialCity.jpg";

import main_02 from "Assets/Images/chase-img-1@3x.png";
import worker_02 from "Assets/Images/character-info-2@3x.png";
import worker_02_work_01 from "Assets/Images/chase-img-2.png";
import worker_02_work_02 from "Assets/Images/chase-img-3.png";
import worker_021_work_02 from "Assets/Images/chase-img-21.png";
import worker_02_work_03 from "Assets/Images/chase-img-4.png";
import SQLED from "./workId/childs/SamsungQLED/Contents/image.jpg";
import Laneige from "./workId/childs/Laneige/Contents/image.jpg";
import CES2021Title from "./workId/childs/CES2021Title/Contents/image.jpeg";
import MustIt from "./workId/childs/MustIt/Contents/image.jpg";
// import fdasf from "../../../../src/Components/Data/Contents/workId/childs/DrBrain/video.mp4"

import main_03 from "./workId/childs/Lottegroup/thumbnail.jpg";
import worker_03 from "Assets/Images/character-info-3@3x.png";
import worker_03_work_01 from "Assets/Images/haenee-img-2.png";
import worker_03_work_02 from "./workId/childs/CES2023Opening/thumbnail/thumbnail.jpg";
import worker_03_work_03 from "Assets/Images/haenee-img-1.png";
import LocaMoney from "./workId/childs/LocaMoney/thumb_loca_840x528.jpg";
import LocaMoneyDetail from "./workId/childs/LocaMoney/Contents/220214_locacard.jpg";
import Oera_Detail from "./workId/childs/Oera/Contents/image.jpg";
import CES2023 from "./workId/childs/CES2023Opening/Contents/image.jpg";
import CES2023Video from "./workId/childs/CES2023Opening/video.mp4";


import SamsungGlaxyBookDetail from "./workId/childs/SamsungGalaxyBook2/Contents/image.jpg";
import SulwhasooContents from "./workId/childs/Sulwhasoo/Contents/image.jpg";
import LG_OLED_STARWARS from "./workId/childs/LG_OLED_STARWARS/Contents/image.jpg";

import ISE2023Video from './workId/childs/ISE2023/Source/master.mp4';

import TheSilentSea from "./workId/childs/고요의바다/TheSilentSeaPage";
import ArtificialcityPage from "./workId/childs/Artificialcity/ArtificialcityPage";
import SamsungNeoQLEDPage from "./workId/childs/SamsungQLED/SamsungNeoQLEDPage";
import LaneigePage from "./workId/childs/Laneige/LaneigePage";
import MustItPage from "./workId/childs/MustIt/MustItPage";
import LotteGroupPage from "./workId/childs/Lottegroup/LotteGroupPage";
import LocaMoneyPage from "./workId/childs/LocaMoney/LocaMoneyPage";
import SulwhasooPage from "./workId/childs/Sulwhasoo/SulwhasooPage";
import SamsungWhyGalaxyPage from "./workId/childs/SamsungWhyGalaxy/SamsungWhyGalaxyPage";
import LgOledStarWars from "./workId/childs/LG_OLED_STARWARS/LgOledStarWars";
import DrBrainPage from "./workId/childs/DrBrain/DrBrain";
import DigillocaPage from "./workId/childs/DIGILOCA/Digilloca";
import Ise2023Page from "./workId/childs/ISE2023/Ise2023Page";
import SamsungGalaxyBoook2 from "./workId/childs/SamsungGalaxyBook2/SamsungGalaxyBoook2";
import CES2023OpeningPage from "./workId/childs/CES2023Opening/CES2023Opening";
import ISE2023 from "./workId/childs/ISE2023/main.jpg";


import main_04 from "Assets/Images/bum-img-1.png";
import worker_04 from "Assets/Images/character-info-4.png";
import worker_04_work_01 from "Assets/Images/bum-img-2.png";
import worker_04_work_02 from "Assets/Images/bum-img-3.png";
import worker_04_work_03 from "Assets/Images/bum-img-4.png";
import main_05 from "./workId/childs/SamsungGalaxyBook2/thumbnail/thumbnail.jpg";
import worker_05 from "Assets/Images/character-info-5.png";
import worker_05_work_01 from "./workId/childs/Sulwhasoo/thumbnail/thumb_sulwhasoo_1920x1080.jpg";
import worker_05_work_02 from "./workId/childs/LG_OLED_STARWARS/thumbnail/thumbnail.png";
import worker_05_work_03 from "Assets/Images/yeom-img-4.png";
import main_06 from "Assets/Images/woo-img-1.png";
import worker_06 from "Assets/Images/character-info-6.png";
import worker_06_work_01 from "Assets/Images/woo-img-2.png";
import worker_06_work_02 from "Assets/Images/woo-img-3.png";
import worker_06_work_03 from "Assets/Images/woo-img-4.png";



export const HomeIntroContents: IHomeIntro = {
	image: introCharacter,
	video: introVideo
};

export const HomeContents: IHomeContents[] = [
	{
		mainThumbnail: main_01,
		mainTitle: "The Silent Sea",
		mainDescription:
			"TZ is the owner of Loocreative. It contains a description of TZ and a description of what you did. TZ is the owner of Loocreative. It contains a description of TZ and a description of what you did.",
		worker: worker_01,
		workThumbnail: [worker_01_work_01, worker_01_work_02, worker_01_work_03],
		workTitle: ["Artificial City", "DR.BRAIN", "Digi-LOCA"],
		workDescription: [
			"TZ is the owner of Loocreative. It contains a description of TZ...description of what you did.TZ is the owner of Loocreative.It contains....description",
			"Loocreative. It contains a description of TZ and a description of...what you did.TZ is the owner of Loocreative.It contains....description ",
			"TZ is the owner of Loocreative. It contains a description of TZ...description of what you did.TZ is the owner of Loocreative.It contains....description"
		],
		popupMaindata: {
			id: "고요의 바다",
			name: {
				ko: "Opening Title | 고요의 바다",
				en: "Opening Title | The Silent sea",
				jp: "",
				cn: ""
			},
			company: {
				ko: "Opening Title | 고요의 바다",
				en: "Opening Title | The Silent sea",
				jp: "",
				cn: ""
			},
			type: "OPENING TITLE",
			mainImg: "",
			videoNew: "https://player.vimeo.com/video/661081833",
			popupPage: TheSilentSea,
		},
		popupdata: [
			{
				id: "Artificialcity",
				name: {
					ko: "Artificialcity",
					en: "Artificialcity",
					jp: "",
					cn: ""
				},
				company: {
					ko: "Artificialcity",
					en: "Artificialcity",
					jp: "",
					cn: ""
				},
				type: "ARTWORK",
				mainImg: Artificialcity,
				videoNew: "https://player.vimeo.com/video/655256972",
				popupPage: ArtificialcityPage
			},
			{
				id: "Dr_Brain",
				name: {
					ko: "Dr.Brain",
					en: "Dr.Brain",
					jp: "",
					cn: ""
				},
				company: {
					ko: "Dr.Brain",
					en: "Dr.Brain",
					jp: "",
					cn: ""
				},
				type: "ARTWORK",
				mainImg: "",
				videoNew: "https://player.vimeo.com/video/803771840",
				// detailImg: [DrBrain],
				popupPage: DrBrainPage
			},
			{
				id: "Digi-LOCA",
				name: {
					ko: "Digi-LOCA",
					en: "Digi-LOCA",
					jp: "",
					cn: ""
				},
				company: {
					ko: "Digi-LOCA",
					en: "Digi-LOCA",
					jp: "",
					cn: ""
				},
				type: "COMMERCIAL",
				mainImg: "",
				videoNew: "https://player.vimeo.com/video/676115604",
				// detailImg: [DIGILOCA],
				popupPage: DigillocaPage
			}
		]
	},
	{
		mainThumbnail: main_02,
		mainTitle: "Samsung Qled Do Man",
		mainDescription:
			"is the owner of Loocreative. It contains a description of TZ and a description of what you did. TZ is the owner of Loocreative. It contains a description of TZ and a description of what you did.",
		worker: worker_02,
		workThumbnail: [worker_02_work_01, ISE2023, worker_02_work_03],
		workTitle: ["Laneige Water Bank", "IES2023", "Must It"],
		workDescription: [
			"TZ is the owner of Loocreative. It contains a description of TZ...description of what you did.TZ is the owner of Loocreative.It contains....description",
			"TZ is the owner of Loocreative. It contains a description of TZ...description of what you did.TZ is the owner of Loocreative.It contains....description",
			"TZ is the owner of Loocreative. It contains a description of TZ...description of what you did.TZ is the owner of Loocreative.It contains....description"
		],
		popupMaindata: {
			id: "SAMSUNG_692972798",
			name: {
				ko: "삼성 Neo QLED l Do Man",
				en: "SAMSUNG Neo QLED l Do Man",
				jp: "",
				cn: ""
			},
			company: {
				ko: "삼성 Neo QLED l Do Man",
				en: "SAMSUNG Neo QLED l Do Man",
				jp: "",
				cn: ""
			},
			type: "COMMERCIAL",
			mainImg: "",
			videoNew: "https://player.vimeo.com/video/692972798",
			popupPage: SamsungNeoQLEDPage
		},
		popupdata: [
			{
				id: "Laneige_685348146",
				name: {
					ko: "라네즈 워터뱅크 l 패키지 편",
					en: "Laneige Water Bank l Package",
					jp: "",
					cn: ""
				},
				company: {
					ko: "라네즈 워터뱅크 l 패키지 편",
					en: "Laneige Water Bank l Package",
					jp: "",
					cn: ""
				},
				type: "COMMERCIAL",
				mainImg: "",
				videoNew: "https://player.vimeo.com/video/685348146",
				popupPage: LaneigePage
			},
			{
				id: "ISE2023",
				name: {
					ko: "ISE2023",
					en: "ISE2023",
					jp: "",
					cn: ""
				},
				company: {
					ko: "ISE2023",
					en: "ISE2023",
					jp: "",
					cn: ""
				},
				type: "COMMERCIAL",
				mainImg: "",
				videoNew: "https://player.vimeo.com/video/803774181",
				popupPage: Ise2023Page
				// detailImg: [CES2021Title]
			},
			{
				id: "머스트잇",
				name: {
					ko: "머스트잇",
					en: "Must it",
					jp: "",
					cn: ""
				},
				company: {
					ko: "머스트잇",
					en: "Must it",
					jp: "",
					cn: ""
				},
				type: "COMMERCIAL",
				mainImg: "",
				videoNew: "https://player.vimeo.com/video/591366301",
				popupPage: MustItPage
			}
		]
	},
	{
		mainThumbnail: main_03,
		mainTitle: "Lotte Group",
		mainDescription:
			"is the owner of Loocreative. It contains a description of TZ and a description of what you did. TZ is the owner of Loocreative. It contains a description of TZ and a description of what you did.",
		worker: worker_03,
		workThumbnail: [worker_03_work_01, worker_03_work_02, worker_03_work_03],
		workTitle: ["Loca Money", "Samsung 2023 CES Opening", "Oera"],
		workDescription: [
			"TZ is the owner of Loocreative. It contains a description of TZ...description of what you did.TZ is the owner of Loocreative.It contains....description",
			"TZ is the owner of Loocreative. It contains a description of TZ...description of what you did.TZ is the owner of Loocreative.It contains....description",
			"TZ is the owner of Loocreative. It contains a description of TZ...description of what you did.TZ is the owner of Loocreative.It contains....description"
		],
		popupMaindata: {
			id: "Lotte_Group",
			name: {
				ko: "Lotte Group",
				en: "Lotte Group",
				jp: "",
				cn: ""
			},
			company: {
				ko: "Lotte Group",
				en: "Lotte Group",
				jp: "",
				cn: ""
			},
			type: "OPENING TITLE",
			mainImg: "",
			videoNew: "https://player.vimeo.com/video/722443463",
			popupPage: LotteGroupPage
		},
		popupdata: [
			{
				id: "LocaMoney",
				name: {
					ko: "LocaMoney",
					en: "LocaMoney",
					jp: "",
					cn: ""
				},
				company: {
					ko: "LocaMoney",
					en: "LocaMoney",
					jp: "",
					cn: ""
				},
				type: "ARTWORK",
				mainImg: LocaMoney,
				videoNew: "https://player.vimeo.com/video/653600207",
				popupPage: LocaMoneyPage
			},
			{
				id: "CES2023_Opening",
				name: {
					ko: "삼성 l CES2023 Opening Film",
					en: "SAMSUNG l CES2023 Opening Film",
					jp: "",
					cn: ""
				},
				company: {
					ko: "삼성 l CES2023 Opening Film",
					en: "SAMSUNG l CES2023 Opening Film",
					jp: "",
					cn: ""
				},
				type: "COMMERCIAL",
				mainImg: "",
				videoNew: "https://player.vimeo.com/video/803777169",
				popupPage: CES2023OpeningPage
			},
			{
				id: "Oera",
				name: {
					ko: "Oera",
					en: "Oera",
					jp: "",
					cn: ""
				},
				company: {
					ko: "Oera",
					en: "Oera",
					jp: "",
					cn: ""
				},
				type: "COMMERCIAL",
				mainImg: "",
				videoNew: "https://player.vimeo.com/video/624408838",
				detailImg: [Oera_Detail]
			}
		]
	},
	{
		mainThumbnail: main_04,
		mainTitle: "Coming Soon",
		mainDescription:
			"TZ is the owner of Loocreative. It contains a description of TZ and a description of what you did. TZ is the owner of Loocreative. It contains a description of TZ and a description of what you did.",
		worker: worker_04,
		workThumbnail: [worker_04_work_01, worker_04_work_02, worker_04_work_03],
		workTitle: ["Coming Soon", "Coming Soon", "Coming Soon"],
		workDescription: [
			"TZ is the owner of Loocreative. It contains a description of TZ...description of what you did. TZ is the owner of Loocreative. It contains.... description",
			"TZ is the owner of Loocreative. It contains a description of TZ...description of what you did. TZ is the owner of Loocreative. It contains.... description",
			"TZ is the owner of Loocreative. It contains a description of TZ...description of what you did. TZ is the owner of Loocreative. It contains.... description"
		],
		popupMaindata: {
			id: "coming_soon",
			name: {
				ko: "Coming Soon",
				en: "Coming Soon",
				jp: "",
				cn: "",
			},
			company: {
				ko: "Coming Soon",
				en: "Coming Soon",
				jp: "",
				cn: "",
			},
			type: "COMMERCIAL",
			mainImg: "",
			videoNew: "",
			// detailImg: [SamsungGlaxyBookDetail],
		},
		popupdata: [
			{
				id: "coming_soon",
				name: {
					ko: "Coming Soon",
					en: "Coming Soon",
					jp: "",
					cn: ""
				},
				company: {
					ko: "Coming Soon",
					en: "Coming Soon",
					jp: "",
					cn: ""
				},
				type: "COMMERCIAL",
				mainImg: "",
				videoNew: "",

			},
			{
				id: "coming_soon",
				name: {
					ko: "Coming Soon",
					en: "Coming Soon",
					jp: "",
					cn: ""
				},
				company: {
					ko: "Coming Soon",
					en: "Coming Soon",
					jp: "",
					cn: ""
				},
				type: "COMMERCIAL",
				mainImg: "",
				videoNew: "",
				// detailImg: [LG_OLED_STARWARS],

			},
			{
				id: "coming_soon",
				name: {
					ko: "Coming Soon",
					en: "Coming Soon",
					jp: "",
					cn: ""
				},
				company: {
					ko: "Coming Soon",
					en: "Coming Soon",
					jp: "",
					cn: ""
				},
				type: "COMMERCIAL",
				mainImg: "",
				videoNew: "",
			}
		]
	},
	{
		mainThumbnail: main_05,
		mainTitle: "Samsung Galaxybook2",
		mainDescription:
			"TZ is the owner of Loocreative. It contains a description of TZ and a description of what you did. TZ is the owner of Loocreative. It contains a description of TZ and a description of what you did.",
		worker: worker_05,
		workThumbnail: [worker_05_work_01, worker_05_work_02, worker_05_work_03],
		workTitle: ["Sulwhasoo", "LG OLED Disney Star Wars", "Coming Soon"],
		workDescription: [
			"TZ is the owner of Loocreative. It contains a description of TZ...description of what you did. TZ is the owner of Loocreative. It contains.... description",
			"TZ is the owner of Loocreative. It contains a description of TZ...description of what you did. TZ is the owner of Loocreative. It contains.... description",
			"TZ is the owner of Loocreative. It contains a description of TZ...description of what you did. TZ is the owner of Loocreative. It contains.... description"
		],
		popupMaindata: {
			id: "Samsung_Galaxybook2",
			name: {
				ko: "Samsung Galaxybook2",
				en: "Samsung Galaxybook2",
				jp: "",
				cn: "",
			},
			company: {
				ko: "Samsung Galaxybook2",
				en: "Samsung Galaxybook2",
				jp: "",
				cn: "",
			},
			type: "COMMERCIAL",
			mainImg: "",
			videoNew: "https://player.vimeo.com/video/700367952",
			// detailImg: [SamsungGlaxyBookDetail],
			popupPage: SamsungGalaxyBoook2
		},
		popupdata: [
			{
				id: "Sulwhasoo",
				name: {
					ko: "Sulwhasoo",
					en: "Sulwhasoo",
					jp: "",
					cn: ""
				},
				company: {
					ko: "Sulwhasoo",
					en: "Sulwhasoo",
					jp: "",
					cn: ""
				},
				type: "COMMERCIAL",
				mainImg: "",
				videoNew: "https://player.vimeo.com/video/803772200",
				popupPage: SulwhasooPage
			},
			{
				id: "LG_OLED_Disney_Star_Wars",
				name: {
					ko: "LG OLED Disney Star Wars",
					en: "LG OLED Disney Star Wars",
					jp: "",
					cn: ""
				},
				company: {
					ko: "LG OLED Disney Star Wars",
					en: "LG OLED Disney Star Wars",
					jp: "",
					cn: ""
				},
				type: "COMMERCIAL",
				mainImg: "",
				// videoNew: "https://player.vimeo.com/video/",
				// detailImg: [LG_OLED_STARWARS],
				popupPage: LgOledStarWars
			},
			{
				id: "coming_soon",
				name: {
					ko: "Coming Soon",
					en: "Coming Soon",
					jp: "",
					cn: ""
				},
				company: {
					ko: "Coming Soon",
					en: "Coming Soon",
					jp: "",
					cn: ""
				},
				type: "COMMERCIAL",
				mainImg: "",
				videoNew: "",

			}
		]
	},
	{
		mainThumbnail: main_06,
		mainTitle: "Coming Soon",
		mainDescription:
			"TZ is the owner of Loocreative. It contains a description of TZ and a description of what you did. TZ is the owner of Loocreative. It contains a description of TZ and a description of what you did.",
		worker: worker_06,
		workThumbnail: [worker_06_work_01, worker_06_work_02, worker_06_work_03],
		workTitle: ["Coming Soon", "Coming Soon", "Coming Soon"],
		workDescription: [
			"TZ is the owner of Loocreative. It contains a description of TZ...description of what you did. TZ is the owner of Loocreative. It contains.... description",
			"TZ is the owner of Loocreative. It contains a description of TZ...description of what you did. TZ is the owner of Loocreative. It contains.... description",
			"TZ is the owner of Loocreative. It contains a description of TZ...description of what you did. TZ is the owner of Loocreative. It contains.... description"
		],
		popupMaindata: {
			id: "coming_soon",
			name: {
				ko: "Coming Soon",
				en: "Coming Soon",
				jp: "",
				cn: "",
			},
			company: {
				ko: "Coming Soon",
				en: "Coming Soon",
				jp: "",
				cn: "",
			},
			type: "COMMERCIAL",
			mainImg: "",
			videoNew: "",
			// detailImg: [SamsungGlaxyBookDetail],
		},
		popupdata: [
			{
				id: "coming_soon",
				name: {
					ko: "Coming Soon",
					en: "Coming Soon",
					jp: "",
					cn: ""
				},
				company: {
					ko: "Coming Soon",
					en: "Coming Soon",
					jp: "",
					cn: ""
				},
				type: "COMMERCIAL",
				mainImg: "",
				videoNew: "",

			},
			{
				id: "coming_soon",
				name: {
					ko: "Coming Soon",
					en: "Coming Soon",
					jp: "",
					cn: ""
				},
				company: {
					ko: "Coming Soon",
					en: "Coming Soon",
					jp: "",
					cn: ""
				},
				type: "COMMERCIAL",
				mainImg: "",
				videoNew: "",
				// detailImg: [LG_OLED_STARWARS],

			},
			{
				id: "coming_soon",
				name: {
					ko: "Coming Soon",
					en: "Coming Soon",
					jp: "",
					cn: ""
				},
				company: {
					ko: "Coming Soon",
					en: "Coming Soon",
					jp: "",
					cn: ""
				},
				type: "COMMERCIAL",
				mainImg: "",
				videoNew: "",
			}
		]
	}
];
