import React, { ReactNode, useEffect, useLayoutEffect, useRef, useState } from "react";

import "./DataList.scss";
import { GetThumbnail } from "./GetThumbnail/GetThumbnail";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ConnectingAirportsOutlined } from "@mui/icons-material";
import { debug } from "console";
import { useFetch } from "usehooks-ts";
import { useTranslation } from "react-i18next";
gsap.registerPlugin(ScrollTrigger);

export interface IProjectData {
	id: string;
	workerTag?: string;
	name: {
		ko: string;
		en: string;
		jp: string;
		cn: string;
	};
	company: {
		ko: string;
		en: string;
		jp: string;
		cn: string;
	};
	type: string;
	mainImg: string;
	videoNew?: string;
	detailImg?: any;
	popupPage?: any;
}

interface IDataList {
	list: IProjectData[];
	selectTag: string;
	onClickDialogOpen(data: IProjectData): void;
}

export interface IThumbnail {
	id: string;
	value: string;
}

function DataList(props: IDataList) {
	const { t, i18n } = useTranslation();
	const { list, selectTag, onClickDialogOpen } = props;
	const LIMIT_AVEAGE = 100;
	const [language, setLanguage] = useState<string | null>("");
	const [limit, setLimit] = useState(0);
	const [posts, setPosts] = useState(list.slice(0, LIMIT_AVEAGE));
	const [isFetching, setIsFetching] = useState(false);

	useEffect(() => {
		const lang = sessionStorage.getItem("language");
		setLanguage(lang);
	}, [sessionStorage.getItem("language")]);

	const handleScroll = () => {
		if (
			window.innerHeight + document.documentElement.scrollTop + 1 <
			document.documentElement.scrollHeight - 200
		) {
			return;
		}
		setIsFetching(true);
	};

	const getMorePosts = () => {
		setLimit(limit + LIMIT_AVEAGE);
	};

	useEffect(() => {
		window.addEventListener("scroll", handleScroll);
		return () => window.removeEventListener("scroll", handleScroll);
	}, []);

	useEffect(() => {
		if (!isFetching) return;
		getMorePosts();
	}, [isFetching]);

	const getPosts = () => {
		setIsFetching(true);

		const data = list.slice(limit + LIMIT_AVEAGE, limit + LIMIT_AVEAGE * 2);

		setPosts([...posts, ...data]);
		setIsFetching(false);
	};
	useEffect(() => {
		if (limit < list.length) {
			getPosts();
		}
	}, [limit]);
	const [height, setHeight] = useState<number>();
	useEffect(() => {
		const elmnt = document.getElementById("Showreel2021");
		if (elmnt) {
			setHeight(elmnt.offsetHeight);
		}
	}, [limit]);

	// useEffect(() => {
	// 	const card = document.querySelectorAll(".item-container");
	// 	gsap.set(card, {
	// 	  opacity: 0.2,
	// 	  scale: 0.8,
	// 	  filter: 'blur(2px)'
	// 	});

	// 	ScrollTrigger.batch(card, {
	// 	  onEnter: batch => {

	// 		card.forEach((card, index) => {
	// 		  gsap.to(card, { opacity: 1, scale: 1, stagger: 0.4, delay: index * 0.2,filter: 'none' });
	// 		});
	// 	  }

	// 	});
	//   },[]);

	return (
		<div className="data-list-container">
			<div className="data-list-wrapper">
				<div className="list">
					{posts &&
						posts.map((value, index) => {
							// console.log(value.videoNew === undefined && value);
							return (
								<React.Fragment key={value.id + index}>
									{value.workerTag ===
										(selectTag === "ALL" ? value.workerTag : selectTag) && (
											<div
												className="item-container fade-in"
												onClick={() => {
													onClickDialogOpen(value);
												}}
											>
												<div
													className="item-wrapper"
													data-content={
														language === "ko"
															? value.name.en
															: value.name.en
													}
												>
													{value.mainImg.length > 1 ? (
														<div style={{ height: height }}>
															<img
																style={{
																	width: "100%",
																	height: "100%"
																}}
																src={value.mainImg}
															/>
														</div>
													) : (
														value.videoNew !== undefined && (
															<GetThumbnail
																height={height}
																id={value.id}
																url={value.videoNew}
															/>
														)
													)}
													<div className="item-wrapper-content">
														<p>
															{language === "ko"
																? value.name.en
																: value.name.en}
														</p>
													</div>
													{/* <div className="top-text-box">
												<div className="title">
													<span>{value.name.ko}</span>
												</div>
											</div> */}
												</div>
											</div>
										)}
								</React.Fragment>
							);
						})}
				</div>
			</div>
		</div>
	);
}
export default DataList;
