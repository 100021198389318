import React from "react";
import HomeStarWar from "./components/HomeSWG";
import "./LgOledStarWars.scss";

function LgOledStarWars() {
	return (
		<div className="start-war">
			<HomeStarWar />
		</div>
	);
}
export default LgOledStarWars;
