/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import Logo from "Assets/Images/64-wh-logo.svg";
import BkLogo from "Assets/Images/64-bk-logo.svg";

import { useTranslation } from "react-i18next";

import "./Header.scss";

interface MenuProp {
	to?: string;
	activeMenu: string;
	menuName: string;
}
interface IHeader {
	location: any;
	i18n: any;
}
const dataMenu = [
	{
		to: "/",
		activeMenu: "/",
		menuName: "Home"
	},
	{
		to: "/about",
		activeMenu: "/about",
		menuName: "About"
	},
	{
		to: "/work",
		activeMenu: "/work",
		menuName: "Work"
	},
	{
		to: "/contact",
		activeMenu: "/contact",
		menuName: "Contact"
	}
];

const Header: React.FC<IHeader> = ({ location, i18n }) => {
	const { t } = useTranslation();
	const navigation = useNavigate();
	const [isOpen, setIsOpen] = useState(false);
	const [isScroll, setIsScroll] = useState(false);
	const handleScroll = () => {
		if (window.scrollY > 8) {
			setIsScroll(true);
		} else {
			setIsScroll(false);
		}
	};
	window.addEventListener("scroll", handleScroll);

	const onChangeLang = () => {
		if (i18n.language === "ko") {
			i18n.changeLanguage("en");
			sessionStorage.setItem("language", "en");
		} else if (i18n.language === "en") {
			i18n.changeLanguage("ko");
			sessionStorage.setItem("language", "ko");
		}

		console.log(i18n.language);
	};

	useEffect(() => {
		navigation(location.pathname, { replace: true });
	}, [location.pathname]);

	const renderNavList = () => {
		return (
			<>
				<div className="menus ">
					<div className="menus_list">
						{dataMenu.map((item, index) => {
							return (
								<Link
									onClick={() => setIsOpen(false)}
									className={`${location.pathname === "/" ? "homeSection" : ""}`}
									key={index}
									to={item.to}
								>
									<span
										onClick={() => window.scroll(0, 0)}
										className={`tab ${
											location.pathname === item.to ? "activeMenu" : ""
										} `}
									>
										{item.menuName}
									</span>
								</Link>
							);
						})}
					</div>
				</div>
			</>
		);
	};

	const toggle_el = useRef(null);
	const content_el = useRef(null);

	const handleClickLogo = () => {
		navigation("/");
		window.scroll(0, 0);
	};

	useEffect(() => {
		if (isOpen) {
			document.body.style.overflow = "hidden";
		} else {
			document.body.style.overflow = "unset";
		}
	}, [isOpen]);
	return (
		<header className="header">
			<div
				className={`header-container ${isScroll === true ? "activeHeader" : ""} ${
					isOpen === true ? "openMenu" : ""
				}`}
			>
				<div className="logo">
					<Link to="/">
						<img
							onClick={() => handleClickLogo()}
							className="logo-img"
							src={`${
								location.pathname === "/" && window.innerWidth > 767 && !isScroll
									? BkLogo
									: BkLogo
							}`}
							alt=""
						></img>
					</Link>
				</div>
				<div className="list-menu">{renderNavList()}</div>
				<div className="language">
					<div className="mobile-icon">
						<div
							onClick={() => setIsOpen(!isOpen)}
							ref={toggle_el}
							id="toggle"
							className={`btn-toggle ${isOpen === true ? "open" : "hidden"}`}
						>
							<span></span>
							<span></span>
							<span></span>
						</div>
					</div>
					<div className={`${isOpen === true ? "openMenu" : ""}`}>
						<div
							className={` ${isOpen === true ? "langVisible" : "langHidden"}`}
							onClick={onChangeLang}
						>
							{t("header.language")}
						</div>
					</div>
				</div>
				<script
					id="ze-snippet"
					src="https://static.zdassets.com/ekr/snippet.js?key=1c39ac81-f28c-40d6-b45f-3b2946686f13"
				>
					{" "}
				</script>
			</div>
			<div
				ref={content_el}
				id="menu"
				className={`menu-list-mobile ${isOpen === true && "open"}`}
			>
				<div onClick={() => setIsOpen(!isOpen)} className="background-tranparent"></div>
				{renderNavList()}
			</div>
		</header>
	);
};

export default Header;
