import React from 'react'

import PageBg from './Components/PageBg'

const DigillocaPage = () => {
  return (
    <PageBg/>
  )
}

export default DigillocaPage