import React from "react";
import bg from "./Contents/bg-main.png";
import intro from "./Contents/img-overview.png";
import img_concept from "./Contents/img-concept.png";
import bg_targer from "./Contents/bg-target.png";
import gif_intro_01 from "./Source/01.gif";
import gif_intro_03 from "./Source/03.gif";
import gif_intro_04 from "./Source/02.gif";

import img_illu_01 from "./Contents/img-illust-01.png";
import img_illu_02 from "./Contents/img-illust-02.png";
import img_character_01 from "./Contents/img-character-01.png";
import img_character_02 from "./Contents/img-character-02.png";
import img_character_03 from "./Contents/img-character-03.png";
import img_character_04 from "./Contents/img-character-04.png";

import img_keyvisual_01 from "./Contents/img-keyvisual-01.png";
import img_keyvisual_02 from "./Contents/img-keyvisual-02.png";
import img_keyvisual_03 from "./Contents/img-keyvisual-03.png";
import img_keyvisual_04 from "./Contents/img-keyvisual-04.png";
import img_bgepilogue from "./Contents/bgepilogue.png";

import img_copy_right from "./Contents/bg-copyright.png";
import bg_key from "./Contents/bg-keyvisual.png";
import img_target from "./Contents/img-target-02.png";

import "./style.scss";
function LocaMoneyPage() {
	const contentIllu = `MZ세대를 상징하는 '영, 펀, 라이크(Young, Fun, Like)'라는 디자인 콘셉트와 \n 각각에 특화된 혜택을 강조하는 일러스트를 활용했습니다. 현재의 삶보다 더 나은 삶을 꿈꾸는 \n 고객들을 관찰하고 떠올리며 가장 와닿을 수 있는 형태를 선택했습니다.`;
	return (
		<div className="localMoney">
			<div className="localMoney-bg">
				<img src={bg} alt="" />
			</div>

			<div className="localMoney-intro">
				<div className="content">
					<p className="subtit">What is</p>
					<p className="detail">
						롯데카드가 금융 브랜드인 ‘LOCA MONEY(로카 머니)’ 를 출시했습니다. <br />
						LOCA MONEY 는 ‘새로운 도전을 즐기는 고객을 위한 선 넘은 금융’을 지향점으로
						삼았습니다.
						<br />
						새로운 금융, 라이프 스타일(Life-style) 맞춤형 금융을 통해 고객이 금융
						서비스가 필요한 순간
						<br />
						함께하고 고객의 한도 없는 도전을 응원한다는 의미를 담고 있습니다.
					</p>
				</div>

				<div className="img">
					<img src={intro} alt="" />
				</div>
			</div>

			<div className="localMoney-concept">
				<img src={img_concept} alt="" />
			</div>

			<div className="localMoney-target_">
				<div className="content">
					<p className="subtit">Target</p>
					<p className="detail">
						LOCA MONEY는 혜택에 민감하고 선호가 뚜렷한 MZ세대 <br />
						(밀레니얼+Z세대)의 소비 성향에 맞춰 더욱 다양하고 세분화
						<br />된 혜택을 제공하고 있습니다.
					</p>
				</div>

				<div className="img">
					<img src={img_target} alt="" />
				</div>
			</div>

			<div className="localMoney-target">
				<img src={bg_targer} alt="" />
				<img src={gif_intro_03} alt="" />
			</div>

			<div className="localMoney-illu">
				<div className="localMoney-illu-container">
					<div className="title">illustration</div>
					<div className="detail">{contentIllu}</div>
				</div>
			</div>

			<div className="localMoney-type">
				<div className="localMoney-type-container">
					<div className="type">
						<div className="title">Type A</div>
						<img src={img_illu_01} alt="" />
					</div>

					<div className="type">
						<div className="title">Type B</div>
						<img src={img_illu_02} alt="" />
					</div>
				</div>
			</div>

			<div className="localMoney-character">
				<div className="localMoney-character-container">
					<div className="content">
						<p className="subtit">Main character</p>
						<p className="detail">
							Type A의 캐릭터 디자인은 Type B의 디자인을 디벨롭(develop)하여
							작업했습니다. <br />
							영, 펀, 라이크(Young, Fun, Like)의 키워드에 가까운 디자인을 선택하였고,
							<br />
							LOCA CARD가 전하고자 하는 의미 또한 더욱 효과적으로 전달할 수 있게
							되었습니다.
						</p>
					</div>

					<div className="img">
						<img src={img_character_01} alt="" />
						<img src={img_character_02} alt="" />
						<img src={img_character_03} alt="" />
						<img src={img_character_04} alt="" />
					</div>
				</div>
			</div>

			<div className="localMoney-target">
				<img src={gif_intro_01} alt="" />
			</div>

			<div className="localMoney-target">
				<img src={img_copy_right} alt="" />
			</div>

			<div className="localMoney-key">
				<div className="localMoney-key-img">
					<img src={bg_key} alt="" />
				</div>
				<div className="localMoney-key-container">
					<div className="content">
						<p className="subtit">Key visual</p>
						<p className="detail">
							LOCA MONEY는 변화하고 있는 라이프 스타일와 함께 <br />
							다양한 상품과 서비스를 고객 중심의 커뮤니케이션에 맞춰
							<br />
							정보에 집중할 수 있도록 노력했습니다.
						</p>
					</div>

					<div className="img">
						<div className="img_item img_1">
							<img src={img_keyvisual_01} alt="" />
						</div>
						<div className="img_item img_2">
							<img src={img_keyvisual_02} alt="" />
						</div>

						<div className="img_item img_3">
							<img src={img_keyvisual_03} alt="" />
						</div>

						<div className="img_item img_4">
							<img src={img_keyvisual_04} alt="" />
						</div>
					</div>
				</div>
			</div>

			<div className="localMoney-list-img">
				<img src={gif_intro_04} alt="" />
				<img src={img_bgepilogue} alt="" />
			</div>
		</div>
	);
}
export default LocaMoneyPage;
