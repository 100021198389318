import React from "react";

import logo from "Assets/Images/64-wh-logo@3x.png";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import "./Footer.scss";
import { useTranslation } from "react-i18next";
// interface MenuProp {
// 	to?: string;
// 	activeMenu: string;
// 	menuName: string;
// }
// interface HeaderProps {
// 	menus: MenuProp[];
// }
const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "80%",
	maxWidth: 400,
	bgcolor: "background.paper",
	border: "1px solid #000",
	boxShadow: 15,
	p: 4
};
function Footer() {
	const { t, i18n } = useTranslation();
	const [open, setOpen] = React.useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	return (
		<>
			<div className="footer-container">
				<div className="left">
					<img className="logo" src={logo} alt="" />
					<li className="sns-left">
						<span onClick={() => { window.open("https://www.instagram.com/loocreative/") }} className="tab">
							IG
						</span>
						<span onClick={() => { window.open("https://vimeo.com/loocreative") }} className="tab">
							VM
						</span>
					</li>
				</div>
				<ul className="right">
					<li className="sns">
						<span onClick={() => { window.open("https://www.instagram.com/loocreative/") }} className="tab">
							IG
						</span>
						<span onClick={() => { window.open("https://vimeo.com/loocreative") }} className="tab">
							VM
						</span>
					</li>
					<li className="contact">
						<span>{t("footer_text_0")}</span>
						<br />
						<span>TEL. 02.543.4983</span>
						<br />
						<span>{t("footer_text_1")}</span>
					</li>
				</ul>
			</div>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={style}>
					<p>Coming soon...</p>
				</Box>
			</Modal>
		</>
	);
}

export default Footer;
