import React from "react";
import bg from "../../Contents/bg-main.png";
import bg_overview from "../../Contents/img-overview.png";
import bg_concept from "../../Contents/img-concept.png";
import img_typo from "../../Contents/img-typo.png";
import bg_typo from "../../Contents/bg-typo.png";
import bg_keyword from "../../Contents/img-keyword.png";
import bg_key_visual_01 from "../../Contents/img-keyvisual-01.png";
import bg_key_visual_02 from "../../Contents/img-keyvisual-02.png";
import bg_key_visual_03 from "../../Contents/img-keyvisual-03.png";
import bg_mockup_01 from "../../Contents/bg-mockup-01.png";
import bg_mockup_02 from "../../Contents/bg-mockup-02.png";
import bg_mockup_03 from "../../Contents/bg-mockup-03.png";
import bg_mockup_04 from "../../Contents/bg-mockup-04.png";
import bg_mockup_05 from "../../Contents/bg-mockup-05.png";

import "./style.scss";
import { Intro } from "../Intro/Intro";
function HomeLaneige() {
	return (
		<div className="home-laneige">
			<div className="imgBg">
				<img src={bg} alt="" />
			</div>
			<Intro
				title="Project Overview"
				detail="아모레 퍼시픽이 전개하는 기능성 뷰티브랜드 ‘라네즈’ 에서 새롭게 출시하는 블루 히알루론산을 주 성분으로 활용해 강력한 흡수율과 리페어 보습 효능을 선사하는
				올 뉴 ‘워터뱅크 블루 히알루토닉’을 소개한다. 전체적으로 제품의 분위기와 패키징 디자인에 초점을 두고 네이밍에 적합한 블루 톤의 컬러 위주로 산뜻한 느낌으로 진행해모델을 적절히 활용하며 제품의 특징을 돋보이게 해주는데에 집중하였습니다."
				isCenter={false}
			/>
			<div className="imgBg">
				<img src={bg_overview} alt="" />
			</div>
			<Intro
				title="Project Concept"
				detail="제품 패키징의 디자인 적인 구조를 활용해 적절히 트랜지션 하여 제품의 형태적인 디테일을 보여주는데에 
				집중하고, 텍스트로 이러한 구조들이 사용자에게 어떻게 접근될 것인지 설명해 나가 정보의 양을 늘리고 모델의
				이미지 비주얼로 사용자의 산뜻한 느낌을 제공합니다."
				isCenter={false}
				isHorizontal={true}
			/>

			<div className="imgBg">
				<img src={bg_concept} alt="" />
			</div>
			<div className="imgBg">
				<img alt="Loading..." src={require("../../Source/open_03.gif")} />
			</div>
			<Intro
				title="Keyword"
				detail="쉽게 열 수 있는, 그립감이 좋은, 최적화된, 어디서든 사용 가능한, 리페어, 완벽한, 등  제품의 특징과 전달하려고 하는 의도에 맞는 키워드를 설정하였다. 이렇게 설정한 키워드들을 조합하여 제품을 오픈하는 장면에서는 easy - open 을 돋보이게 하는 등 전달하고자 하는 내용과 키비주얼에 맞는 문장을 적재적소에 
				맞게 활용하여 가독성을 올렸습니다."
				isCenter={false}
			/>
			<div className="imgBg">
				<img src={bg_keyword} alt="" />
			</div>
			<Intro
				title="Typography"
				detail="산돌고딕 네오는 전달하고자 하는 내용과 제품의 디자인, 분위기와 잘 어우러지며 명확하게 전달하고자 하는 바를 전달할 수 있게 도와줄 수 있는 폰트였으며Futura Lt pro의 각진 형태의 타이포는 앞서 선택한 산돌고딕과 이질감 없이 조화롭게 어우러져 영상의 타이포는 한글은 산돌고딕, 영문은 futura lt pro로 
				설정한 뒤에 진행하였습니다."
				isCenter={false}
			/>

			<div className="imgBg">
				<img src={img_typo} alt="" />
			</div>
			<div className="imgBg">
				<img src={bg_typo} alt="" />
			</div>

			<Intro
				title="Keyvisual"
				detail="제품 패키징 중 하나인 모서리가 둥근 정육면체의 구조적인 특징을 살려 시작하여 시각적인 재미를 주고, 이를 활용한 트랜지션으로 장면이 넘어가며 자연스럽게제품의 형태와 구조에 대해 인지할 수 있도록 함과 동시에 이러한 구조가 사용자에게 어떻게 작용하는지 설명하며 제품 설명에 대해 소비자가 인지 할 수 있도록 하였습니다. 
				모델이 제품을 사용하는 장면이 제품이 지향하는 전반적인 분위기를 소개하는데 활용하였습니다."
				isCenter
			/>
			<div className="imgBg">
				<img src={bg_key_visual_01} alt="" />
			</div>

			<Intro title="" detail="" isCenter />

			<div className="imgBg">
				<img alt="Loading..." src={require("../../Source/intro_02.gif")} />
			</div>

			<div className="img-double">
				<div className="img-double-1">
					<img src={bg_key_visual_02} alt="" />
				</div>
				<div className="img-double-2">
					<img src={bg_key_visual_03} alt="" />
				</div>
			</div>
			<div className="imgBg">
				<img src={bg_mockup_01} alt="" />
			</div>
			<div className="imgBg">
				<img src={bg_mockup_02} alt="" />
			</div>
			<div className="imgBg">
				<img src={bg_mockup_03} alt="" />
			</div>
			<div className="imgBg">
				<img src={bg_mockup_04} alt="" />
			</div>
			<div className="imgBg">
				<img src={bg_mockup_05} alt="" />
			</div>
		</div>
	);
}

export default HomeLaneige;
