import React from 'react';
import HomeBg from './Components/HomeBg/HomeBg'

function ArtificialcityPage() {
    return (
        <div className='artificialcityPage'>
           <HomeBg />
        </div>
    )
}
export default ArtificialcityPage;