import React from "react";

import "./Intro.scss";

export const Intro = ({
	title,
	detail,
	isCenter
}: {
	title: string;
	detail: string;
	isCenter: boolean;
}) => {
	return (
		<div className="intro-ssqled">
			{title && detail ? (
				<div className="intro-ssqled-container">
					<div className={`title ${isCenter ? "center" : ""}`}>{title}</div>
					<div className={`detail ${isCenter ? "center" : ""}`}>{detail}</div>
				</div>
			) : (
				<div className="intro-ssqled-container" style={{ padding: "70px 0" }}>
					<div className={`title ${isCenter ? "center" : ""}`}>{title}</div>
					<div className={`detail ${isCenter ? "center" : ""}`}>{detail}</div>
				</div>
			)}
		</div>
	);
};
