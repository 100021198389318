import { Action, configureStore, Store, ThunkAction } from "@reduxjs/toolkit";
import thumbnailReducer from "Components/Common/DataList/GetThumbnail/thumbnailSlice";

export const store: Store = configureStore({
	reducer: {
		thumbnail: thumbnailReducer
	}
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
	ReturnType,
	RootState,
	unknown,
	Action<string>
>;
