import React, { Dispatch, useEffect, useRef, useState } from "react";
import { IProjectData } from "../DataList/DataList";
import "./Popup.scss";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
interface PopupProps {
	data: IProjectData;
	setOpenPopup: Dispatch<React.SetStateAction<boolean>>;
}

const Popup: React.FC<PopupProps> = ({ data, setOpenPopup }) => {
	const { id, company, mainImg, name, type, videoNew, workerTag, detailImg, popupPage } = data;

	const [language, setLanguage] = useState<string | null>("");
	const navigation = useNavigate();
	useEffect(() => {
		const lang = sessionStorage.getItem("language");
		setLanguage(lang);
	}, [language]);

	return (
		<div className="popup">
			<div className="popup-container" style={{ position: "relative" }}>
				<div className="btn-close" onClick={() => setOpenPopup(false)}>
					<CloseIcon></CloseIcon>
				</div>
				<div className="popup-inner">
					<div className="popup-header"></div>

					<div className="popup-contents">
						<div className="scroll-inners">
							<>
								{videoNew ? ( //case 1: vimeo is exist
									<div className="mainVideo">
										<iframe
											id="iMovie"
											title="mainVideo"
											name="movieFrame"
											src={videoNew}
											frameBorder="0"
											allow="autoplay; fullscreen; picture-in-picture"
											allowFullScreen={true}
										/>
									</div>
								) : null}
							</>
							{popupPage &&
								<>
									{popupPage()}
								</>
							}
							{!popupPage &&
								detailImg && Array.isArray(detailImg)
								? detailImg.map((sp: any, index: any) => (
									<img
										style={{ width: "100%", height: "100%" }}
										key={sp.toString() + index}
										src={sp.toString()}
										alt=""
									/>
								))
								: detailImg && ( //case 3 detailImg exist and is image
									<img
										style={{ width: "100%", height: "100%" }}
										src={detailImg}
										alt=""
									/>
								)
							}

							<div className="overView">
								<div className="overViewWrap">
									<div className="left-title">
										<div className="type">{type}</div>
										<div className="company">
											{language === "ko" ? company.ko : company.en}
										</div>
									</div>
								</div>
							</div>

							<a href="mailto:pd@loocreative.com" title="">
								<div className="contact-works">
									Contact us
								</div>
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Popup;
