import React from "react";

import "./Intro.scss";

export const Intro = ({
	title,
	detail,
	isCenter,
	isHorizontal
}: {
	title: string;
	detail: string;
	isCenter: boolean;
	isHorizontal?: boolean;
}) => {
	return (
		<div className="intro-laneige">
			{title && detail ? (
				<div className={`intro-laneige-container ${isHorizontal ? "horizontal" : ""}`}>
					<div className={`title ${isCenter ? "center" : ""}`}>{title}</div>
					<div
						className={`detail ${isCenter ? "center" : ""} ${
							isHorizontal ? "maxWidth" : ""
						}`}
					>
						{detail}
					</div>
				</div>
			) : (
				<div
					className={`intro-container ${isHorizontal ? "horizontal" : ""}`}
					style={{ padding: "70px 0" }}
				>
					<div className={`title ${isCenter ? "center" : ""}`}>{title}</div>
					<div className={`detail ${isCenter ? "center" : ""}`}>{detail}</div>
				</div>
			)}
		</div>
	);
};
