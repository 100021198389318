import React from 'react';


function PageNotFound() {
    return (
        <>
            <div>
                <h1>ERROR : 404</h1>
            </div>
        </>
    )
}
export default PageNotFound;