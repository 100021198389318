import React from "react";
import { IIntroSpace } from "Components/Module/API/Interface/Home.interface";
import { useTranslation } from "react-i18next";
import "./IntroSpace.scss";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useNavigate } from "react-router-dom";
import { GetThumbnail } from "Components/Common/DataList/GetThumbnail/GetThumbnail";
const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "80%",
	bgcolor: "background.paper",
	border: "1px solid #000",
	boxShadow: 15,
	p: 4
};

const IntroSpace: React.FC<IIntroSpace> = ({ index, dir, workerData, onClickDialogOpen }) => {
	const navigation = useNavigate();
	const { t } = useTranslation();
	const [open, setOpen] = React.useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	return (
		<>
			<div className="intro-space-container">
				<div className={`grid-wrapper ${dir === "left" ? "left" : "right"}`}>
					<div className={`${dir === "left" ? "work" : "worker"}`}>
						<a className="box">
							<div className="image-button">
								<img
									onClick={() => {
										if (window.innerWidth < 767) {
											if (workerData.popupMaindata.id === "none") {
												handleOpen();
											} else {
												onClickDialogOpen(workerData.popupMaindata);
											}
										}
									}}
									className="image-src"
									src={
										dir === "left"
											? workerData.mainThumbnail
											: workerData.worker
									}
									alt=""
								/>
								{dir === "right" && (
									<div
										onClick={() => navigation("/work")}
										className={`more ${index === 0 ? "index-zero" : ""}`}
									>
										<p>More</p>
									</div>
								)}
								{dir === "left" && (
									<div>
										{/* <div className="workTitle">
											<p>{workerData.mainTitle}</p>
										</div>
										<div className="workDiscription">
											<p>{workerData.mainDescription}</p>
										</div> */}
										<div
											className="background"
											onClick={() =>
												onClickDialogOpen(workerData.popupMaindata)
											}
										>
											{workerData.popupMaindata.id === "none" ? (
												<>
													<p>{"Coming Soon"}</p>
													{/* hover background text */}
												</>
											) : (
												<>
													<p>{workerData.mainTitle && t(workerData.mainTitle)}</p>
												</>
											)}
										</div>
									</div>
								)}
							</div>
						</a>
					</div>

					<div className={`${dir === "left" ? "worker" : "work"}`}>
						<a className="box">
							<div className="image-button">
								<img
									onClick={() => {
										if (window.innerWidth < 767) {
											if (workerData.popupMaindata.id === "none") {
												handleOpen();
											} else {
												onClickDialogOpen(workerData.popupMaindata);
											}
										}
									}}
									src={
										dir === "left"
											? workerData.worker
											: workerData.mainThumbnail
									}
									alt=""
								/>
								{dir === "left" && (
									<div
										onClick={() => navigation("/work")}
										className={`more ${index === 0 ? "index-zero" : ""}`}
									>
										<p>More</p>
									</div>
								)}
								{dir === "right" && (
									<div>
										{/* <div className="workTitle">
											<p>{workerData.mainTitle}</p>
										</div>
										<div className="workDiscription">
											<p>{workerData.mainDescription}</p>
										</div> */}
										<div
											className="background"
											onClick={() =>
												onClickDialogOpen(workerData.popupMaindata)
											}
										>
											{workerData.popupMaindata.id === "none" ? (
												<>
													<p>{"Coming Soon"}</p>
													{/* hover background text */}
												</>
											) : (
												<>
													<p>{workerData.mainTitle && t(workerData.mainTitle)}</p>
												</>
											)}
										</div>
									</div>
								)}
							</div>
						</a>
					</div>
					{workerData.workThumbnail.map((value, index: number) => {
						return (
							<div key={value + "-" + index} className="work">
								<a className="box">
									<div
										className="image-button"
										onClick={() => {
											if (
												workerData.popupdata[index].id === "none" &&
												window.innerWidth < 767
											) {
												handleOpen();
											} else {
												onClickDialogOpen(workerData.popupdata[index]);
											}
										}}
									>
										<img
											className="image-src"
											src={ workerData.workThumbnail[index]}
											alt=""
										/>
										
										<div className="background">
											{workerData.popupdata[index].id === "none" ? (
												<>
													<p>{"Coming Soon"}</p>
													{/* hover background text */}
												</>
											) : (
												<>
													<p>{workerData.workTitle[index] && t(workerData.workTitle[index])}</p>
												</>
											)}
										</div>
									</div>
								</a>
								{/* <span className="title">{workerData.workTitle[index]}</span>
								<span className="text">{workerData.workDescription[index]}</span> */}
								{/* <div className="listTitle">
						
							</div> */}
							</div>
						);
					})}
				</div>
			</div>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={style}>
					<p>Coming soon...</p>
				</Box>
			</Modal>
		</>
	);
};

export default IntroSpace;
