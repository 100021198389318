import React from "react";

import imgMainBg from "../Source/bg-main.png";

import imgKey1 from "../Source/img-keyword-01.png";
import imgKey2 from "../Source/img-keyword-02.png";
import imgKey3 from "../Source/img-keyword-03.png";
import imgKey4 from "../Source/img-keyword-04.png";
import imgKey5 from "../Source/img-keyword-05.png";

import gifS13 from "../Source/S13_Looping.gif";

import imgColor01 from "../Source/img-color-01.png";

import gifS215 from "../Source/S21_S25_Looping.gif";

import imgKeyVisual from "../Source/img-keyvisual.png";

import gifS19 from "../Source/S19_Looping.gif";

import imgAdv02 from "../Source/img-advertisement-02.png";
import imgAdv01 from "../Source/img-advertisement-01.png";

import "./styles.scss";
const PageBg = () => {
	return (
		<div className="ise2023">
			<div className="main-bg">
				<img src={imgMainBg} alt="img" />
			</div>

			<div className="section-white">
				<OverView
					title="IES 2023"
					detail="유럽 ​​통합 시스템 전시회 [영어: INTERGRATED SYSTEM EUROPE, 줄여서 ISE]는 매년 2월 스페인 바르셀로나에서 열리는 유럽 최대
                규모의 디스플레이 전시 행사로, 각 기업은 신기술을 적용한 기기들을 대중에게 선보일 예정이다.
                이 전시회에서 수많은 제품 프리뷰가 쏟아지며 새로운 제품들이 소개된다."
				/>
				<div className="horizontal-block"></div>
				<OverView
					maxWidth="1161px"
					title="Project Overview"
					detail="이번 프로젝트는 ISE 2023 행사에 사용되는 삼성의 영상제작 프로젝트이다.
                    면의 심플한 일러스트로 사용된 컬러는 블랙 & 화이트 그리고 포인트 원 컬러로만 이뤄진 심플하고 세련미가 돋보이는 작업이며
                    일러스트들이 연결되는 위트있는 스타일의 작업으로 심플하지만 드라마틱한 전개를 볼 수 있게 제작되었다."
				/>
			</div>

			<div className="keyword-section">
				<OverView
					maxWidth="575px"
					title="Keyword"
					detail="이 프로젝트는 5개의 키워드를 선택할 수 있으며 삽화는 순전히
                    평면과 선/청색-녹색, 흰색 및 검정색을 주로 사용합니다."
				/>
				<div className="img">
					<div className="item">
						<img src={imgKey1} alt="img" />
                        <div className="text">
                        Leaves
                        </div>
					</div>
					<div className="item">
						<img src={imgKey2} alt="img" />
                        <div className="text">
                        Wavy lines & Illustration
                        </div>
					</div>
					<div className="item">
						<img src={imgKey3} alt="img" />
                        <div className="text">
                        Circles & Dots
                        </div>
					</div>
					<div className="item">
						<img src={imgKey4} alt="img" />
                        <div className="text">
                        Lines & Patterns
                        </div>
					</div>
					<div className="item">
						<img src={imgKey5} alt="img" />
                        <div className="text">
                        Infinity Symbol
                        </div>
					</div>
				</div>
			</div>

			<div className="main-bg">
				<img src={gifS13} alt="img" />
			</div>

			<div className="color-section">
				<OverView
					title="Color"
					maxWidth="802px"
					detail="심플한 사이드와 라인 일러스트로 구성된 이번 프로젝트는 블루와 그린 컬러가 어우러져
                    화이트를 포인트 컬러로 하여 심플한 느낌의 일러스트와 영상을 제작하였습니다."
				/>

				<div className="img">
					<div className="item">
						<img src={imgColor01} alt="img" />
					</div>
				</div>
			</div>

			<div className="main-bg">
				<img src={gifS215} alt="img" />
			</div>

			<div className="key-visual-section">
				<div className="top">Key Visual</div>

				<div className="item">
					<img src={imgKeyVisual} alt="img" />
				</div>
			</div>

			<div className="main-bg">
				<img src={gifS19} alt="img" />
			</div>

			<div className="main-bg">
				<img src={imgAdv02} alt="img" />
			</div>

			<div className="main-bg">
				<img src={imgAdv01} alt="img" />
			</div>
		</div>
	);
};

export default PageBg;

const OverView = ({ title, detail, maxWidth="1250px" }: { title: string; detail: string; maxWidth?:string }) => {
	return (
		<div className="overview">
			<div className="top">{title}</div>
			<div className="bottom" style={{maxWidth: maxWidth}}>{detail}</div>
		</div>
	);
};
