import React, { useEffect, useState } from "react";
import { IReactPlayer } from "Components/Module/API/Interface/Common.interface";

import VideoButton from "Assets/Images/250-play-btn.png";
import thumbnail from "Assets/Images/2023.jpeg";
import ReactPlayer from "react-player";
import "./ReactVideoPlayer.scss";

const ReactVideoPlayer: React.FC<IReactPlayer> = ({ url }) => {
	const [start, setStart] = useState(false);
	const [playing, setPlaying] = useState(false);
	function onStart() {
		const timer = setTimeout(() => {
			setStart(true);
		}, 900);
	}
	function changePlaying() {
		setPlaying(!playing);
	}

	return (
		<div className="player-container">
			<div className="player-wrapper">
				<div className="player-button">
					<ReactPlayer
						className="content"
						url="https://vimeo.com/834677738"
						width="100%"
						height="100%"
						muted={true} //chrome정책으로 인해 자동 재생을 위해 mute 옵션을 true로 해주었다.
						playing={playing}
						loop={true}
						config={{}}
						onStart={onStart}
					/>
					{start === false && <img className="thumbnail" src={thumbnail} alt="" />}
					{playing === false && (
						<img className="btn" src={VideoButton} alt="" onClick={changePlaying} />
					)}
				</div>
			</div>
		</div>
	);
};

export default ReactVideoPlayer;
