import React from 'react';
import HomeLaneige from './components/HomeLaneige';
import './LaneigePage.scss'
function LaneigePage() {
    return (
        <div className="laneige">
			<HomeLaneige />
		</div>
    )
}
export default LaneigePage;